import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { GetService } from 'src/app/services/get.service';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-compliance-requirement',
  templateUrl: './compliance-requirement.component.html',
  styleUrls: ['./compliance-requirement.component.css']
})
export class ComplianceRequirementComponent implements OnInit {
  componentId: number;
  lastCompleted:number;

  constructor(  private statusService: GetService,
    private router: Router, 
    private route: ActivatedRoute, 
    public mediaObserver: MediaObserver, 
    private breakpointObserver: BreakpointObserver) { 
      this.route.params.subscribe( params => this.componentId = params['id'] );
      this.statusService.getCompletionStatus()
      .subscribe(data=>{
        this.lastCompleted = parseInt(data.toString());
        // console.log("lastcomp",this.lastCompleted)
      },err=>{
        alert("Couldnt get status"+err);
      })
    }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

}
