import { Injectable } from '@angular/core';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalConstants } from '../common/global-constants';


interface UserObserver {
  user: string;
  password: string;
}

@Injectable({
  providedIn: 'root'
})

export class GetService {
  private url = GlobalConstants.rootURL;
  user = { id: 1, name: 'kiran' };

  constructor(private http: HttpClient) { }

  getUsers(username: string, password: string) {
    var data = {
      username: username,
      password: password
    }
    
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return this.http.post(this.url+'/api/users', data, {
      headers: headers
    })
  }
// -----------------------------------------------------------------------------------NGP STUDIES
  // course status tracking with validations for previous and next topics/lessons
  validateCompletionStatus(previousId: number, nextId: number){
    var data = {
      previousId: previousId,
      nextId : nextId
    }
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return this.http.post(this.url+'/api/status-tracking/validate', data)
  }

  // course status tracking for update in last completed topic
  updateCompletionStatus(previousId: number, nextId: number){
    var data = {
      previousId: previousId,
      nextId : nextId
    }
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return this.http.post(this.url+'/api/status-tracking/update', data)
  }

  // get completion status
  getCompletionStatus(){
    return this.http.get(this.url+'/api/status-tracking/get-status');
  }

// -----------------------------------------------------------------------------------Case Studies
// Case studies services
  getCompletionStatusCaseStudy(){
    return this.http.get(this.url+'/api/status-tracking/get-status-casestudy');
  }
 
  updateCompletionStatusCaseStudy(previousId: number, nextId: number){
    var data = {
      previousId: previousId,
      nextId : nextId
    }
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return this.http.post(this.url+'/api/status-tracking/update-casestudy', data)
  }

  // -----------------------------------------------------------------------------------Dos and Donts
  // do's and dont's service
  updateCompletionStatusDosDonts(previousId: number, nextId: number){
    var data = {
    previousId: previousId,
    nextId : nextId
    }
    const headers = new HttpHeaders()
    .set('Content-Type', 'application/json');
    return this.http.post(this.url+'/api/status-tracking/update-dos-donts', data)
  }

  // get completion status
  getCompletionStatusDos(){
    return this.http.get(this.url+'/api/status-tracking/get-status-dos-donts');
  }
}