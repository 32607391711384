import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chap7g-hawk-eyesp-install',
  templateUrl: './chap7g-hawk-eyesp-install.component.html',
  styleUrls: ['./chap7g-hawk-eyesp-install.component.css']
})
export class Chap7gHawkEyespInstallComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
