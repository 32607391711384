import { Component, OnInit } from '@angular/core';
import { AdminserviceService } from '../services/adminservice.service';
import { GlobalConstants } from 'src/app/common/global-constants';
import {AfterViewInit, ViewChild} from '@angular/core';
import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-pretest-analysis',
  templateUrl: './pretest-analysis.component.html',
  styleUrls: ['./pretest-analysis.component.css']
})
export class PretestAnalysisComponent implements OnInit,AfterViewInit  {
  pretestRecords;
  
  url = GlobalConstants.rootURL;

  displayedColumns: string[] = ['username', 'date_attempted', 'total_points', 'obtained_points' , 'result','export'];
  // dataSource = new MatTableDataSource<TableElement>(this.pretestRecords);
  dataSource:any;
  @ViewChild(MatPaginator,{static: true}) paginator: MatPaginator;

  constructor(private adminService: AdminserviceService) { 
    this.adminService.getPretestHistory().subscribe(data=>{
      this.pretestRecords = data;
      this.dataSource = new MatTableDataSource<TableElement>(this.pretestRecords);
      this.dataSource.paginator = this.paginator;
    }, error=>{
      // console.log("cant load data")
    })
  }

  exportData(id) {
    // console.log("Id received-",id)
    this.adminService.createReport(id)
      .subscribe(data=>{
        this.downloadFile(data['csvString'], data['userName'])
        // console.log(data)
    }, error=>{
      // console.log("cant load data",error)
    })
  }

  downloadFile(data, filename:string) {
    const blob = new Blob([data], { type: 'text/csv' });
    const url= window.URL.createObjectURL(blob);

    var anchor = document.createElement("a");
    anchor.download = 'Pre-Test_Result_'+filename+'.csv';
    anchor.href = url;
    anchor.click();
  }

  ngOnInit() {}
  ngAfterViewInit() {
   
    // this.dataSource.paginator = this.paginator;
  }

}

export interface TableElement {
  username: string;
  date_attempted: number;
  total_points: number;
  obtained_points: number;
  result: number;
  export: string;
}


// import { Component, OnInit } from '@angular/core';
// import { AdminserviceService } from '../services/adminservice.service';
// import { GlobalConstants } from 'src/app/common/global-constants';

// @Component({
//   selector: 'app-pretest-analysis',
//   templateUrl: './pretest-analysis.component.html',
//   styleUrls: ['./pretest-analysis.component.css']
// })
// export class PretestAnalysisComponent implements OnInit {
//   pretestRecords;
//   url = GlobalConstants.rootURL;

//   constructor(private adminService: AdminserviceService) { 
//     this.adminService.getPretestHistory().subscribe(data=>{
//       this.pretestRecords = data;
//       console.log(data)
//     }, error=>{
//       console.log("cant load data")
//     })
//   }

//   exportData(id) {
//     this.adminService.createReport(id).subscribe(data=>{
//       // this.downloadFile(this.url+'/'+data)
//       // window.location.href=this.url+'/'+data;
//       console.log(data)
//     }, error=>{
//       console.log("cant load data")
//     })
//   }

//   downloadFile(data) {
//     const blob = new Blob([data], { type: 'text/csv' });
//     const url= window.URL.createObjectURL(blob);
//     window.open(url);
//   }

//   ngOnInit() {
//   }

// }
