// import { Component, OnInit } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { GetService } from 'src/app/services/get.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-index-chap1',
  templateUrl: './index-chap1.component.html',
  styleUrls: ['./index-chap1.component.css']
})
export class IndexChap1Component implements OnInit {

  opened= false;
  deviceXs: boolean;
  mediaSub: Subscription;
  sideFlag=1;

  lastCompleted: number;
 
  constructor(private router:Router, public mediaObserver: MediaObserver,private statusService: GetService,private toastr:ToastrService) { 

    statusService.getCompletionStatus()
      .subscribe(data=>{
        this.lastCompleted = parseInt(data.toString());
      },err=>{
        alert("Error getting status!!")
      })
  }

  navigateNgp(componentId: number){
    if(componentId <= this.lastCompleted){
    this.router.navigate(['course/ngp', componentId]);
    } else if(componentId == this.lastCompleted+1){
    this.router.navigate(['course/ngp', componentId]);
    } else{
    this.toastr.warning('Please complete this topic first to proceed for next');
    this.router.navigate(['course/ngp', this.lastCompleted+1]);
    }
    }
   
  isCompleted(componentId: number){
    if(componentId <= this.lastCompleted){
      return true;
    }
    return false;
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.mediaSub = this.mediaObserver.media$.subscribe((res: MediaChange) => {
      // console.log(res.mqAlias);
      this.deviceXs = res.mqAlias === "xs" ? true : false;
    })
  }
  ngOnDestroy() {
    this.mediaSub.unsubscribe();
  }
  sidebar(){
    if(this.sideFlag==1){
      this.sideFlag=0;
    }
    else{
      this.sideFlag=1;
    }
    // console.log(this.sideFlag)
}

}
