import { Component, OnInit } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MediaObserver } from '@angular/flex-layout';
import { ActivatedRoute, Router } from '@angular/router';
import { GetService } from 'src/app/services/get.service';

@Component({
  selector: 'app-necessity-of-point-less10',
  templateUrl: './necessity-of-point-less10.component.html',
  styleUrls: ['./necessity-of-point-less10.component.css']
})
export class NecessityOfPointLess10Component implements OnInit {

  componentId: number;
  lastCompleted:number;

  constructor(  private statusService: GetService,
    private router: Router, 
    private route: ActivatedRoute, 
    public mediaObserver: MediaObserver, 
    private breakpointObserver: BreakpointObserver) { 
      this.route.params.subscribe( params => this.componentId = params['id'] );
      this.statusService.getCompletionStatusDos()
      .subscribe(data=>{
        this.lastCompleted = parseInt(data.toString());
        // console.log("lastcomp",this.lastCompleted)
      },err=>{
        alert("Couldnt get status"+err);
      })
    }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

}
