import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subchap8m-wiring-details',
  templateUrl: './subchap8m-wiring-details.component.html',
  styleUrls: ['./subchap8m-wiring-details.component.css']
})
export class Subchap8mWiringDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
