import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subchap8c-new-install-phase3',
  templateUrl: './subchap8c-new-install-phase3.component.html',
  styleUrls: ['./subchap8c-new-install-phase3.component.css']
})
export class Subchap8cNewInstallPhase3Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
