import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-annexure-chap7',
  templateUrl: './annexure-chap7.component.html',
  styleUrls: ['./annexure-chap7.component.css']
})
export class AnnexureChap7Component implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }
  onClickBack(){
    this.router.navigate(['course/ngp/42'])
  }
}
