import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subchap8d-review-installation',
  templateUrl: './subchap8d-review-installation.component.html',
  styleUrls: ['./subchap8d-review-installation.component.css']
})
export class Subchap8dReviewInstallationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
