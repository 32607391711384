import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { CountdownComponent } from 'ngx-countdown';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  @ViewChild('countdown',{static: false}) counter: CountdownComponent;
  

  disableStatus= true;
  timeLeft: number = 10;
  timerEndFlag: boolean= false;
  interval;
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));
  @Input() deviceXs: boolean;
  mediaSub: Subscription;
  smallDeviceCheck : boolean;
  config:any;
  checkTimer: boolean = true;

  componentId: number
  sideFlag: boolean = true;

  constructor(private router: Router, private route: ActivatedRoute, public mediaObserver: MediaObserver, private breakpointObserver: BreakpointObserver) {
    this.startTimer();
    // console.log(this.sideFlag);
    this.config = {leftTime: 10} 
    
  }

  timesUp(event) { 
    if (event.action == "done") {
      //  console.log("Finished");
       this.checkTimer = false;
       }
  }
  
  ngOnInit() {
    window.scrollTo(0, 0);

    // console.log(this.deviceXs);
    // setTimeout(() => this.counter.restart());
    this.mediaSub = this.mediaObserver.media$.subscribe((res: MediaChange) => {
      // console.log(res.mqAlias);
      this.deviceXs = res.mqAlias === "xs" ? true : false;
      this.sideFlag = res.mqAlias === "xs" ? false: true;
     
      // this.smallDeviceCheck = res.mqAlias === "xs" ? true : false;
      // console.log("hello .."+ this.smallDeviceCheck );

    });

    this.route.paramMap.subscribe((params: ParamMap) => {
      let id = parseInt(params.get('id'));
      this.componentId = id;
      this.checkTimer=true;
    });
    // console.log("hello .."+ this.deviceXs );
  }
  // button click for next URL
  onClickNext(nextComponent: number) {
    // this.restartCounter();
    this.componentId = nextComponent;
    this.router.navigate(['course/ngp', this.componentId]);
    this.startTimer();
    this.config = {leftTime: 10} 
  }

  onClickPrevious(prevComponent: number) {
    this.componentId = prevComponent;
    this.router.navigate(['course/ngp', this.componentId]);
    this.config = {leftTime: -1}
  }

  // restartCounter(){
  //   // setTimeout(() => this.counter.restart());

  // }
  // small devices check!
  ngOnDestroy() {
    this.mediaSub.unsubscribe();
  }
  
  // timer method
  startTimer() {
    this.timeLeft= 10;
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
        // console.log(this.timeLeft)
      }else{
        clearInterval(this.interval);
      }
    }, 1000);
  }


  handleEvent(event){
  // console.log(event.action);
  if(event.action == 'done'){

    this.timerEndFlag = true;

  }

  }

  sidebar() {
    if (this.sideFlag) {
      this.sideFlag = false;
    } else {
      this.sideFlag = true;
    }
  }
}
