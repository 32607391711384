import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chap8s-postcheck-images',
  templateUrl: './chap8s-postcheck-images.component.html',
  styleUrls: ['./chap8s-postcheck-images.component.css']
})
export class Chap8sPostcheckImagesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
