import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subchap8s-post-check-install',
  templateUrl: './subchap8s-post-check-install.component.html',
  styleUrls: ['./subchap8s-post-check-install.component.css']
})
export class Subchap8sPostCheckInstallComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
