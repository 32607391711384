import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  user : string;
  username:string;
  constructor(private router: Router) { 
    if (sessionStorage.getItem('email') != ''){
      this.user = sessionStorage.getItem('email')
  }
  }

  ngOnInit() {
    this.username = localStorage.getItem('firstName');
  }

  logout(){

    localStorage.clear();
    this.router.navigate(['login']);
  }
  

}
