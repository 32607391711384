import { Component, OnInit } from '@angular/core';
import { OnDestroy, Input,ViewChild  } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from "@angular/router";
import { MediaChange, MediaObserver } from "@angular/flex-layout";
import { Subscription } from "rxjs";
import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState
} from "@angular/cdk/layout";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { RouterModule } from "@angular/router";
import { CountdownComponent, CountdownConfig } from 'ngx-countdown';
import { GetService } from "src/app/services/get.service";
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-main-hawk-eyes-p-course-view',
  templateUrl: './main-hawk-eyes-p-course-view.component.html',
  styleUrls: ['./main-hawk-eyes-p-course-view.component.css']
})
export class MainHawkEyesPCourseViewComponent implements OnInit {
  config: CountdownConfig = {
    leftTime: 5,
    format: 'HH:mm:ss',
  };

  @ViewChild('cd',{static: false}) counter: CountdownComponent;
  lastCompleted: number; 
  timerDisableStatus = false;
  disableStatus= true;
  completionStatus: number;
  timeLeft: number = 10;
  timerEndFlag: boolean= false;
  interval;
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));
  @Input() deviceXs: boolean;
  mediaSub: Subscription;
  smallDeviceCheck : boolean;
  checkTimer: boolean = true;

  componentId: number
  sideFlag = 1;

  prevTracker: number = 1;
  constructor(private router: Router, private route: ActivatedRoute, public mediaObserver: MediaObserver, private breakpointObserver: BreakpointObserver) {
  }


  ngOnInit() {
    window.scrollTo(0, 0);

    this.route.paramMap.subscribe((params: ParamMap) => {
      let id = parseInt(params.get('id'));
      this.componentId = id;
    });
    
    this.mediaSub = this.mediaObserver.media$.subscribe((res: MediaChange) => {
      console.log(res.mqAlias);
      this.deviceXs = res.mqAlias === "xs" ? true : false;
    });

  }
  // button click for next URL
  onClickNext(nextComponent: number) {
    this.componentId = nextComponent;
    this.router.navigate(['hawkeyesp/android-material', this.componentId]);
  }


  onClickPrevious(prevComponent: number) {
    this.componentId = prevComponent;
    this.router.navigate(['hawkeyesp/android-material', this.componentId]);
  }


  // small devices check!
  ngOnDestroy() {
    this.mediaSub.unsubscribe();
  }


  // handles timer events
  handleEvent(event){
    console.log(event)
    if(event.action == 'done'){
      this.timerDisableStatus = true;
    }else if(event.action == 'restart' || 'start'){
      this.timerDisableStatus = false
    }
  }

  sidebar() {
    if (this.sideFlag == 1) {
      this.sideFlag = 0;
    } else {
      this.sideFlag = 1;
    }
  }

  
  onClickLesson(lessonIndex:number){
    this.componentId = lessonIndex;
    if(this.componentId <= 21){
    // this.indexNumber = this.componentId/10;
    this.router.navigate(['hawkeyesp/android-material/7']);
    }
    else{
    this.router.navigate(['hawkeyesp/android-material/22']);
    }
    
    }
    
    onClickCourse(){
    this.router.navigate(['hawkeyesp/android-material'])
    }
    
    onfinish(){
      this.router.navigate(['/'])
    }

}
