import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RandomQuestionsService } from 'src/app/admin/services/random-questions.service';

import { GetquestionsService } from 'src/app/services/getquestions.service';
import { GlobalConstants } from 'src/app/common/global-constants';

@Component({
  selector: 'app-preindex',
  templateUrl: './preindex.component.html',
  styleUrls: ['./preindex.component.css']
})
export class PreindexComponent implements OnInit {

  warningMsg: boolean = false;
  timeInstruction;
  public windowReference: Window;
  constructor(private router: Router,
    private queService: GetquestionsService,
    private randomQuestion: RandomQuestionsService) { 
      this.randomQuestion.getMarketDetails()
      .subscribe(data=>{
        console.log("market",data)
        this.timeInstruction = parseInt(data["time"]);   
      }
    )
  }

  ngOnInit() {
  }

  onPreTestStart(){
    if(confirm('Test will be launched in new Window, Are you sure you want to start the test?')){
      this.queService.ifTestAttempted().subscribe(data=>{
        if(data){
          if(GlobalConstants.pretestOpen == null || GlobalConstants.pretestOpen.closed){
            GlobalConstants.pretestOpen = window.open('test/pre-test', '', 'toolbar=0');
          }else{
            this.router.navigate(['test/test-content'])
          }
        }else{
          this.warningMsg = true;
        }
      },err=>{
        // console.log(err)
      })
    }else{
      // console.log("not confirmed")
    }
  }
}
