import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthguardServiceService {
  constructor() { }

  get onLogin() {
    return !!localStorage.getItem('accessToken')
  }

  getToken() {
    return localStorage.getItem('accessToken')
  }
}
