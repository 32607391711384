import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GetService } from 'src/app/services/get.service';
import { QuizcourseService } from 'src/app/services/quizcourse.service';

@Component({
  selector: 'app-quizchap12',
  templateUrl: './quizchap12.component.html',
  styleUrls: ['./quizchap12.component.css']
})
export class Quizchap12Component implements OnInit {
  lastCompleted: number; 
  question : any;
  options: any;
  data: any;
  answerStatus:boolean = false;
  selectedOption:string;
  cssChangeAction:string;
  constructor( private quizcourseService: QuizcourseService,private router: Router,private statusService: GetService) {
    this.statusService.getCompletionStatus()
    .subscribe(data=>{
      this.lastCompleted = parseInt(data.toString());
    },err=>{
      alert("Couldnt get status"+err);
    })
   }

  ngOnInit():void {
    window.scrollTo(0, 0);
    // window.location.reload();
    this.data = this.quizcourseService.getquizzes(11);
    // console.log(this.question);
    this.question = this.data[0];
    this.options = this.data[1];
    // console.log(this.question);
    // console.log(this.options);

  }

  radioChange(event){
    // console.log(event.value)
    this.selectedOption = event.value;
  }

  onAnswerCheck(){
  this.answerStatus=true;
  this.cssChangeAction=this.selectedOption;
  }

  onSubmitQuiz(){
  
    // this.quizcourseService.saveChapterQuizResult(this.question, `chapter_12`)
    //   .subscribe(data=>{
    //     if (data){
    //       this.router.navigate(['/course/ngp/70']);
    //     } else{
    //       alert("Cant update the score, Please try again!!!")
    //     }
    // })

    if (this.lastCompleted == 68) {
      this.statusService.updateCompletionStatus(68 , 69)
        .subscribe(data => { 
          if (data) {
            this.quizcourseService.saveChapterQuizResult(this.question, `chapter_12`)
              .subscribe(data => {
                if (data) {
                  this.router.navigate(['/course/ngp/70']);
                } else {
                  alert("Can't update the score, Please try again!!!")
                }
              })
          }
        }, err => {
          alert("something went wrong, please try again!!")
        })
    } else {
  
    this.quizcourseService.saveChapterQuizResult(this.question, `chapter_12`)
      .subscribe(data => {
        if (data) {
          this.router.navigate(['/course/ngp/70']);
        } else {
          alert("Can't update the score, Please try again!!!")
        }
      })
  
  }   
  
  }

}
