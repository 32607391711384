import { Component, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { GetService } from 'src/app/services/get.service';

@Component({
  selector: 'app-hawk-eyes-p-main-index',
  templateUrl: './hawk-eyes-p-main-index.component.html',
  styleUrls: ['./hawk-eyes-p-main-index.component.css']
})
export class HawkEyesPMainIndexComponent implements OnInit {

  lastCompleted:number = 0;
  panelOpenState = false;
  panelOpenState1 = false;
  // colorName = 'green';
  expandCollapseState : boolean =false;

 
  
@ViewChild(MatAccordion,{static:true}) accordion: MatAccordion;
  constructor(private statusService: GetService,private router: Router, private route: ActivatedRoute) { 
    
  }
  ngOnInit() {
    window.scrollTo(0, 0);
  }

  // isCompleted(componentId: number){
  //   if(componentId <= this.lastCompleted){
  //     return true;
  //   }
  //   return false;
  // }
  
  goToChapterTopic(){
    this.router.navigate([0], {relativeTo: this.route})
  }

}
