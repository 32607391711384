import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chap8i-ev-dedicated-mcb',
  templateUrl: './chap8i-ev-dedicated-mcb.component.html',
  styleUrls: ['./chap8i-ev-dedicated-mcb.component.css']
})
export class Chap8iEvDedicatedMcbComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
