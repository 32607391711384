import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chap8j-ev-dedicated-sub-db',
  templateUrl: './chap8j-ev-dedicated-sub-db.component.html',
  styleUrls: ['./chap8j-ev-dedicated-sub-db.component.css']
})
export class Chap8jEvDedicatedSubDBComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
