import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hawk-eyes-p-chap1',
  templateUrl: './hawk-eyes-p-chap1.component.html',
  styleUrls: ['./hawk-eyes-p-chap1.component.css']
})
export class HawkEyesPChap1Component implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0);

  }

}
