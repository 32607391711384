import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subchap8x-report-sample',
  templateUrl: './subchap8x-report-sample.component.html',
  styleUrls: ['./subchap8x-report-sample.component.css']
})
export class Subchap8xReportSampleComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
