import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subchap8j-ev-dedicated-mcb',
  templateUrl: './subchap8j-ev-dedicated-mcb.component.html',
  styleUrls: ['./subchap8j-ev-dedicated-mcb.component.css']
})
export class Subchap8jEvDedicatedMcbComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
