import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { GlobalConstants } from 'src/app/common/global-constants';
import { QuizcourseService } from 'src/app/services/quizcourse.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-chapwise-quiz-analysis',
  templateUrl: './chapwise-quiz-analysis.component.html',
  styleUrls: ['./chapwise-quiz-analysis.component.css']
})
export class ChapwiseQuizAnalysisComponent implements OnInit {
  pretestRecords;
  
  url = GlobalConstants.rootURL;

  displayedColumns: string[] = ['username', 'date_attempted','status'];
  // dataSource = new MatTableDataSource<TableElement>(this.pretestRecords);
  dataSource:any;
  @ViewChild(MatPaginator,{static: true}) paginator: MatPaginator;

  quiz_history;
  // : TableElement[] = [{'ser_no':1, 'username':'reetika','date_attempted':'24/04/2021', 'status':2, 'result':2}]
  constructor(private quizcourseService: QuizcourseService, private router: Router) { }

  ngOnInit() {
    // console.log("executedd")
    this.quizcourseService.getChapterQuizResult()
      .subscribe(data=>{
        if (data){
          this.quiz_history = data
          // console.log(data)
          // this.router.navigate(['/course/ngp/80']);
        } else{
          alert("Cant retrieve the data, Please try again!!!")
        }
    })
  }


  
}
export interface TableElement {
  ser_no: number;
  username: string;
  date_attempted: string;
  result: number;
  status: number;
}


