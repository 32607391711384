import { Component, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { Router, ActivatedRoute } from '@angular/router';
import { GetService } from 'src/app/services/get.service';

@Component({
  selector: 'app-main-index',
  templateUrl: './main-index.component.html',
  styleUrls: ['./main-index.component.css']
})
export class MainIndexComponent implements OnInit {
  lastCompleted:number = 0;
  panelOpenState = false;
  panelOpenState1 = false;
  colorName = 'green';
  expandCollapseState : boolean =false;


  @ViewChild(MatAccordion,{static:true}) accordion: MatAccordion;
    constructor(private router: Router, private route: ActivatedRoute, private statusService: GetService) { 

    }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.statusService.getCompletionStatus()
      .subscribe(data=>{
        this.lastCompleted = parseInt(data.toLocaleString());
        // console.log("Status Received-",this.lastCompleted)
      },err=>{
        alert("could not get status ");
      })
  }

  isCompleted(componentId: number){
    if(componentId <= this.lastCompleted){
      return true;
    }
    return false;
  }

  goToChapterTopic(){
    this.router.navigate([0], {relativeTo: this.route})
  }
}
