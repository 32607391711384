import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chap7j-hawk-eyesp-install-ver8',
  templateUrl: './chap7j-hawk-eyesp-install-ver8.component.html',
  styleUrls: ['./chap7j-hawk-eyesp-install-ver8.component.css']
})
export class Chap7jHawkEyespInstallVer8Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
