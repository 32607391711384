import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chap8w-help',
  templateUrl: './chap8w-help.component.html',
  styleUrls: ['./chap8w-help.component.css']
})
export class Chap8wHelpComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
