import { Component, OnInit, Input, ViewChild} from "@angular/core";
import { ActivatedRoute, Router, ParamMap } from "@angular/router";
import { MediaChange, MediaObserver } from "@angular/flex-layout";
import { Subscription } from "rxjs";
import { ToastrService } from 'ngx-toastr';
import { CountdownComponent, CountdownConfig } from 'ngx-countdown';
import { BreakpointObserver, Breakpoints, BreakpointState } from "@angular/cdk/layout";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { GetService } from "src/app/services/get.service";

@Component({
  selector: "app-main-course-view",
  templateUrl: "./main-course-view.component.html",
  styleUrls: ["./main-course-view.component.css"]
})
export class MainCourseViewComponent implements OnInit {
  config: CountdownConfig = {
    leftTime: 5,
    format: 'HH:mm:ss',
  };

@ViewChild("cd",{static: false}) counter: CountdownComponent;
  lastCompleted: number; 
  timerDisableStatus = false;
  disableStatus= true;
  completionStatus: number;
  timeLeft: number = 10;
  timerEndFlag: boolean= false;
  interval;
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));
  @Input() deviceXs: boolean;
  mediaSub: Subscription;
  smallDeviceCheck : boolean;
  checkTimer: boolean = true;

  componentId: number
  sideFlag: boolean = true;
  retrievedObject;
  username:string;

  userSideBarChoice: boolean = false;

  prevTracker: number = 1;
  constructor(private toastr: ToastrService,
    private statusService: GetService,
    private router: Router, 
    private route: ActivatedRoute, 
    public mediaObserver: MediaObserver, 
    private breakpointObserver: BreakpointObserver) {
      this.username = localStorage.getItem('firstName');
      // this.sideFlag = Boolean(localStorage.getItem('sBar'));
      this.route.params.subscribe( params => this.componentId = params['id'] );
      this.statusService.getCompletionStatus()
      .subscribe(data=>{
        this.lastCompleted = parseInt(data.toString());
        if(this.componentId <= this.lastCompleted){
          this.timerDisableStatus = true;
        } else if(this.componentId == this.lastCompleted+1){
          this.timerDisableStatus = false;
          // this.counter.restart();
        } else{
          this.router.navigate(['course/ngp', this.lastCompleted+1]);
          this.showWarning();
        }
      },err=>{
        alert("Couldnt get status"+err);
      })
  }

  // on view initialize
  ngOnInit() {
    window.scrollTo(0, 0);
    // this.sideFlag = Boolean(localStorage.getItem('sBar'));
    // console.log("in on init-", this.sideFlag)

    if(localStorage.getItem('sBar')){
      this.retrievedObject = localStorage.getItem('sBar');
      this.sideFlag = JSON.parse(this.retrievedObject) ;
      // console.log("in constructor-", typeof(this.sideFlag))
    }
    else{
      this.sideFlag = true;
    }

    this.route.paramMap.subscribe((params: ParamMap) => {
      let id = parseInt(params.get('id'));
      this.componentId = id;
    });
    
    this.mediaSub = this.mediaObserver.media$.subscribe((res: MediaChange) => {
      // console.log(res.mqAlias);
      this.deviceXs = res.mqAlias === "xs" ? true : false;
      // this.sideFlag = res.mqAlias === "xs" ? false: true;
    });
  }

  // button click for next URL
  onClickNext(nextComponent: number) {
    this.statusService.getCompletionStatus()
      .subscribe(data=>{
        this.lastCompleted = parseInt(data.toString());        
        if(nextComponent <= this.lastCompleted){
          this.router.navigate(['course/ngp', nextComponent]);
          this.componentId = nextComponent;
          this.timerDisableStatus = true;
        } else if(nextComponent == this.lastCompleted+1){
          this.router.navigate(['course/ngp', nextComponent]);
          this.componentId = nextComponent;
          this.timerDisableStatus = false;
          // this.counter.restart();
        }else{
          this.router.navigate(['course/ngp', this.lastCompleted+1]);
          // console.log("cant proceed")
          this.showWarning();
        }
      },err=>{
        alert("Network error, Please try again!!")
        // console.log("Error-",err)
      })
      // console.log("last complete",this.lastCompleted)
  }

  // update after mark complete
  markComplete(nextComponentId){
    this.statusService.updateCompletionStatus(this.prevTracker, nextComponentId)
      .subscribe(data=>{
        if(data){
          this.lastCompleted = data["nextId"];
          this.router.navigate(['course/ngp', this.lastCompleted+1]);
          this.componentId = this.lastCompleted+1;
          this.timerDisableStatus = false;
          // this.counter.restart();
        }
      },err=>{
        alert("something went wrong, please try again!!")
      })
  }

  toggeleSideBar(){
    this.sideFlag = !this.sideFlag; 
    localStorage.setItem('sBar', JSON.stringify(this.sideFlag))
    // console.log("in on init-", this.sideFlag)
  }
  // onclick previous button
  onClickPrevious(prevComponent: number) {
    this.timerDisableStatus = true;
    this.componentId = prevComponent;
    this.router.navigate(['course/ngp', this.componentId]);
  }

  isCompleted(componentId: number){
    if(componentId <= this.lastCompleted){
      return true;
    }
    return false;
  }

  // on destroy view
  ngOnDestroy() {
    this.mediaSub.unsubscribe();
  }

  // shows warning toast
  showWarning() {
    this.toastr.warning('Please complete this topic first to proceed for next', );
  }

  
  
  // handles timer events
  handleEvent(event){
    // console.log(event)
    if(event.action == 'done'){
      this.timerDisableStatus = true;
    }else if(event.action == 'restart' || 'start'){
      this.timerDisableStatus = false
    }
  }

  // for back to lesson linking
  onClickLesson(lessonIndex:number){
    this.componentId = lessonIndex;
    if(this.componentId <= 11){
    // this.indexNumber = this.componentId/10;
    this.router.navigate(['course/ngp/1']);
    }
    else if(this.componentId <=14){
    this.router.navigate(['course/ngp/12']);
    }
    else if(this.componentId<=19){
    this.router.navigate(['course/ngp/15']);
    }
    else if(this.componentId<=24){
    this.router.navigate(['course/ngp/20']);
    }
    else if(this.componentId<=29){
    this.router.navigate(['course/ngp/25']);
    }
    else if(this.componentId<=34){
    this.router.navigate(['course/ngp/30']);
    }
    else if(this.componentId<=39){
    this.router.navigate(['course/ngp/35']);
    }
    else if(this.componentId<=44){
    this.router.navigate(['course/ngp/40']);
    }
    else if(this.componentId<=49){
    this.router.navigate(['course/ngp/45']);
    }
    else if(this.componentId<=54){
    this.router.navigate(['course/ngp/50']);
    }
    else if(this.componentId<=59){
    this.router.navigate(['course/ngp/55']);
    }
    else if(this.componentId<=64){
    this.router.navigate(['course/ngp/60']);
    }
    else if(this.componentId<=69){
    this.router.navigate(['course/ngp/65']);
    }
    else if(this.componentId<=74){
    this.router.navigate(['course/ngp/70']);
    }
    else if(this.componentId<=79){
    this.router.navigate(['course/ngp/75']);
    }
    else if(this.componentId<=84){
    this.router.navigate(['course/ngp/80']);
    }
    }
    onClickCourse(){
    this.router.navigate(['course'])
    }

    logout(){

      localStorage.clear();
      this.router.navigate(['login']);
    }
}