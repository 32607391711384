import { Component, OnInit, Input, ViewChild} from "@angular/core";
import { ActivatedRoute, Router, ParamMap } from "@angular/router";
import { MediaChange, MediaObserver } from "@angular/flex-layout";
import { Subscription } from "rxjs";
import { ToastrService } from 'ngx-toastr';
import { CountdownComponent, CountdownConfig } from 'ngx-countdown';
import { BreakpointObserver, Breakpoints, BreakpointState } from "@angular/cdk/layout";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { GetService } from "src/app/services/get.service";

@Component({
  selector: 'app-case-main-course-view',
  templateUrl: './case-main-course-view.component.html',
  styleUrls: ['./case-main-course-view.component.css']
})
export class CaseMainCourseViewComponent implements OnInit {
  config: CountdownConfig = {
    leftTime: 5,
    format: 'HH:mm:ss',
  };
  @ViewChild('cd',{static: false}) counter: CountdownComponent;
  lastCompleted: number; 
  timerDisableStatus = false;
  disableStatus= true;
  timeLeft: number = 10;
  timerEndFlag: boolean= false;
  interval;
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));
  @Input() deviceXs: boolean;
  mediaSub: Subscription;
  smallDeviceCheck : boolean;
  checkTimer: boolean = true;

  componentId: number
  sideFlag: boolean = true;

  prevTracker: number = 1;
  username:string;
  // componentId: number
  // sideFlag = 1;
  // config:any;
  // checkTimer: boolean = true;
  // sideFlag: boolean = true;

  constructor(private toastr: ToastrService, private statusService: GetService,private router: Router, private route: ActivatedRoute, public mediaObserver: MediaObserver, private breakpointObserver: BreakpointObserver) {
    this.username = localStorage.getItem('firstName');
    this.route.params.subscribe( params => this.componentId = params['id'] );
    this.statusService.getCompletionStatusCaseStudy()
    .subscribe(data=>{
      this.lastCompleted = parseInt(data.toString());
      if(this.componentId <= this.lastCompleted){
        this.timerDisableStatus = true;
      } else if(this.componentId == this.lastCompleted+1){
        this.timerDisableStatus = false;
        this.counter.restart();
      } else{
        this.router.navigate(['course-case-study/case-study', this.lastCompleted+1]);
        this.showWarning();
      }
    },err=>{
      alert("Couldnt get status"+err);
    })
  }

  // timesUp(event) { 
  //   if (event.action == "done") {
  //      console.log("Finished");
  //      this.checkTimer = false;
  //      }
  // }
  
  ngOnInit() {
    window.scrollTo(0, 0);
    this.mediaSub = this.mediaObserver.media$.subscribe((res: MediaChange) => {
      // console.log(res.mqAlias);
      this.deviceXs = res.mqAlias === "xs" ? true : false;
      this.sideFlag = res.mqAlias === "xs" ? false: true;
    });

    this.route.paramMap.subscribe((params: ParamMap) => {
      let id = parseInt(params.get('id'));
      this.componentId = id;
    });
  }
  // button click for next URL
  onClickNext(nextComponent: number) {
    this.statusService.getCompletionStatusCaseStudy()
      .subscribe(data=>{
        this.lastCompleted = parseInt(data.toString());        
        if(nextComponent <= this.lastCompleted){
          this.router.navigate(['course-case-study/case-study', nextComponent]);
          this.componentId = nextComponent;
          this.timerDisableStatus = true;
        } else if(nextComponent == this.lastCompleted+1){
          this.router.navigate(['course-case-study/case-study', nextComponent]);
          this.componentId = nextComponent;
          this.timerDisableStatus = false;
          this.counter.restart();
        }else{
          this.router.navigate(['course-case-study/case-study', this.lastCompleted+1]);
          // console.log("cant proceeddd")
          this.showWarning();
        }
      },err=>{
        alert("Network error, Please try again!!")
        // console.log("Error-",err)
      })
      // console.log("last complete",this.lastCompleted)
   
  }


  markComplete(nextComponentId){
    this.statusService.updateCompletionStatusCaseStudy(this.prevTracker, nextComponentId)
      .subscribe(data=>{
        if(data){
          this.lastCompleted = data["nextId"];
          if(this.lastCompleted == 6){
            // console.log("is 6",this.lastCompleted)
            this.router.navigate(['/']);
          } else {
            this.router.navigate(['course-case-study/case-study', this.lastCompleted+1]);
            this.componentId = this.lastCompleted+1;
            this.timerDisableStatus = false;
            this.counter.restart();
          }
        }
      },err=>{
        alert("something went wrong, please try again!!")
      })
  }


  onClickPrevious(prevComponent: number) {
    // this.componentId = prevComponent;
    // this.router.navigate(['course-case-study/case-study', this.componentId]);
    this.timerDisableStatus = true;
    this.componentId = prevComponent;
    this.router.navigate(['course-case-study/case-study', this.componentId]);
  }

  isCompleted(componentId: number){
    if(componentId <= this.lastCompleted){
      return true;
    }
    return false;
  }

  // small devices check!
  ngOnDestroy() {
    this.mediaSub.unsubscribe();
  }

  // shows warning toast
  showWarning() {
    this.toastr.warning('Please complete this topic first to proceed for next');
  }
  
   // handles timer events
   handleEvent(event){
    // console.log(event)
    if(event.action == 'done'){
      this.timerDisableStatus = true;
    }else if(event.action == 'restart' || 'start'){
      this.timerDisableStatus = false
    }
  }

  // timer method

  // startTimer() {
  //   this.timeLeft= 10;
  //   this.interval = setInterval(() => {
  //     if (this.timeLeft > 0) {
  //       this.timeLeft--;
  //       console.log(this.timeLeft)
  //     }else{
  //       clearInterval(this.interval);
  //     }
  //   }, 1000);
  // }

  // sidebar() {
  //   if (this.sideFlag) {
  //     this.sideFlag = false;
  //   } else {
  //     this.sideFlag = true;
  //   }
  // }
  onfinish(){
    this.router.navigate(['/'])
  }
  logout(){

    localStorage.clear();
    this.router.navigate(['login']);
  }
}
