import { Component, OnInit } from '@angular/core';
import { QuizcourseService } from 'src/app/services/quizcourse.service';
import { ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-chapter-wise-quiz-details',
  templateUrl: './chapter-wise-quiz-details.component.html',
  styleUrls: ['./chapter-wise-quiz-details.component.css']
})
export class ChapterWiseQuizDetailsComponent implements OnInit {
  quizHistory;
  constructor(private courseQuizService: QuizcourseService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      let username = params.get('name');
      this.courseQuizService.getDetailCourseStatus(username)
        .subscribe(data=>{
          this.quizHistory = data;
          console.log(this.quizHistory)
      })
    });
  }
}
