import { Component, OnInit } from '@angular/core';
// import { Component, OnInit } from '@angular/core
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GetService } from 'src/app/services/get.service';

@Component({
  selector: 'app-hawk-eyes-p-chap7-index',
  templateUrl: './hawk-eyes-p-chap7-index.component.html',
  styleUrls: ['./hawk-eyes-p-chap7-index.component.css']
})
export class HawkEyesPChap7IndexComponent implements OnInit {

  lastCompleted: number;
  constructor(private statusService: GetService,private toastr:ToastrService,private router: Router) {
   }

   navigateNgp(componentId: number){
    this.router.navigate(['hawkeyesp/android-material', componentId]);
    }
  
  isCompleted(componentId: number){
    if(componentId <= this.lastCompleted){
      return true;
    }
    return false;
  }
  ngOnInit() {
    window.scrollTo(0, 0);
  }

}
