import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subchap8w-help',
  templateUrl: './subchap8w-help.component.html',
  styleUrls: ['./subchap8w-help.component.css']
})
export class Subchap8wHelpComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
