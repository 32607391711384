import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { UsercreationComponent } from './usercreation/usercreation.component';
import { RandomquestionComponent } from './randomquestion/randomquestion.component';
import { PretestAnalysisComponent } from './pretest-analysis/pretest-analysis.component';
import { TestHistoryComponent } from './test-history/test-history.component';
import { ChapwiseQuizAnalysisComponent } from './chapwise-quiz-analysis/chapwise-quiz-analysis.component';
import { ChapterWiseQuizDetailsComponent } from './chapwise-quiz-analysis/chapter-wise-quiz-details/chapter-wise-quiz-details.component';
import { RandomquestionpostComponent } from './randomquestionpost/randomquestionpost.component';
import { PosttestAnalysisComponent } from './posttest-analysis/posttest-analysis.component';
import { AuthGuard } from '../authgaurd/auth.guard';

const routes: Routes = [
  // { path: 'admin',
  //   children: [
  //     { path: '', component: HomeComponent },
  //     { path: 'create-user', component: UsercreationComponent},
  //     { path: 'question-occurence', component: RandomquestionComponent},
  //     { path: 'pretest-report', component: PretestAnalysisComponent },
  //     { path: 'test-history', component: TestHistoryComponent }
  //   ]
  // },

  { path: 'eyJpZr9cI6oD3cVsImu', component: HomeComponent, canActivate: [AuthGuard],

    children: [
      { path: '', component: UsercreationComponent, canActivate: [AuthGuard] },
      { path: 'create-user', component: UsercreationComponent, canActivate: [AuthGuard] },
      { path: 'question-occurence', component: RandomquestionComponent, canActivate: [AuthGuard] },

      { path: 'pretest-report', component: PretestAnalysisComponent, canActivate: [AuthGuard] },
      { path: 'test-history', component: TestHistoryComponent, canActivate: [AuthGuard] },

      { path: 'chap-history', component: ChapwiseQuizAnalysisComponent , canActivate: [AuthGuard]},
      { path: 'chap-history/:name', component: ChapterWiseQuizDetailsComponent , canActivate: [AuthGuard]},

      {path: 'posttest-report', component: PosttestAnalysisComponent, canActivate: [AuthGuard]},
      {path: 'question-occurence-post',component:RandomquestionpostComponent , canActivate: [AuthGuard]}
  ]},
  {path: '**', redirectTo: '', canActivate: [AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
