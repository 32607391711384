  import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GetService } from 'src/app/services/get.service';
import { QuizcourseService } from 'src/app/services/quizcourse.service';
import { Quiz } from '../../../../shared/quiz';

@Component({
  selector: 'app-quizchap1',
  templateUrl: './quizchap1.component.html',
  styleUrls: ['./quizchap1.component.css']
})
export class Quizchap1Component implements OnInit {
  lastCompleted: number; 
  question : any;
  options: any;
  data: any;
  answerStatus:boolean = false;
  selectedOption:string;
  cssChangeAction:string;
  constructor( private quizcourseService: QuizcourseService,private router: Router,private statusService: GetService) {
    this.statusService.getCompletionStatus()
    .subscribe(data=>{
      this.lastCompleted = parseInt(data.toString());
    },err=>{
      alert("Couldnt get status"+err);
    })

   }

  ngOnInit():void {
    // window.location.reload();
    window.scrollTo(0, 0);
    this.data = this.quizcourseService.getquizzes(0);
    // console.log(this.question);
    this.question = this.data[0];
    this.options = this.data[1];
    

  }

  radioChange(event){
    // console.log(event.value)
    this.selectedOption = event.value;
  }

  onAnswerCheck(){
  this.answerStatus=true;
  this.cssChangeAction=this.selectedOption;
  }

  onSubmitQuiz(){
    // this.answerStatus=true;
    // this.cssChangeAction=this.selectedOption;
    if (this.lastCompleted == 18) {
      this.statusService.updateCompletionStatus(18, 19)
        .subscribe(data => {
          if (data) {
            this.quizcourseService.saveChapterQuizResult(this.question, `chapter_1_2`)
              .subscribe(data => {
                if (data) {
                  this.router.navigate(['/course/ngp/20']);
                } else {
                  alert("Can't update the score, Please try again!!!")
                }
              })
          }
        }, err => {
          alert("something went wrong, please try again!!")
        })
    } else {

    this.quizcourseService.saveChapterQuizResult(this.question, `chapter_1_2`)
      .subscribe(data => {
        if (data) {
          this.router.navigate(['/course/ngp/20']);
        } else {
          alert("Can't update the score, Please try again!!!")
        }
      })

  }
  }
}
