import { Component, OnInit, OnDestroy, Input,ViewChild  } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from "@angular/router";
import { MediaChange, MediaObserver } from "@angular/flex-layout";
import { Subscription } from "rxjs";
import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState
} from "@angular/cdk/layout";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { RouterModule } from "@angular/router";
import { CountdownComponent, CountdownConfig } from 'ngx-countdown';
import { GetService } from "src/app/services/get.service";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-main-do-donts-course-view',
  templateUrl: './main-do-donts-course-view.component.html',
  styleUrls: ['./main-do-donts-course-view.component.css']
})
export class MainDoDontsCourseViewComponent implements OnInit {
  config: CountdownConfig = {
    leftTime: 5,
    format: 'HH:mm:ss',
  };

  @ViewChild('cd',{static: false}) counter: CountdownComponent;
  lastCompleted: number; 
  timerDisableStatus = false;
  disableStatus= true;
  completionStatus: number;
  timeLeft: number = 10;
  timerEndFlag: boolean= false;
  interval;
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));
  @Input() deviceXs: boolean;
  mediaSub: Subscription;
  smallDeviceCheck : boolean;
  checkTimer: boolean = true;
  username:string;
  componentId: number
  sideFlag = 1;

  prevTracker: number = 1;
  constructor(private toastr: ToastrService,private statusService: GetService,private router: Router, private route: ActivatedRoute, public mediaObserver: MediaObserver, private breakpointObserver: BreakpointObserver) {
    this.username = localStorage.getItem('firstName');
    this.route.params.subscribe( params => this.componentId = params['id'] );
    // console.log("....",this.componentId)
    this.statusService.getCompletionStatusDos()
    .subscribe(data=>{
      this.lastCompleted = parseInt(data.toString());
      // console.log(this.lastCompleted)
      if(this.componentId <= this.lastCompleted){
        this.timerDisableStatus = true;
      } else if(this.componentId == this.lastCompleted+1){
        this.timerDisableStatus = false;
        this.counter.restart();
      } else{
        this.router.navigate(['course-do-donts/do-donts', this.lastCompleted+1]);
        this.showWarning();
      }
    },err=>{
      alert("Couldnt get status"+err);
    })
  }

  // timesUp(event) { 
  //   if (event.action == "done") {
  //      console.log("Finished");
  //      this.checkTimer = false;
  //      }
  // }

  ngOnInit() {
    window.scrollTo(0, 0);

    this.route.paramMap.subscribe((params: ParamMap) => {
      let id = parseInt(params.get('id'));
      this.componentId = id;
    });
    
    this.mediaSub = this.mediaObserver.media$.subscribe((res: MediaChange) => {
      // console.log(res.mqAlias);
      this.deviceXs = res.mqAlias === "xs" ? true : false;
    });

    // this.route.paramMap.subscribe((params: ParamMap) => {
    //   let id = parseInt(params.get('id'));
    //   this.componentId = id;
    //   this.checkTimer=true;
    // });
  }
  // button click for next URL
  onClickNext(nextComponent: number) {
    this.statusService.getCompletionStatusDos()
    .subscribe(data=>{
      this.lastCompleted = parseInt(data.toString());        
      if(nextComponent <= this.lastCompleted){
        this.router.navigate(['course-do-donts/do-donts', nextComponent]);
        this.componentId = nextComponent;
        this.timerDisableStatus = true;
      } else if(nextComponent == this.lastCompleted+1){
        this.router.navigate(['course-do-donts/do-donts', nextComponent]);
        this.componentId = nextComponent;
        this.timerDisableStatus = false;
        this.counter.restart();
      }else{
        this.router.navigate(['course-do-donts/do-donts', this.lastCompleted+1]);
        // console.log("cant proceeddd")
        this.showWarning();
      }
    },err=>{
      alert("Network error, Please try again!!")
      // console.log("Error-",err)
    })
    // console.log("last complete",this.lastCompleted)
  }

  markComplete(nextComponentId){
    this.statusService.updateCompletionStatusDosDonts(this.prevTracker, nextComponentId)
      .subscribe(data=>{
        if(data){
          this.lastCompleted = data["nextId"];
          if(this.lastCompleted == 33){
            this.router.navigate(['/']);
          } else {
            this.router.navigate(['course-do-donts/do-donts', this.lastCompleted+1]);
            this.componentId = this.lastCompleted+1;
            this.timerDisableStatus = false;
            this.counter.restart();
          }
        }
      },err=>{
        alert("something went wrong, please try again!!")
      })
  }


  onClickPrevious(prevComponent: number) {
    this.timerDisableStatus = true;
    this.componentId = prevComponent;
    this.router.navigate(['course-do-donts/do-donts', this.componentId]);
  }

  isCompleted(componentId: number){
    if(componentId <= this.lastCompleted){
      return true;
    }
    return false;
  }

  // small devices check!
  ngOnDestroy() {
    this.mediaSub.unsubscribe();
  }

  showWarning() {
    this.toastr.warning('Please complete this topic first to proceed for next');
  }


  // handles timer events
  handleEvent(event){
    // console.log(event)
    if(event.action == 'done'){
      this.timerDisableStatus = true;
    }else if(event.action == 'restart' || 'start'){
      this.timerDisableStatus = false
    }
  }


  // timer method
  // startTimer() {
  //   this.timeLeft= 10;
  //   this.interval = setInterval(() => {
  //     if (this.timeLeft > 0) {
  //       this.timeLeft--;
  //       console.log(this.timeLeft)
  //     }else{
  //       clearInterval(this.interval);
  //     }
  //   }, 1000);
  // }
  

  sidebar() {
    if (this.sideFlag == 1) {
      this.sideFlag = 0;
    } else {
      this.sideFlag = 1;
    }
  }
  onClickLesson(lessonIndex:number){
    this.componentId = lessonIndex;
    if(this.componentId <= 3){
    // this.indexNumber = this.componentId/10;
    this.router.navigate(['course-do-donts/do-donts/1']);
    }
    else if(this.componentId <=6){
    this.router.navigate(['course-do-donts/do-donts/4']);
    }
    else if(this.componentId<=9){
    this.router.navigate(['course-do-donts/do-donts/7']);
    }
    else if(this.componentId<=12){
    this.router.navigate(['course-do-donts/do-donts/10']);
    }
    else if(this.componentId<=15){
    this.router.navigate(['course-do-donts/do-donts/13']);
    }
    else if(this.componentId<=18){
    this.router.navigate(['course-do-donts/do-donts/16']);
    }
    else if(this.componentId<=21){
    this.router.navigate(['course-do-donts/do-donts/19']);
    }
    else if(this.componentId<=24){
    this.router.navigate(['course-do-donts/do-donts/22']);
    }
    else if(this.componentId<=27){
    this.router.navigate(['course-do-donts/do-donts/25']);
    }
    else if(this.componentId<=30){
    this.router.navigate(['course-do-donts/do-donts/28']);
    }
    else if(this.componentId<=33){
    this.router.navigate(['course-do-donts/do-donts/31']);
    }
    
    }
    onClickCourse(){
    this.router.navigate(['course-do-donts'])
    }
    onfinish(){
      this.router.navigate(['/'])
    }

    logout(){

      localStorage.clear();
      this.router.navigate(['login']);
    }
}
