import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hawk-eyes-p-chap4',
  templateUrl: './hawk-eyes-p-chap4.component.html',
  styleUrls: ['./hawk-eyes-p-chap4.component.css']
})
export class HawkEyesPChap4Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
