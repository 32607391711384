import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AdminModule } from './admin/admin.module'

import { AppComponent } from './app.component';
import { HomecardsComponent } from './homecards/homecards.component';
import { HeadersComponent } from './headers/headers.component';
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { LoginComponent } from './login/login.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component'
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TrainingComponent } from './training/training.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { PreindexComponent } from './pretest/preindex/preindex.component';

import { PortalModule } from '@angular/cdk/portal';
import { TestcontentComponent } from './pretest/testcontent/testcontent.component';
import { StartpretestComponent } from './pretest/startpretest/startpretest.component';
import { MainpretestComponent } from './pretest/mainpretest/mainpretest.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
// import { IntroductionComponent } from './course/introduction/introduction.component';

import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { AuthGuard } from './authgaurd/auth.guard';
import { AuthguardServiceService } from './authgaurd/authguard-service.service';
import { TokenInterceptorService } from './authgaurd/token-interceptor.service';
import { TestResultComponent } from './pretest/test-result/test-result.component';
import {​​​​​​​​ MatToolbarModule }​​​​​​​​ from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { CountdownGlobalConfig, CountdownModule } from 'ngx-countdown';
import { ɵMatchMedia, BreakPointRegistry, PrintHook ,MediaObserver} from '@angular/flex-layout/core';
import { FlexLayoutModule, StyleUtils, StylesheetMap, LayoutStyleBuilder, MediaMarshaller, LayoutAlignStyleBuilder, FlexStyleBuilder } from '@angular/flex-layout';
// import { HeaderComponent } from './course/header/header.component';
import { MainIndexComponent } from './course/main-index/main-index.component';
// import { IndexComponent } from './course/chapter-1-2/index/index.component';
// import { ComplianceReqComponent } from './course/chapter-1-2/compliance-req/compliance-req.component';
// import { Annexure1Component } from './course/chapter-1-2/annexure1/annexure1.component';
// import { NecessityOfReqComponent } from './course/chapter-1-2/necessity-of-req/necessity-of-req.component';
// import { NonComplianceResComponent } from './course/chapter-1-2/non-compliance-res/non-compliance-res.component';
import { MainCourseViewComponent } from './course/main-course-view/main-course-view/main-course-view.component';
// import { TestingComponent } from './course/testing/testing.component';
import { ComplianceRequirementComponent } from './course/main-course-view/NGP-Chap1,2/compliance-requirement/compliance-requirement.component';
import { NecessityOfRequirementComponent } from './course/main-course-view/NGP-Chap1,2/necessity-of-requirement/necessity-of-requirement.component';
// import { NonComplianceRequirementComponent } from './course/main-course-view/NGP-Chap1,2/non-compliance-requirement/non-compliance-requirement.component';
import { NonComplianceResultComponent } from './course/main-course-view/NGP-Chap1,2/non-compliance-result/non-compliance-result.component';
import { IndexChap1Component } from './course/main-course-view/NGP-Chap1,2/index-chap1/index-chap1.component';
import { Topic1LEAFElectricVehicleComponent } from './course/main-course-view/NGP-Introduction/topic1-leaf-electric-vehicle/topic1-leaf-electric-vehicle.component';
import { Topic2EVChargingMethodsComponent } from './course/main-course-view/NGP-Introduction/topic2-ev-charging-methods/topic2-ev-charging-methods.component';
import { Topic3ChargingPortComponent } from './course/main-course-view/NGP-Introduction/topic3-charging-port/topic3-charging-port.component';
import { Topic4Mode2AndMode3Component } from './course/main-course-view/NGP-Introduction/topic4-mode2-and-mode3/topic4-mode2-and-mode3.component';
import { Topic5CaseBAndCaseCComponent } from './course/main-course-view/NGP-Introduction/topic5-case-b-and-case-c/topic5-case-b-and-case-c.component';
import { Topic6ModesAndCaseForEVComponent } from './course/main-course-view/NGP-Introduction/topic6-modes-and-case-for-ev/topic6-modes-and-case-for-ev.component';
import { Topic7VehicleInletTypeComponent } from './course/main-course-view/NGP-Introduction/topic7-vehicle-inlet-type/topic7-vehicle-inlet-type.component';
import { Topic8ChargingTimeComponent } from './course/main-course-view/NGP-Introduction/topic8-charging-time/topic8-charging-time.component';
import { Topic9ChargingPowerComponent } from './course/main-course-view/NGP-Introduction/topic9-charging-power/topic9-charging-power.component';
import { Topic10MainWiringDiagComponent } from './course/main-course-view/NGP-Introduction/topic10-main-wiring-diag/topic10-main-wiring-diag.component';
import { Topic1Chap1ToChap8Component } from './course/main-course-view/NGP-Requirement/topic1-chap1-to-chap8/topic1-chap1-to-chap8.component';
import { Topic2Chap9ToChap15Component } from './course/main-course-view/NGP-Requirement/topic2-chap9-to-chap15/topic2-chap9-to-chap15.component';
import { IndexIntroComponent } from './course/main-course-view/NGP-Introduction/index-intro/index-intro.component';
import { IndexRequirementComponent } from './course/main-course-view/NGP-Requirement/index-requirement/index-requirement.component';
import { IndexChap3Component } from './course/main-course-view/NGP-Chap3/index-chap3/index-chap3.component';
import { ComplianceRequirementLess3Component } from './course/main-course-view/NGP-Chap3/compliance-requirement-less3/compliance-requirement-less3.component';
import { NecessityOfRequirementLess3Component } from './course/main-course-view/NGP-Chap3/necessity-of-requirement-less3/necessity-of-requirement-less3.component';
import { NonComplianceResultLess3Component } from './course/main-course-view/NGP-Chap3/non-compliance-result-less3/non-compliance-result-less3.component';
import { ComplianceRequirementLess4Component } from './course/main-course-view/NGP-Chap4/compliance-requirement-less4/compliance-requirement-less4.component';
import { IndexChap4Component } from './course/main-course-view/NGP-Chap4/index-chap4/index-chap4.component';
import { NecessityOfRequirementLess4Component } from './course/main-course-view/NGP-Chap4/necessity-of-requirement-less4/necessity-of-requirement-less4.component';
import { NonComplianceResultLess4Component } from './course/main-course-view/NGP-Chap4/non-compliance-result-less4/non-compliance-result-less4.component';
import { ComplianceRequirementLess5Component } from './course/main-course-view/NGP-Chap5/compliance-requirement-less5/compliance-requirement-less5.component';
import { IndexChap5Component } from './course/main-course-view/NGP-Chap5/index-chap5/index-chap5.component';
import { NecessityOfRequirementLess5Component } from './course/main-course-view/NGP-Chap5/necessity-of-requirement-less5/necessity-of-requirement-less5.component';
import { NonComplianceResultLess5Component } from './course/main-course-view/NGP-Chap5/non-compliance-result-less5/non-compliance-result-less5.component';
import { ComplianceRequirementLess6Component } from './course/main-course-view/NGP-Chap6/compliance-requirement-less6/compliance-requirement-less6.component';
import { IndexChap6Component } from './course/main-course-view/NGP-Chap6/index-chap6/index-chap6.component';
import { NecessityOfRequirementLess6Component } from './course/main-course-view/NGP-Chap6/necessity-of-requirement-less6/necessity-of-requirement-less6.component';
import { NonComplianceResultLess6Component } from './course/main-course-view/NGP-Chap6/non-compliance-result-less6/non-compliance-result-less6.component';
import { ComplianceRequirementLess7Component } from './course/main-course-view/NGP-Chap7/compliance-requirement-less7/compliance-requirement-less7.component';
import { IndexChap7Component } from './course/main-course-view/NGP-Chap7/index-chap7/index-chap7.component';
import { NonComplianceResultLess7Component } from './course/main-course-view/NGP-Chap7/non-compliance-result-less7/non-compliance-result-less7.component';
import { NessecityOfRequirementLess7Component } from './course/main-course-view/NGP-Chap7/nessecity-of-requirement-less7/nessecity-of-requirement-less7.component';
import { ComplianceRequirementLess8Component } from './course/main-course-view/NGP-Chap8/compliance-requirement-less8/compliance-requirement-less8.component';
import { IndexChap8Component } from './course/main-course-view/NGP-Chap8/index-chap8/index-chap8.component';
import { NecessityOfRequirementLess8Component } from './course/main-course-view/NGP-Chap8/necessity-of-requirement-less8/necessity-of-requirement-less8.component';
import { NonComplianceResultLess8Component } from './course/main-course-view/NGP-Chap8/non-compliance-result-less8/non-compliance-result-less8.component';
import { ComplianceRequirementLess9Component } from './course/main-course-view/NGP-Chap9/compliance-requirement-less9/compliance-requirement-less9.component';
import { IndexChap9Component } from './course/main-course-view/NGP-Chap9/index-chap9/index-chap9.component';
import { NecessityOfRequirementLess9Component } from './course/main-course-view/NGP-Chap9/necessity-of-requirement-less9/necessity-of-requirement-less9.component';
import { NonComplianceResultLess9Component } from './course/main-course-view/NGP-Chap9/non-compliance-result-less9/non-compliance-result-less9.component';
import { IndexChap10Component } from './course/main-course-view/NGP-Chap10/index-chap10/index-chap10.component';
import { ComplianceRequirementLess10Component } from './course/main-course-view/NGP-Chap10/compliance-requirement-less10/compliance-requirement-less10.component';
import { NecessityOfRequirementLess10Component } from './course/main-course-view/NGP-Chap10/necessity-of-requirement-less10/necessity-of-requirement-less10.component';
import { NonComplianceResultLess10Component } from './course/main-course-view/NGP-Chap10/non-compliance-result-less10/non-compliance-result-less10.component';
import { ComplianceRequirementLess11Component } from './course/main-course-view/NGP-Chap11/compliance-requirement-less11/compliance-requirement-less11.component';
import { IndexChap11Component } from './course/main-course-view/NGP-Chap11/index-chap11/index-chap11.component';
import { NecessityOfRequirementLess11Component } from './course/main-course-view/NGP-Chap11/necessity-of-requirement-less11/necessity-of-requirement-less11.component';
import { NonComplianceResultLess11Component } from './course/main-course-view/NGP-Chap11/non-compliance-result-less11/non-compliance-result-less11.component';
import { ComplianceRequirementLess12Component } from './course/main-course-view/NGP-Chap12/compliance-requirement-less12/compliance-requirement-less12.component';
import { IndexChap12Component } from './course/main-course-view/NGP-Chap12/index-chap12/index-chap12.component';
import { NecessityOfRequirementLess12Component } from './course/main-course-view/NGP-Chap12/necessity-of-requirement-less12/necessity-of-requirement-less12.component';
import { NonComplianceResultLess12Component } from './course/main-course-view/NGP-Chap12/non-compliance-result-less12/non-compliance-result-less12.component';
import { ComplianceRequirementLess13Component } from './course/main-course-view/NGP-Chap13/compliance-requirement-less13/compliance-requirement-less13.component';
import { IndexChap13Component } from './course/main-course-view/NGP-Chap13/index-chap13/index-chap13.component';
import { NecessityOfRequirementLess13Component } from './course/main-course-view/NGP-Chap13/necessity-of-requirement-less13/necessity-of-requirement-less13.component';
import { NonComplianceResultLess13Component } from './course/main-course-view/NGP-Chap13/non-compliance-result-less13/non-compliance-result-less13.component';
import { ComplienceRequirementLess14Component } from './course/main-course-view/NGP-Chap14/complience-requirement-less14/complience-requirement-less14.component';
import { IndexChap14Component } from './course/main-course-view/NGP-Chap14/index-chap14/index-chap14.component';
import { NecessityOfRequirementLess14Component } from './course/main-course-view/NGP-Chap14/necessity-of-requirement-less14/necessity-of-requirement-less14.component';
import { NonComplianceResultLess14Component } from './course/main-course-view/NGP-Chap14/non-compliance-result-less14/non-compliance-result-less14.component';
import { ComplianceRequirementLess15Component } from './course/main-course-view/NGP-Chap15/compliance-requirement-less15/compliance-requirement-less15.component';
import { IndexChap15Component } from './course/main-course-view/NGP-Chap15/index-chap15/index-chap15.component';
import { NecessityOfRequirementLess15Component } from './course/main-course-view/NGP-Chap15/necessity-of-requirement-less15/necessity-of-requirement-less15.component';
import { NonComplianceResultLess15Component } from './course/main-course-view/NGP-Chap15/non-compliance-result-less15/non-compliance-result-less15.component';
import { Quizchap1Component } from "./course/main-course-view/NGP-Chap1,2/quizchap1/quizchap1.component";
import { Quizchap3Component } from './course/main-course-view/NGP-Chap3/quizchap3/quizchap3.component';
import { Quizchap4Component } from './course/main-course-view/NGP-Chap4/quizchap4/quizchap4.component';
import { Quizchap5Component } from './course/main-course-view/NGP-Chap5/quizchap5/quizchap5.component';
import { Quizchap6Component } from './course/main-course-view/NGP-Chap6/quizchap6/quizchap6.component';
import { Quizchap7Component } from './course/main-course-view/NGP-Chap7/quizchap7/quizchap7.component';
import { Quizchap8Component } from './course/main-course-view/NGP-Chap8/quizchap8/quizchap8.component';
import { Quizchap9Component } from './course/main-course-view/NGP-Chap9/quizchap9/quizchap9.component';
import { Quizchap10Component } from './course/main-course-view/NGP-Chap10/quizchap10/quizchap10.component';
import { Quizchap11Component } from './course/main-course-view/NGP-Chap11/quizchap11/quizchap11.component';
import { Quizchap12Component } from './course/main-course-view/NGP-Chap12/quizchap12/quizchap12.component';
import { Quizchap13Component } from './course/main-course-view/NGP-Chap13/quizchap13/quizchap13.component';
import { Quizchap14Component } from './course/main-course-view/NGP-Chap14/quizchap14/quizchap14.component';
import { Quizchap15Component } from './course/main-course-view/NGP-Chap15/quizchap15/quizchap15.component';
import { CaseMainCourseViewComponent } from './course/case-study/caseStudy-main-course-view/case-main-course-view/case-main-course-view.component';
import { CaseStudyChap1Component } from './course/case-study/caseStudy-main-course-view/case-study-chap1/case-study-chap1.component';
import { CaseStudyChap2Component } from './course/case-study/caseStudy-main-course-view/case-study-chap2/case-study-chap2.component';
import { CaseStudyChap3Component } from './course/case-study/caseStudy-main-course-view/case-study-chap3/case-study-chap3.component';
import { CaseStudyChap4Component } from './course/case-study/caseStudy-main-course-view/case-study-chap4/case-study-chap4.component';
import { CaseStudyChap5Component } from './course/case-study/caseStudy-main-course-view/case-study-chap5/case-study-chap5.component';
import { CaseStudyChap6Component } from './course/case-study/caseStudy-main-course-view/case-study-chap6/case-study-chap6.component';
import { CaseStudyMainIndexComponent } from './course/case-study/case-study-main-index/case-study-main-index.component';




import { MainDoDontsCourseViewComponent } from './course/do-donts/main-do-donts-course-view/main-do-donts-course-view/main-do-donts-course-view.component';
import { MainDoDontsIndexComponent } from './course/do-donts/main-do-donts-index/main-do-donts-index.component';
import { AnnexureChap1Component } from './course/main-course-view/NGP-Chap1,2/annexure-chap1/annexure-chap1.component';
import { AnnexureChap3Component } from './course/main-course-view/NGP-Chap3/annexure-chap3/annexure-chap3.component';
import { AnnexureChap4Component } from './course/main-course-view/NGP-Chap4/annexure-chap4/annexure-chap4.component';
import { AnnexureChap5Component } from './course/main-course-view/NGP-Chap5/annexure-chap5/annexure-chap5.component';
import { AnnexureChap6Component } from './course/main-course-view/NGP-Chap6/annexure-chap6/annexure-chap6.component';
import { AnnexureChap7Component } from './course/main-course-view/NGP-Chap7/annexure-chap7/annexure-chap7.component';
import { AnnexureChap8Component } from './course/main-course-view/NGP-Chap8/annexure-chap8/annexure-chap8.component';
import { AnnexureChap9Component } from './course/main-course-view/NGP-Chap9/annexure-chap9/annexure-chap9.component';
import { AnnexureChap10Component } from './course/main-course-view/NGP-Chap10/annexure-chap10/annexure-chap10.component';
import { AnnexureChap13aComponent } from './course/main-course-view/NGP-Chap13/annexure-chap13a/annexure-chap13a.component';
import { AnnexureChap13bComponent } from './course/main-course-view/NGP-Chap13/annexure-chap13b/annexure-chap13b.component';
import { AnnexureChap14Component } from './course/main-course-view/NGP-Chap14/annexure-chap14/annexure-chap14.component';
import { DoDontsIndexChap1Component } from './course/do-donts/main-do-donts-course-view/Do-Donts-Chap1/do-donts-index-chap1/do-donts-index-chap1.component';
import { NecessityOfPointLess1Component } from './course/do-donts/main-do-donts-course-view/Do-Donts-Chap1/necessity-of-point-less1/necessity-of-point-less1.component';
import { DosDontsNonComplianceLess1Component } from './course/do-donts/main-do-donts-course-view/Do-Donts-Chap1/dos-donts-non-compliance-less1/dos-donts-non-compliance-less1.component';
import { DoDontsIndexChap2Component } from './course/do-donts/main-do-donts-course-view/Do-Donts-Chap2/do-donts-index-chap2/do-donts-index-chap2.component';
import { NecessityOfPointLess2Component } from './course/do-donts/main-do-donts-course-view/Do-Donts-Chap2/necessity-of-point-less2/necessity-of-point-less2.component';
import { DosDontsNonComplianceLess2Component } from './course/do-donts/main-do-donts-course-view/Do-Donts-Chap2/dos-donts-non-compliance-less2/dos-donts-non-compliance-less2.component';
import { DoDontsIndexChap3Component } from './course/do-donts/main-do-donts-course-view/Do-Donts-Chap3/do-donts-index-chap3/do-donts-index-chap3.component';
import { NecessityOfPointLess3Component } from './course/do-donts/main-do-donts-course-view/Do-Donts-Chap3/necessity-of-point-less3/necessity-of-point-less3.component';
import { DosDontsNonComplianceLess3Component } from './course/do-donts/main-do-donts-course-view/Do-Donts-Chap3/dos-donts-non-compliance-less3/dos-donts-non-compliance-less3.component';
import { DoDontsIndexChap4Component } from './course/do-donts/main-do-donts-course-view/Do-Donts-Chap4/do-donts-index-chap4/do-donts-index-chap4.component';
import { NecessityOfPointLess4Component } from './course/do-donts/main-do-donts-course-view/Do-Donts-Chap4/necessity-of-point-less4/necessity-of-point-less4.component';
import { DosDontsNonComplianceLess4Component } from './course/do-donts/main-do-donts-course-view/Do-Donts-Chap4/dos-donts-non-compliance-less4/dos-donts-non-compliance-less4.component';
import { DoDontsIndexChap5Component } from './course/do-donts/main-do-donts-course-view/Do-Donts-Chap5/do-donts-index-chap5/do-donts-index-chap5.component';
import { NecessityOfPointLess5Component } from './course/do-donts/main-do-donts-course-view/Do-Donts-Chap5/necessity-of-point-less5/necessity-of-point-less5.component';
import { DosDontsNonComplianceLess5Component } from './course/do-donts/main-do-donts-course-view/Do-Donts-Chap5/dos-donts-non-compliance-less5/dos-donts-non-compliance-less5.component';
import { DoDontsIndexChap6Component } from './course/do-donts/main-do-donts-course-view/Do-Donts-Chap6/do-donts-index-chap6/do-donts-index-chap6.component';
import { NecessityOfPointLess6Component } from './course/do-donts/main-do-donts-course-view/Do-Donts-Chap6/necessity-of-point-less6/necessity-of-point-less6.component';
import { DosDontsNonComplianceLess6Component } from './course/do-donts/main-do-donts-course-view/Do-Donts-Chap6/dos-donts-non-compliance-less6/dos-donts-non-compliance-less6.component';
import { DoDontsIndexChap7Component } from './course/do-donts/main-do-donts-course-view/Do-Donts-Chap7/do-donts-index-chap7/do-donts-index-chap7.component';
import { NecessityOfPointLess7Component } from './course/do-donts/main-do-donts-course-view/Do-Donts-Chap7/necessity-of-point-less7/necessity-of-point-less7.component';
import { DosDontsNonComplianceLess7Component } from './course/do-donts/main-do-donts-course-view/Do-Donts-Chap7/dos-donts-non-compliance-less7/dos-donts-non-compliance-less7.component';
import { DoDontsIndexChap8Component } from './course/do-donts/main-do-donts-course-view/Do-Donts-Chap8/do-donts-index-chap8/do-donts-index-chap8.component';
import { NecessityOfPointLess8Component } from './course/do-donts/main-do-donts-course-view/Do-Donts-Chap8/necessity-of-point-less8/necessity-of-point-less8.component';
import { DosDontsNonComplianceLess8Component } from './course/do-donts/main-do-donts-course-view/Do-Donts-Chap8/dos-donts-non-compliance-less8/dos-donts-non-compliance-less8.component';
import { DoDontsIndexChap9Component } from './course/do-donts/main-do-donts-course-view/Do-Donts-Chap9/do-donts-index-chap9/do-donts-index-chap9.component';
import { NecessityOfPointLess9Component } from './course/do-donts/main-do-donts-course-view/Do-Donts-Chap9/necessity-of-point-less9/necessity-of-point-less9.component';
import { DosDontsNonComplianceLess9Component } from './course/do-donts/main-do-donts-course-view/Do-Donts-Chap9/dos-donts-non-compliance-less9/dos-donts-non-compliance-less9.component';
import { DoDontsIndexChap10Component } from './course/do-donts/main-do-donts-course-view/Do-Donts-Chap10/do-donts-index-chap10/do-donts-index-chap10.component';
import { NecessityOfPointLess10Component } from './course/do-donts/main-do-donts-course-view/Do-Donts-Chap10/necessity-of-point-less10/necessity-of-point-less10.component';
import { DosDontsNonComplianceLess10Component } from './course/do-donts/main-do-donts-course-view/Do-Donts-Chap10/dos-donts-non-compliance-less10/dos-donts-non-compliance-less10.component';
import { DoDontsIndexChap11Component } from './course/do-donts/main-do-donts-course-view/Do-Donts-Chap11/do-donts-index-chap11/do-donts-index-chap11.component';
import { NecessityOfPointLess11Component } from './course/do-donts/main-do-donts-course-view/Do-Donts-Chap11/necessity-of-point-less11/necessity-of-point-less11.component';
import { DosDontsNonComplianceLess11Component } from './course/do-donts/main-do-donts-course-view/Do-Donts-Chap11/dos-donts-non-compliance-less11/dos-donts-non-compliance-less11.component';
// import { StatusAlertComponent } from './status-alert/status-alert.component';

// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ToastrModule } from 'ngx-toastr';
import { MainPostTestComponent } from './posttest/main-post-test/main-post-test.component';
import { InstructionPostTestComponent } from './posttest/instruction-post-test/instruction-post-test.component';
import { ResultPostTestComponent } from './posttest/result-post-test/result-post-test.component';
import { TestContentPostTestComponent } from './posttest/test-content-post-test/test-content-post-test.component';
import { HawkEyesPMainIndexComponent } from './course/hawk-EyesP/hawk-eyes-p-main-index/hawk-eyes-p-main-index.component';
import { MainHawkEyesPCourseViewComponent } from './course/hawk-EyesP/main-hawkEyesP-course-view/main-hawk-eyes-p-course-view/main-hawk-eyes-p-course-view.component';
// import { HawkEyesPChap1Component} from './course/hawk-EyesP/main-hawkEyesP-course-view/main-hawk-eyes-p-course-view/main-hawk-eyes-p-course-view.component';
import { HawkEyesPChap1Component } from "./course/hawk-EyesP/main-hawkEyesP-course-view/hawk-eyes-p-chapter1/hawk-eyes-p-chap1/hawk-eyes-p-chap1.component";
import { HawkEyesPChap2Component } from './course/hawk-EyesP/main-hawkEyesP-course-view/hawk-eyes-p-chapter2/hawk-eyes-p-chap2/hawk-eyes-p-chap2.component';
import { HawkEyesPChap3Component } from './course/hawk-EyesP/main-hawkEyesP-course-view/hawk-eyes-p-chapter3/hawk-eyes-p-chap3/hawk-eyes-p-chap3.component';
import { HawkEyesPChap4Component } from './course/hawk-EyesP/main-hawkEyesP-course-view/hawk-eyes-p-chapter4/hawk-eyes-p-chap4/hawk-eyes-p-chap4.component';
import { HawkEyesPChap5Component } from './course/hawk-EyesP/main-hawkEyesP-course-view/hawk-eyes-p-chapter5/hawk-eyes-p-chap5/hawk-eyes-p-chap5.component';
import { HawkEyesPChap6Component } from './course/hawk-EyesP/main-hawkEyesP-course-view/hawk-eyes-p-chapter6/hawk-eyes-p-chap6/hawk-eyes-p-chap6.component';
import { Chap7aOverflowComponent } from './course/hawk-EyesP/main-hawkEyesP-course-view/hawk-eyes-p-chapter7/chap7a-overflow/chap7a-overflow.component';
import { Chap7bInstallStepsComponent } from './course/hawk-EyesP/main-hawkEyesP-course-view/hawk-eyes-p-chapter7/chap7b-install-steps/chap7b-install-steps.component';
import { Chap7cAppReceiveComponent } from './course/hawk-EyesP/main-hawkEyesP-course-view/hawk-eyes-p-chapter7/chap7c-app-receive/chap7c-app-receive.component';
import { HawkEyesPChap7IndexComponent } from './course/hawk-EyesP/main-hawkEyesP-course-view/hawk-eyes-p-chapter7/hawk-eyes-p-chap7-index/hawk-eyes-p-chap7-index.component';
import { Chap7dPreSettingComponent } from './course/hawk-EyesP/main-hawkEyesP-course-view/hawk-eyes-p-chapter7/chap7d-pre-setting/chap7d-pre-setting.component';
import { Chap7ePreSetting1Component } from './course/hawk-EyesP/main-hawkEyesP-course-view/hawk-eyes-p-chapter7/chap7e-pre-setting1/chap7e-pre-setting1.component';
import { Chap7fPreSetting2Component } from './course/hawk-EyesP/main-hawkEyesP-course-view/hawk-eyes-p-chapter7/chap7f-pre-setting2/chap7f-pre-setting2.component';
import { Chap7gHawkEyespInstallComponent } from './course/hawk-EyesP/main-hawkEyesP-course-view/hawk-eyes-p-chapter7/chap7g-hawk-eyesp-install/chap7g-hawk-eyesp-install.component';
import { Chap7hPreSettingVer8Component } from './course/hawk-EyesP/main-hawkEyesP-course-view/hawk-eyes-p-chapter7/chap7h-pre-setting-ver8/chap7h-pre-setting-ver8.component';
import { Chap7iPreSetting1Ver8Component } from './course/hawk-EyesP/main-hawkEyesP-course-view/hawk-eyes-p-chapter7/chap7i-pre-setting1-ver8/chap7i-pre-setting1-ver8.component';
import { Chap7jHawkEyespInstallVer8Component } from './course/hawk-EyesP/main-hawkEyesP-course-view/hawk-eyes-p-chapter7/chap7j-hawk-eyesp-install-ver8/chap7j-hawk-eyesp-install-ver8.component';
import { Chap7kPostSettingComponent } from './course/hawk-EyesP/main-hawkEyesP-course-view/hawk-eyes-p-chapter7/chap7k-post-setting/chap7k-post-setting.component';
import { Chap7LPostSetting1Component } from './course/hawk-EyesP/main-hawkEyesP-course-view/hawk-eyes-p-chapter7/chap7-l-post-setting1/chap7-l-post-setting1.component';
import { Chap7mPostSetting2Component } from './course/hawk-EyesP/main-hawkEyesP-course-view/hawk-eyes-p-chapter7/chap7m-post-setting2/chap7m-post-setting2.component';
import { Chap7nAppIconHomeComponent } from './course/hawk-EyesP/main-hawkEyesP-course-view/hawk-eyes-p-chapter7/chap7n-app-icon-home/chap7n-app-icon-home.component';
import { HawkEyespChap8IndexComponent } from './course/hawk-EyesP/main-hawkEyesP-course-view/hawk-eyes-p-chapter8/hawk-eyesp-chap8-index/hawk-eyesp-chap8-index.component';
import { Chap8aMainScreenComponent } from './course/hawk-EyesP/main-hawkEyesP-course-view/hawk-eyes-p-chapter8/chap8a-main-screen/chap8a-main-screen.component';
import { Chap8bNewInstallMenuComponent } from './course/hawk-EyesP/main-hawkEyesP-course-view/hawk-eyes-p-chapter8/chap8b-new-install-menu/chap8b-new-install-menu.component';
import { Chap8cReviewInstallMenuComponent } from './course/hawk-EyesP/main-hawkEyesP-course-view/hawk-eyes-p-chapter8/chap8c-review-install-menu/chap8c-review-install-menu.component';
import { Chap8dLangSelectionComponent } from './course/hawk-EyesP/main-hawkEyesP-course-view/hawk-eyes-p-chapter8/chap8d-lang-selection/chap8d-lang-selection.component';
import { Chap8eLocationComponent } from './course/hawk-EyesP/main-hawkEyesP-course-view/hawk-eyes-p-chapter8/chap8e-location/chap8e-location.component';
import { Chap8fMainsBreakerComponent } from './course/hawk-EyesP/main-hawkEyesP-course-view/hawk-eyes-p-chapter8/chap8f-mains-breaker/chap8f-mains-breaker.component';
import { Chap8gBranchBreakerComponent } from './course/hawk-EyesP/main-hawkEyesP-course-view/hawk-eyes-p-chapter8/chap8g-branch-breaker/chap8g-branch-breaker.component';
import { Chap8hEvDedicatedComponent } from './course/hawk-EyesP/main-hawkEyesP-course-view/hawk-eyes-p-chapter8/chap8h-ev-dedicated/chap8h-ev-dedicated.component';
import { Chap8iEvDedicatedMcbComponent } from './course/hawk-EyesP/main-hawkEyesP-course-view/hawk-eyes-p-chapter8/chap8i-ev-dedicated-mcb/chap8i-ev-dedicated-mcb.component';
import { Chap8jEvDedicatedSubDBComponent } from './course/hawk-EyesP/main-hawkEyesP-course-view/hawk-eyes-p-chapter8/chap8j-ev-dedicated-sub-db/chap8j-ev-dedicated-sub-db.component';
import { Chap8kEarthingComponent } from './course/hawk-EyesP/main-hawkEyesP-course-view/hawk-eyes-p-chapter8/chap8k-earthing/chap8k-earthing.component';
import { Chap8lWiringDetailComponent } from './course/hawk-EyesP/main-hawkEyesP-course-view/hawk-eyes-p-chapter8/chap8l-wiring-detail/chap8l-wiring-detail.component';
import { Chap8mRecommWiringDetailComponent } from './course/hawk-EyesP/main-hawkEyesP-course-view/hawk-eyes-p-chapter8/chap8m-recomm-wiring-detail/chap8m-recomm-wiring-detail.component';
import { Chap8nImagesWiringDetailComponent } from './course/hawk-EyesP/main-hawkEyesP-course-view/hawk-eyes-p-chapter8/chap8n-images-wiring-detail/chap8n-images-wiring-detail.component';
import { Chap8oWallboxSocketComponent } from './course/hawk-EyesP/main-hawkEyesP-course-view/hawk-eyes-p-chapter8/chap8o-wallbox-socket/chap8o-wallbox-socket.component';
import { Chap8pSpdComponent } from './course/hawk-EyesP/main-hawkEyesP-course-view/hawk-eyes-p-chapter8/chap8p-spd/chap8p-spd.component';
import { Chap8qAddProtectionComponent } from './course/hawk-EyesP/main-hawkEyesP-course-view/hawk-eyes-p-chapter8/chap8q-add-protection/chap8q-add-protection.component';
import { Chap8rPostcheckInstallComponent } from './course/hawk-EyesP/main-hawkEyesP-course-view/hawk-eyes-p-chapter8/chap8r-postcheck-install/chap8r-postcheck-install.component';
import { Chap8sPostcheckImagesComponent } from './course/hawk-EyesP/main-hawkEyesP-course-view/hawk-eyes-p-chapter8/chap8s-postcheck-images/chap8s-postcheck-images.component';
import { Chap8bNewInstallThreephaseComponent } from './course/hawk-EyesP/main-hawkEyesP-course-view/hawk-eyes-p-chapter8/chap8b-new-install-threephase/chap8b-new-install-threephase.component';
import { Chap8tEmailComponent } from './course/hawk-EyesP/main-hawkEyesP-course-view/hawk-eyes-p-chapter8/chap8t-email/chap8t-email.component';
import { Chap8uEmail1Component } from './course/hawk-EyesP/main-hawkEyesP-course-view/hawk-eyes-p-chapter8/chap8u-email1/chap8u-email1.component';
import { Chap8vEmail2Component } from './course/hawk-EyesP/main-hawkEyesP-course-view/hawk-eyes-p-chapter8/chap8v-email2/chap8v-email2.component';
import { Chap8wHelpComponent } from './course/hawk-EyesP/main-hawkEyesP-course-view/hawk-eyes-p-chapter8/chap8w-help/chap8w-help.component';
import { Chap8xReportGenerationComponent } from './course/hawk-EyesP/main-hawkEyesP-course-view/hawk-eyes-p-chapter8/chap8x-report-generation/chap8x-report-generation.component';

//for hawkeyes ios
import { HawkeyesIosMainIndexComponent } from './course/hawkeyes-ios/hawkeyes-ios-main-index/hawkeyes-ios-main-index.component';
import { HawkeyesIosMainCourseViewComponent } from './course/hawkeyes-ios/hawkeyes-ios-course-view/hawkeyes-ios-main-course-view/hawkeyes-ios-main-course-view.component';
import { HawkeyesiosObjectiveComponent } from './course/hawkeyes-ios/hawkeyes-ios-course-view/hawkeyesios-objective/hawkeyesios-objective.component';
import { HawkeyesiosDatainputsComponent } from './course/hawkeyes-ios/hawkeyes-ios-course-view/hawkeyesios-datainputs/hawkeyesios-datainputs.component';
import { HwkeyeiosInstallationReportComponent } from './course/hawkeyes-ios/hawkeyes-ios-course-view/hwkeyeios-installation-report/hwkeyeios-installation-report.component';
import { HawkeyesIosIntroComponent } from './course/hawkeyes-ios/hawkeyes-ios-course-view/hawkeyes-ios-intro/hawkeyes-ios-intro.component';
import { HawkeyesiosFeaturesComponent } from './course/hawkeyes-ios/hawkeyes-ios-course-view/hawkeyesios-features/hawkeyesios-features.component';
import { HawkeyesiosToolarchComponent } from './course/hawkeyes-ios/hawkeyes-ios-course-view/hawkeyesios-toolarch/hawkeyesios-toolarch.component';
import { HawkeyesiosApplicationinstallationIndexComponent } from './course/hawkeyes-ios/hawkeyes-ios-course-view/hawkeyesios-applicationinstallation-index/hawkeyesios-applicationinstallation-index.component';
import { Chapt8IndexComponent } from './course/hawkeyes-ios/hawkeyes-ios-course-view/workflow of hawkeyes-ios-chapter8/chapt8-index/chapt8-index.component';
import { Subchap8aMainScreenComponent } from './course/hawkeyes-ios/hawkeyes-ios-course-view/workflow of hawkeyes-ios-chapter8/subchap8a-main-screen/subchap8a-main-screen.component';
import { Subchap8bNewInstallComponent } from './course/hawkeyes-ios/hawkeyes-ios-course-view/workflow of hawkeyes-ios-chapter8/subchap8b-new-install/subchap8b-new-install.component';
import { Subchap8cNewInstallPhase3Component } from './course/hawkeyes-ios/hawkeyes-ios-course-view/workflow of hawkeyes-ios-chapter8/subchap8c-new-install-phase3/subchap8c-new-install-phase3.component';
import { Subchap8dReviewInstallationComponent } from './course/hawkeyes-ios/hawkeyes-ios-course-view/workflow of hawkeyes-ios-chapter8/subchap8d-review-installation/subchap8d-review-installation.component';
import { Subchap8eLanguageSelectionComponent } from './course/hawkeyes-ios/hawkeyes-ios-course-view/workflow of hawkeyes-ios-chapter8/subchap8e-language-selection/subchap8e-language-selection.component';
import { Subchap8fLocationComponent } from './course/hawkeyes-ios/hawkeyes-ios-course-view/workflow of hawkeyes-ios-chapter8/subchap8f-location/subchap8f-location.component';
import { Subchap8gMainsBreakerComponent } from './course/hawkeyes-ios/hawkeyes-ios-course-view/workflow of hawkeyes-ios-chapter8/subchap8g-mains-breaker/subchap8g-mains-breaker.component';
import { Subchap8hBranchingBreakerComponent } from './course/hawkeyes-ios/hawkeyes-ios-course-view/workflow of hawkeyes-ios-chapter8/subchap8h-branching-breaker/subchap8h-branching-breaker.component';
import { Subchap8iEvDedicatedProtectionComponent } from './course/hawkeyes-ios/hawkeyes-ios-course-view/workflow of hawkeyes-ios-chapter8/subchap8i-ev-dedicated-protection/subchap8i-ev-dedicated-protection.component';
import { Subchap8jEvDedicatedMcbComponent } from './course/hawkeyes-ios/hawkeyes-ios-course-view/workflow of hawkeyes-ios-chapter8/subchap8j-ev-dedicated-mcb/subchap8j-ev-dedicated-mcb.component';
import { Subchap8kEvDedicatedSubdbComponent } from './course/hawkeyes-ios/hawkeyes-ios-course-view/workflow of hawkeyes-ios-chapter8/subchap8k-ev-dedicated-subdb/subchap8k-ev-dedicated-subdb.component';
import { Subchap8lEarthingComponent } from './course/hawkeyes-ios/hawkeyes-ios-course-view/workflow of hawkeyes-ios-chapter8/subchap8l-earthing/subchap8l-earthing.component';
import { Subchap8mWiringDetailsComponent } from './course/hawkeyes-ios/hawkeyes-ios-course-view/workflow of hawkeyes-ios-chapter8/subchap8m-wiring-details/subchap8m-wiring-details.component';
import { Subchap8nRecommWiringDetailComponent } from './course/hawkeyes-ios/hawkeyes-ios-course-view/workflow of hawkeyes-ios-chapter8/subchap8n-recomm-wiring-detail/subchap8n-recomm-wiring-detail.component';
import { Subchap8oWiringDetailsImagesComponent } from './course/hawkeyes-ios/hawkeyes-ios-course-view/workflow of hawkeyes-ios-chapter8/subchap8o-wiring-details-images/subchap8o-wiring-details-images.component';
import { Subchap8pWallboxsocketComponent } from './course/hawkeyes-ios/hawkeyes-ios-course-view/workflow of hawkeyes-ios-chapter8/subchap8p-wallboxsocket/subchap8p-wallboxsocket.component';
import { Subchap8qSpdComponent } from './course/hawkeyes-ios/hawkeyes-ios-course-view/workflow of hawkeyes-ios-chapter8/subchap8q-spd/subchap8q-spd.component';
import { Subchap8rAdditionalProtectionComponent } from './course/hawkeyes-ios/hawkeyes-ios-course-view/workflow of hawkeyes-ios-chapter8/subchap8r-additional-protection/subchap8r-additional-protection.component';
import { Subchap8sPostCheckInstallComponent } from './course/hawkeyes-ios/hawkeyes-ios-course-view/workflow of hawkeyes-ios-chapter8/subchap8s-post-check-install/subchap8s-post-check-install.component';
import { Subchap8tPostCheckImagesComponent } from './course/hawkeyes-ios/hawkeyes-ios-course-view/workflow of hawkeyes-ios-chapter8/subchap8t-post-check-images/subchap8t-post-check-images.component';
import { Subchap8uEmailComponent } from './course/hawkeyes-ios/hawkeyes-ios-course-view/workflow of hawkeyes-ios-chapter8/subchap8u-email/subchap8u-email.component';
import { Subchap8vEmail1Component } from './course/hawkeyes-ios/hawkeyes-ios-course-view/workflow of hawkeyes-ios-chapter8/subchap8v-email1/subchap8v-email1.component';
import { Subchap8wHelpComponent } from './course/hawkeyes-ios/hawkeyes-ios-course-view/workflow of hawkeyes-ios-chapter8/subchap8w-help/subchap8w-help.component';
import { Subchap8xReportSampleComponent } from './course/hawkeyes-ios/hawkeyes-ios-course-view/workflow of hawkeyes-ios-chapter8/subchap8x-report-sample/subchap8x-report-sample.component';
import { Subchap7aIndexComponent } from './course/hawkeyes-ios/hawkeyes-ios-course-view/ios-guide-chapter7/subchap7a-index/subchap7a-index.component';
import { Subchap7aApplicationOverflowComponent } from './course/hawkeyes-ios/hawkeyes-ios-course-view/ios-guide-chapter7/subchap7a-application-overflow/subchap7a-application-overflow.component';
import { Subchap7cAppViaEmailComponent } from './course/hawkeyes-ios/hawkeyes-ios-course-view/ios-guide-chapter7/subchap7c-app-via-email/subchap7c-app-via-email.component';
import { Subchap7dViewInTestflightComponent } from './course/hawkeyes-ios/hawkeyes-ios-course-view/ios-guide-chapter7/subchap7d-view-in-testflight/subchap7d-view-in-testflight.component';
import { Subchap7eDownloadingTestflightComponent } from './course/hawkeyes-ios/hawkeyes-ios-course-view/ios-guide-chapter7/subchap7e-downloading-testflight/subchap7e-downloading-testflight.component';
import { Subchap7fInstallingAppComponent } from './course/hawkeyes-ios/hawkeyes-ios-course-view/ios-guide-chapter7/subchap7f-installing-app/subchap7f-installing-app.component';
import { Subchap7gAppiconHomepageComponent } from './course/hawkeyes-ios/hawkeyes-ios-course-view/ios-guide-chapter7/subchap7g-appicon-homepage/subchap7g-appicon-homepage.component';


@NgModule({
  declarations: [
    AppComponent,
    HomecardsComponent,
    HeadersComponent,
    FooterComponent,
    NavbarComponent,
    LoginComponent,
    ForgotpasswordComponent,
    TrainingComponent,
    PreindexComponent,
    TestcontentComponent,
    StartpretestComponent,
    MainpretestComponent,
    // IntroductionComponent,
    TestResultComponent,
    // HeaderComponent,
    MainIndexComponent,
    // IndexComponent,
    MainCourseViewComponent,
    ComplianceRequirementComponent,
    NecessityOfRequirementComponent,
    NonComplianceResultComponent,
    IndexChap1Component,
    Topic1LEAFElectricVehicleComponent,
    Topic2EVChargingMethodsComponent,
    Topic3ChargingPortComponent,
    Topic4Mode2AndMode3Component,
    Topic5CaseBAndCaseCComponent,
    Topic6ModesAndCaseForEVComponent,
    Topic7VehicleInletTypeComponent,
    Topic8ChargingTimeComponent,
    Topic9ChargingPowerComponent,
    Topic10MainWiringDiagComponent,
    Topic1Chap1ToChap8Component,
    Topic2Chap9ToChap15Component,
    IndexIntroComponent,
    IndexRequirementComponent,
    IndexChap3Component,
    ComplianceRequirementLess3Component,
    NecessityOfRequirementLess3Component,
    NonComplianceResultLess3Component,
    ComplianceRequirementLess4Component,
    IndexChap4Component,
    NecessityOfRequirementLess4Component,
    NonComplianceResultLess4Component,
    ComplianceRequirementLess5Component,
    IndexChap5Component,
    NecessityOfRequirementLess5Component,
    NonComplianceResultLess5Component,
    ComplianceRequirementLess6Component,
    IndexChap6Component,
    NecessityOfRequirementLess6Component,
    NonComplianceResultLess6Component,
    ComplianceRequirementLess7Component,
    IndexChap7Component,
    NonComplianceResultLess7Component,
    NessecityOfRequirementLess7Component,
    ComplianceRequirementLess8Component,
    IndexChap8Component,
    NecessityOfRequirementLess8Component,
    NonComplianceResultLess8Component,
    ComplianceRequirementLess9Component,
    IndexChap9Component,
    NecessityOfRequirementLess9Component,
    NonComplianceResultLess9Component,
    IndexChap10Component,
    ComplianceRequirementLess10Component,
    NecessityOfRequirementLess10Component,
    NonComplianceResultLess10Component,
    ComplianceRequirementLess11Component,
    IndexChap11Component,
    NecessityOfRequirementLess11Component,
    NonComplianceResultLess11Component,
    ComplianceRequirementLess12Component,
    IndexChap12Component,
    NecessityOfRequirementLess12Component,
    NonComplianceResultLess12Component,
    ComplianceRequirementLess13Component,
    IndexChap13Component,
    NecessityOfRequirementLess13Component,
    NonComplianceResultLess13Component,
    ComplienceRequirementLess14Component,
    IndexChap14Component,
    NecessityOfRequirementLess14Component,
    NonComplianceResultLess14Component,
    ComplianceRequirementLess15Component,
    IndexChap15Component,
    NecessityOfRequirementLess15Component,
    NonComplianceResultLess15Component,
    Quizchap1Component,
    Quizchap3Component,
    Quizchap4Component,
    Quizchap5Component,
    Quizchap6Component,
    Quizchap7Component,
    Quizchap8Component,
    Quizchap9Component,
    Quizchap10Component,
    Quizchap11Component,
    Quizchap12Component,
    Quizchap13Component,
    Quizchap14Component,
    Quizchap15Component,
    CaseMainCourseViewComponent,
    CaseStudyChap1Component,
    CaseStudyChap2Component,
    CaseStudyChap3Component,
    CaseStudyChap4Component,
    CaseStudyChap5Component,
    CaseStudyChap6Component,
    CaseStudyMainIndexComponent,



    MainDoDontsCourseViewComponent,
    MainDoDontsIndexComponent,
    AnnexureChap1Component,
    AnnexureChap3Component,
    AnnexureChap4Component,
    AnnexureChap5Component,
    AnnexureChap6Component,
    AnnexureChap7Component,
    AnnexureChap8Component,
    AnnexureChap9Component,
    AnnexureChap10Component,
    AnnexureChap13aComponent,
    AnnexureChap13bComponent,
    AnnexureChap14Component,
    DoDontsIndexChap1Component,
    NecessityOfPointLess1Component,
    DosDontsNonComplianceLess1Component,
    DoDontsIndexChap2Component,
    NecessityOfPointLess2Component,
    DosDontsNonComplianceLess2Component,
    DoDontsIndexChap3Component,
    NecessityOfPointLess3Component,
    DosDontsNonComplianceLess3Component,
    DoDontsIndexChap4Component,
    NecessityOfPointLess4Component,
    DosDontsNonComplianceLess4Component,
    DoDontsIndexChap5Component,
    NecessityOfPointLess5Component,
    DosDontsNonComplianceLess5Component,
    DoDontsIndexChap6Component,
    NecessityOfPointLess6Component,
    DosDontsNonComplianceLess6Component,
    DoDontsIndexChap7Component,
    NecessityOfPointLess7Component,
    DosDontsNonComplianceLess7Component,
    DoDontsIndexChap8Component,
    NecessityOfPointLess8Component,
    DosDontsNonComplianceLess8Component,
    DoDontsIndexChap9Component,
    NecessityOfPointLess9Component,
    DosDontsNonComplianceLess9Component,
    DoDontsIndexChap10Component,
    NecessityOfPointLess10Component,
    DosDontsNonComplianceLess10Component,
    DoDontsIndexChap11Component,
    NecessityOfPointLess11Component,
    DosDontsNonComplianceLess11Component,
    // StatusAlertComponent,
    MainPostTestComponent,
    InstructionPostTestComponent,
    ResultPostTestComponent,
    TestContentPostTestComponent,

    //hawkeyesp android
    HawkEyesPMainIndexComponent,
    MainHawkEyesPCourseViewComponent,   
    HawkEyesPChap1Component, HawkEyesPChap2Component, HawkEyesPChap3Component, HawkEyesPChap4Component, HawkEyesPChap5Component, HawkEyesPChap6Component, Chap7aOverflowComponent, Chap7bInstallStepsComponent, Chap7cAppReceiveComponent, HawkEyesPChap7IndexComponent, Chap7dPreSettingComponent, Chap7ePreSetting1Component, Chap7fPreSetting2Component, Chap7gHawkEyespInstallComponent, Chap7hPreSettingVer8Component, Chap7iPreSetting1Ver8Component, Chap7jHawkEyespInstallVer8Component, Chap7kPostSettingComponent, Chap7LPostSetting1Component, Chap7mPostSetting2Component, Chap7nAppIconHomeComponent, HawkEyespChap8IndexComponent, Chap8aMainScreenComponent, Chap8bNewInstallMenuComponent, Chap8cReviewInstallMenuComponent, Chap8dLangSelectionComponent, Chap8eLocationComponent, Chap8fMainsBreakerComponent, Chap8gBranchBreakerComponent, Chap8hEvDedicatedComponent, Chap8iEvDedicatedMcbComponent, Chap8jEvDedicatedSubDBComponent, Chap8kEarthingComponent, Chap8lWiringDetailComponent, Chap8mRecommWiringDetailComponent, Chap8nImagesWiringDetailComponent, Chap8oWallboxSocketComponent, Chap8pSpdComponent, Chap8qAddProtectionComponent, Chap8rPostcheckInstallComponent, Chap8sPostcheckImagesComponent, Chap8bNewInstallThreephaseComponent, Chap8tEmailComponent, Chap8uEmail1Component, Chap8vEmail2Component, Chap8wHelpComponent, Chap8xReportGenerationComponent,
    
    //hawkeyesp ios
    HawkeyesIosMainIndexComponent,
    HawkeyesIosMainCourseViewComponent, 
    HawkeyesiosObjectiveComponent, 
    HawkeyesiosDatainputsComponent, 
    HwkeyeiosInstallationReportComponent, 
    HawkeyesIosIntroComponent, 
    HawkeyesiosFeaturesComponent, 
    HawkeyesiosToolarchComponent, 
    HawkeyesiosApplicationinstallationIndexComponent, 
    Chapt8IndexComponent, 
    Subchap8aMainScreenComponent, 
    Subchap8bNewInstallComponent, Subchap8cNewInstallPhase3Component, Subchap8dReviewInstallationComponent, Subchap8eLanguageSelectionComponent, Subchap8fLocationComponent, Subchap8gMainsBreakerComponent, Subchap8hBranchingBreakerComponent, Subchap8iEvDedicatedProtectionComponent, Subchap8jEvDedicatedMcbComponent, Subchap8kEvDedicatedSubdbComponent, Subchap8lEarthingComponent, Subchap8mWiringDetailsComponent, Subchap8nRecommWiringDetailComponent, Subchap8oWiringDetailsImagesComponent, Subchap8pWallboxsocketComponent, Subchap8qSpdComponent, Subchap8rAdditionalProtectionComponent, Subchap8sPostCheckInstallComponent, Subchap8tPostCheckImagesComponent, Subchap8uEmailComponent, Subchap8vEmail1Component, Subchap8wHelpComponent, Subchap8xReportSampleComponent, Subchap7aIndexComponent, Subchap7aApplicationOverflowComponent,  Subchap7cAppViaEmailComponent, Subchap7dViewInTestflightComponent, Subchap7eDownloadingTestflightComponent, Subchap7fInstallingAppComponent, Subchap7gAppiconHomepageComponent, 

  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    AdminModule,
    FormsModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatButtonModule,
    MatProgressBarModule,
    MatExpansionModule,
    MatIconModule,
    PortalModule,
    MatRadioModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatListModule,
    ​​​​​​​​MatToolbarModule,
    MatMenuModule,
    CountdownModule,
    MatMenuModule,
    FlexLayoutModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-full-width',
      preventDuplicates: true,
    }),
  ],
providers: [
ɵMatchMedia,
BreakPointRegistry,
PrintHook,
MediaObserver,
StyleUtils,
StylesheetMap,
LayoutStyleBuilder,
MediaMarshaller,
LayoutAlignStyleBuilder,
FlexStyleBuilder,
[
  { provide: CountdownGlobalConfig, useValue: undefined }
],
AuthGuard, AuthguardServiceService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
