import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subchap7f-installing-app',
  templateUrl: './subchap7f-installing-app.component.html',
  styleUrls: ['./subchap7f-installing-app.component.css']
})
export class Subchap7fInstallingAppComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
