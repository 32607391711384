import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthguardServiceService } from './authguard-service.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authguardservice: AuthguardServiceService, private router: Router){}
  canActivate() {
    if (localStorage.getItem('accessToken')) {
      var token = localStorage.getItem('accessToken');
      var payload = localStorage.getItem('payload');
      // console.log("gggggg",JSON.stringify(payload))

    //   var payload = jwt.verify(token, 'secretKey', function(err, decoded){
    //     if(!err){
    //         console.log(decoded, payload)
    //         req.id = decoded.id
    //         req.username = decoded.fname
    //         req.email = decoded.email
    //     }else{
    //         console.log(err.message)
    //         return res.redirect('http://localhost:4200');
    //     }
    // })
      return true;
    } else {
      this.router.navigate(['login']);
      return false;
    }
  }

}
