import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-result-post-test',
  templateUrl: './result-post-test.component.html',
  styleUrls: ['./result-post-test.component.css']
})
export class ResultPostTestComponent implements OnInit {

  
  constructor(private router: Router) { }

  ngOnInit() {
  }

  logout(){
    localStorage.clear()
    this.router.navigate(['/'])
  }

  exitClick(){
    window.close()
  }
}
