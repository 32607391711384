import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalConstants } from 'src/app/common/global-constants';

@Injectable({
  providedIn: 'root'
})
export class RandomQuestionsService {
  url = GlobalConstants.rootURL;
  constructor(private http: HttpClient) { }

  // setQuestionOccurence(easy, medium, hard) {
  //   var questionSet = {
  //     easy: easy,
  //     medium: medium,
  //     hard: hard
  //   }
  //   const headers = new HttpHeaders()
  //     .set('Content-Type', 'application/json');
  //   return this.http.post<boolean>(this.url + '/set-random-occurence', questionSet, {
  //     headers: headers
  //   })
  // }

  // getQuestionOccurence() {
  //   const headers = new HttpHeaders()
  //     .set('Content-Type', 'application/json');
  //   return this.http.get(this.url + '/get-random-occurence', {
  //     headers: headers
  //   })
  // }

  setCustomOccurenceStatus(testName: string, customStatus: number){
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return this.http.post<boolean>(this.url + '/api/set-custom-status?name='+testName+'&customStatus='+customStatus,{
      headers: headers
    })
  }

  setQuestionOccurence(testName: string, easy: number, medium: number, hard: number, maxTime: number) {
    var questionSet = {
      easy: easy,
      medium: medium,
      hard: hard,
      maxTime: maxTime
    }
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return this.http.post<boolean>(this.url + '/api/set-random-occurence?name='+testName, questionSet, {
      headers: headers
    })
  }

  // setMaximumTimeAllowed(testName: string, allowedTime: number){
  //   const headers = new HttpHeaders()
  //     .set('Content-Type', 'application/json');
  //   return this.http.post<boolean>(this.url + '/set-test-time?name='+testName+'&timeAllowed='+allowedTime, {
  //     headers: headers
  //   })
  // }

  getQuestionOccurence(testName:string) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return this.http.get(this.url + '/api/get-random-occurence?name='+testName, {
      headers: headers
    })
  }

  getMarketDetails(){
    
  const headers = new HttpHeaders()
  .set('Content-Type', 'application/json');
  return this.http.get(this.url + '/api/users/market-details',{
  headers: headers
  })

  }

}
