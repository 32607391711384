import { Component, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Router } from '@angular/router';
import { GetService } from 'src/app/services/get.service';
import { QuizcourseService } from 'src/app/services/quizcourse.service';

@Component({
  selector: 'app-quizchap15',
  templateUrl: './quizchap15.component.html',
  styleUrls: ['./quizchap15.component.css']
})
export class Quizchap15Component implements OnInit {
  lastCompleted: number; 
  responseArray = []
  question : any;
  options: any;
  data: any;
  answerStatus:boolean = false;
  selectedOption:string;
  cssChangeAction:string;
  count;
  answerCheck:boolean = false;
  correctResponseCount;
  buttonStatus:boolean = false;
  constructor( private quizcourseService: QuizcourseService,private router: Router,private statusService: GetService) {
    this.statusService.getCompletionStatus()
    .subscribe(data=>{
      this.lastCompleted = parseInt(data.toString());
    },err=>{
      alert("Couldnt get status"+err);
    })
   }

  ngOnInit():void {
    window.scrollTo(0, 0);
    // window.location.reload();
    this.data = this.quizcourseService.getquizzes(1);
    // console.log(this.question);
    this.question = this.data[0];
    this.options = this.data[1];
    // console.log(this.question);
    // console.log(this.options);

  }

  radioChange(event){
    this.buttonStatus = true;
    // console.log(event.value)
    this.selectedOption = event.value;
  }

  onAnswerCheck(){
  this.answerStatus=true;
  this.cssChangeAction=this.selectedOption;
  if(this.question.questionType= 'multiple'){
   var correctResponse=this.question.correctOption.split("|");
   this.correctResponseCount=correctResponse.length;
  //  console.log(this.correctResponseCount);
  
// usercount
    this.count=this.responseArray.length
    // console.log(this.count);

    if(this.correctResponseCount == this.count){
      this.question.userResponse = 'correct'

    }
  }
  
  }

  onSubmitQuiz(){
   
    // this.quizcourseService.saveChapterQuizResult(this.question, `chapter_15`)
    //   .subscribe(data=>{
    //     if (data){
    //       this.router.navigate(['/']);
    //     } else{
    //       alert("Cant update the score, Please try again!!!")
    //     }
    // })

    if (this.lastCompleted == 83) {
      this.statusService.updateCompletionStatus(83 , 84)
        .subscribe(data => { 
          if (data) {
            this.quizcourseService.saveChapterQuizResult(this.question, `chapter_15`)
              .subscribe(data => {
                if (data) {
                  this.router.navigate(['/']);
                } else {
                  alert("Can't update the score, Please try again!!!")
                }
              })
          }
        }, err => {
          alert("something went wrong, please try again!!")
        })
    } else {
  
    this.quizcourseService.saveChapterQuizResult(this.question, `chapter_15`)
      .subscribe(data => {
        if (data) {
          this.router.navigate(['/']);
        } else {
          alert("Can't update the score, Please try again!!!")
        }
      })
  
  }   
  

}

checkBoxChange(event: MatCheckboxChange) {
  var optionvalue = event.source.value[0]

  if (event.checked) {
    this.responseArray.push(optionvalue)
  } else {
    this.responseArray = this.responseArray.filter(item => item !== optionvalue);
  }
  // console.log(this.responseArray)

  if(this.responseArray.length == 0){
    this.buttonStatus = false;

  }else{
    this.buttonStatus = true;

  }
}

}
