import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GetService } from 'src/app/services/get.service';

@Component({
  selector: 'app-index-chap15',
  templateUrl: './index-chap15.component.html',
  styleUrls: ['./index-chap15.component.css']
})
export class IndexChap15Component implements OnInit {

  lastCompleted: number;
  constructor(private statusService: GetService,private toastr:ToastrService,private router:Router) {
    statusService.getCompletionStatus()
      .subscribe(data=>{
        this.lastCompleted = parseInt(data.toString());
      },err=>{
        alert("Error getting status!!")
      })
   }

   
   navigateNgp(componentId: number){
    if(componentId <= this.lastCompleted){
    this.router.navigate(['course/ngp', componentId]);
    } else if(componentId == this.lastCompleted+1){
    this.router.navigate(['course/ngp', componentId]);
    } else{
    this.toastr.warning('Please complete this topic first to proceed for next');
    this.router.navigate(['course/ngp', this.lastCompleted+1]);
    }
  }
  
  isCompleted(componentId: number){
    if(componentId <= this.lastCompleted){
      return true;
    }
    return false;
  }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

}
