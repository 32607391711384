import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chap8r-postcheck-install',
  templateUrl: './chap8r-postcheck-install.component.html',
  styleUrls: ['./chap8r-postcheck-install.component.css']
})
export class Chap8rPostcheckInstallComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
