import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminRoutingModule } from './admin-routing.module';
import { UsercreationComponent } from './usercreation/usercreation.component';
import { HomeComponent } from './home/home.component';
import { FormsModule } from '@angular/forms';
import { RandomquestionComponent } from './randomquestion/randomquestion.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { ContentComponent } from './content/content.component';
import { PretestAnalysisComponent } from './pretest-analysis/pretest-analysis.component';
import { PosttestAnalysisComponent } from './posttest-analysis/posttest-analysis.component';
import { RandomquestionpostComponent } from './randomquestionpost/randomquestionpost.component';

import { MatTableModule } from '@angular/material/table';
import {​​​​​ MatPaginatorModule }​​​​​ from '@angular/material/paginator';
import { TestHistoryComponent } from './test-history/test-history.component';
import { MatTabsModule } from '@angular/material/tabs';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule} from '@angular/material/toolbar';
import { MatDividerModule} from '@angular/material/divider';
import { MatListModule} from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ChapwiseQuizAnalysisComponent } from './chapwise-quiz-analysis/chapwise-quiz-analysis.component';
import { ChapterWiseQuizDetailsComponent } from './chapwise-quiz-analysis/chapter-wise-quiz-details/chapter-wise-quiz-details.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';




@NgModule({
  declarations: [
    UsercreationComponent,
    HomeComponent,
    RandomquestionComponent,
    SideNavComponent,
    ContentComponent,
    PretestAnalysisComponent,
    TestHistoryComponent,
    ChapwiseQuizAnalysisComponent,
    ChapterWiseQuizDetailsComponent,
    PosttestAnalysisComponent,
    RandomquestionpostComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatListModule,
    MatDividerModule,
    MatToolbarModule,
    MatExpansionModule,
    MatProgressBarModule,
    MatCardModule,
    MatButtonModule,
    AdminRoutingModule,
    FormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatSlideToggleModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatSelectModule
  ]
})
export class AdminModule { }
