import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hawkeyesios-features',
  templateUrl: './hawkeyesios-features.component.html',
  styleUrls: ['./hawkeyesios-features.component.css']
})
export class HawkeyesiosFeaturesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
