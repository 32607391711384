import { Component, OnInit } from '@angular/core';
import { RandomQuestionsService } from '../services/random-questions.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-randomquestionpost',
  templateUrl: './randomquestionpost.component.html',
  styleUrls: ['./randomquestionpost.component.css']
})
export class RandomquestionpostComponent implements OnInit {

  noOfQuestionsEasy: number = 0;
  noOfQuestionsMedium: number = 0;
  noOfQuestionsHard: number = 0;
  customQuestionSelection: boolean;
  maximumTimeMinutes: number;
  disableTextbox = false;

  constructor(private randomQuestionService: RandomQuestionsService) { }

  ngOnInit() {
    this.randomQuestionService.getQuestionOccurence('posttest').subscribe(data => {
      // console.log(data)
      this.noOfQuestionsEasy = data['easy'];
      this.noOfQuestionsMedium = data['medium'];
      this.noOfQuestionsHard = data['hard'];
      this.customQuestionSelection = data['custom'] == '1' ? true : false;
      this.maximumTimeMinutes = data['maxTime'];
      this.disableTextbox = !this.disableTextbox;
    }, error => {
      // console.log('didnt get values')
    })
  }

  toggleChange(event: MatSlideToggleChange){
    this.customQuestionSelection = event.checked;
    let customStatus = event.checked == true ? 1 : 0;
    this.randomQuestionService.setCustomOccurenceStatus('posttest' ,customStatus)
      .subscribe(data => {
        // console.log('value set')
        this.disableTextbox = true;
      }, error => {
        // console.log('value not set')
      })
    // console.log(event.checked)
  }

  saveData() {
    this.randomQuestionService.setQuestionOccurence('posttest' ,this.noOfQuestionsEasy, this.noOfQuestionsMedium, this.noOfQuestionsHard, this.maximumTimeMinutes)
      .subscribe(data => {
        // console.log('value set')
        this.disableTextbox = true;
      }, error => {
        // console.log('value not set')
      })
  }

  editData() {
    this.disableTextbox = false;
  }

}
