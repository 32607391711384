import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomecardsComponent } from "./homecards/homecards.component";
import { LoginComponent } from "./login/login.component";
import { PreindexComponent } from "./pretest/preindex/preindex.component";
import { TestcontentComponent } from "./pretest/testcontent/testcontent.component";
import { StartpretestComponent } from "./pretest/startpretest/startpretest.component";
import { ForgotpasswordComponent } from "./forgotpassword/forgotpassword.component";
import { AuthGuard } from "./authgaurd/auth.guard";
import { TestResultComponent } from "./pretest/test-result/test-result.component";
// import { HeaderComponent } from "./course/header/header.component";
import { MainIndexComponent } from "./course/main-index/main-index.component";
import { MainCourseViewComponent } from "./course/main-course-view/main-course-view/main-course-view.component";
import { IndexIntroComponent } from "./course/main-course-view/NGP-Introduction/index-intro/index-intro.component";
import { IndexRequirementComponent } from "./course/main-course-view/NGP-Requirement/index-requirement/index-requirement.component";
import { Topic1LEAFElectricVehicleComponent } from "./course/main-course-view/NGP-Introduction/topic1-leaf-electric-vehicle/topic1-leaf-electric-vehicle.component";
import { Topic2EVChargingMethodsComponent } from "./course/main-course-view/NGP-Introduction/topic2-ev-charging-methods/topic2-ev-charging-methods.component";
import { Topic3ChargingPortComponent } from "./course/main-course-view/NGP-Introduction/topic3-charging-port/topic3-charging-port.component";
import { Topic4Mode2AndMode3Component } from "./course/main-course-view/NGP-Introduction/topic4-mode2-and-mode3/topic4-mode2-and-mode3.component";
import { Topic5CaseBAndCaseCComponent } from "./course/main-course-view/NGP-Introduction/topic5-case-b-and-case-c/topic5-case-b-and-case-c.component";
import { Topic1Chap1ToChap8Component } from "./course/main-course-view/NGP-Requirement/topic1-chap1-to-chap8/topic1-chap1-to-chap8.component";
import { Topic2Chap9ToChap15Component } from "./course/main-course-view/NGP-Requirement/topic2-chap9-to-chap15/topic2-chap9-to-chap15.component";
import { Topic6ModesAndCaseForEVComponent } from './course/main-course-view/NGP-Introduction/topic6-modes-and-case-for-ev/topic6-modes-and-case-for-ev.component';
import { Topic7VehicleInletTypeComponent } from './course/main-course-view/NGP-Introduction/topic7-vehicle-inlet-type/topic7-vehicle-inlet-type.component';
import { Topic8ChargingTimeComponent } from './course/main-course-view/NGP-Introduction/topic8-charging-time/topic8-charging-time.component';
import { Topic9ChargingPowerComponent } from './course/main-course-view/NGP-Introduction/topic9-charging-power/topic9-charging-power.component';
import { Topic10MainWiringDiagComponent } from './course/main-course-view/NGP-Introduction/topic10-main-wiring-diag/topic10-main-wiring-diag.component';
import { IndexChap1Component } from "./course/main-course-view/NGP-Chap1,2/index-chap1/index-chap1.component";
import { ComplianceRequirementComponent } from "./course/main-course-view/NGP-Chap1,2/compliance-requirement/compliance-requirement.component";
import { NecessityOfRequirementComponent } from "./course/main-course-view/NGP-Chap1,2/necessity-of-requirement/necessity-of-requirement.component";
import { IndexChap3Component } from "./course/main-course-view/NGP-Chap3/index-chap3/index-chap3.component";
import { ComplianceRequirementLess3Component } from "./course/main-course-view/NGP-Chap3/compliance-requirement-less3/compliance-requirement-less3.component";
import { NecessityOfRequirementLess3Component } from "./course/main-course-view/NGP-Chap3/necessity-of-requirement-less3/necessity-of-requirement-less3.component";
import { NonComplianceResultLess3Component } from "./course/main-course-view/NGP-Chap3/non-compliance-result-less3/non-compliance-result-less3.component";
import { NonComplianceResultComponent } from "./course/main-course-view/NGP-Chap1,2/non-compliance-result/non-compliance-result.component";
import { Quizchap1Component } from "./course/main-course-view/NGP-Chap1,2/quizchap1/quizchap1.component";
// import{NonComplianceRequirementComponent} from './course/chapter-1-2/non-compliance-res/non-compliance-requirement.component';
import { ComplianceRequirementLess4Component } from "./course/main-course-view/NGP-Chap4/compliance-requirement-less4/compliance-requirement-less4.component";
import { NecessityOfRequirementLess4Component } from "./course/main-course-view/NGP-Chap4/necessity-of-requirement-less4/necessity-of-requirement-less4.component";
import { NonComplianceResultLess4Component } from "./course/main-course-view/NGP-Chap4/non-compliance-result-less4/non-compliance-result-less4.component";
import { IndexChap4Component } from "./course/main-course-view/NGP-Chap4/index-chap4/index-chap4.component";
import { IndexChap5Component } from "./course/main-course-view/NGP-Chap5/index-chap5/index-chap5.component";
import { ComplianceRequirementLess5Component } from "./course/main-course-view/NGP-Chap5/compliance-requirement-less5/compliance-requirement-less5.component";
import { NecessityOfRequirementLess5Component } from "./course/main-course-view/NGP-Chap5/necessity-of-requirement-less5/necessity-of-requirement-less5.component";
import { NonComplianceResultLess5Component } from "./course/main-course-view/NGP-Chap5/non-compliance-result-less5/non-compliance-result-less5.component";
import { IndexChap6Component } from "./course/main-course-view/NGP-Chap6/index-chap6/index-chap6.component";
import { ComplianceRequirementLess6Component } from "./course/main-course-view/NGP-Chap6/compliance-requirement-less6/compliance-requirement-less6.component";
import { NecessityOfRequirementLess6Component } from "./course/main-course-view/NGP-Chap6/necessity-of-requirement-less6/necessity-of-requirement-less6.component";
import { NonComplianceResultLess6Component } from "./course/main-course-view/NGP-Chap6/non-compliance-result-less6/non-compliance-result-less6.component";
import { IndexChap7Component } from "./course/main-course-view/NGP-Chap7/index-chap7/index-chap7.component";
import { ComplianceRequirementLess7Component } from "./course/main-course-view/NGP-Chap7/compliance-requirement-less7/compliance-requirement-less7.component";
import { NessecityOfRequirementLess7Component } from "./course/main-course-view/NGP-Chap7/nessecity-of-requirement-less7/nessecity-of-requirement-less7.component";
import { NonComplianceResultLess7Component } from "./course/main-course-view/NGP-Chap7/non-compliance-result-less7/non-compliance-result-less7.component";
import { IndexChap8Component } from "./course/main-course-view/NGP-Chap8/index-chap8/index-chap8.component";
import { ComplianceRequirementLess8Component } from "./course/main-course-view/NGP-Chap8/compliance-requirement-less8/compliance-requirement-less8.component";
import { NecessityOfRequirementLess8Component } from "./course/main-course-view/NGP-Chap8/necessity-of-requirement-less8/necessity-of-requirement-less8.component";
import { NonComplianceResultLess8Component } from "./course/main-course-view/NGP-Chap8/non-compliance-result-less8/non-compliance-result-less8.component";
import { IndexChap9Component } from "./course/main-course-view/NGP-Chap9/index-chap9/index-chap9.component";
import { ComplianceRequirementLess9Component } from "./course/main-course-view/NGP-Chap9/compliance-requirement-less9/compliance-requirement-less9.component";
import { NecessityOfRequirementLess9Component } from "./course/main-course-view/NGP-Chap9/necessity-of-requirement-less9/necessity-of-requirement-less9.component";
import { NonComplianceResultLess9Component } from "./course/main-course-view/NGP-Chap9/non-compliance-result-less9/non-compliance-result-less9.component";
import { IndexChap10Component } from "./course/main-course-view/NGP-Chap10/index-chap10/index-chap10.component";
import { ComplianceRequirementLess10Component } from "./course/main-course-view/NGP-Chap10/compliance-requirement-less10/compliance-requirement-less10.component";
import { NecessityOfRequirementLess10Component } from "./course/main-course-view/NGP-Chap10/necessity-of-requirement-less10/necessity-of-requirement-less10.component";
import { NonComplianceResultLess10Component } from "./course/main-course-view/NGP-Chap10/non-compliance-result-less10/non-compliance-result-less10.component";
import { IndexChap11Component } from "./course/main-course-view/NGP-Chap11/index-chap11/index-chap11.component";
import { ComplianceRequirementLess11Component } from "./course/main-course-view/NGP-Chap11/compliance-requirement-less11/compliance-requirement-less11.component";
import { NecessityOfRequirementLess11Component } from "./course/main-course-view/NGP-Chap11/necessity-of-requirement-less11/necessity-of-requirement-less11.component";
import { NonComplianceResultLess11Component } from "./course/main-course-view/NGP-Chap11/non-compliance-result-less11/non-compliance-result-less11.component";
import { IndexChap12Component } from "./course/main-course-view/NGP-Chap12/index-chap12/index-chap12.component";
import { ComplianceRequirementLess12Component } from "./course/main-course-view/NGP-Chap12/compliance-requirement-less12/compliance-requirement-less12.component";
import { NecessityOfRequirementLess12Component } from "./course/main-course-view/NGP-Chap12/necessity-of-requirement-less12/necessity-of-requirement-less12.component";
import { NonComplianceResultLess12Component } from "./course/main-course-view/NGP-Chap12/non-compliance-result-less12/non-compliance-result-less12.component";
import { IndexChap13Component } from "./course/main-course-view/NGP-Chap13/index-chap13/index-chap13.component";
import { ComplianceRequirementLess13Component } from "./course/main-course-view/NGP-Chap13/compliance-requirement-less13/compliance-requirement-less13.component";
import { NecessityOfRequirementLess13Component } from "./course/main-course-view/NGP-Chap13/necessity-of-requirement-less13/necessity-of-requirement-less13.component";
import { NonComplianceResultLess13Component } from "./course/main-course-view/NGP-Chap13/non-compliance-result-less13/non-compliance-result-less13.component";
import { IndexChap14Component } from "./course/main-course-view/NGP-Chap14/index-chap14/index-chap14.component";
import { ComplienceRequirementLess14Component } from "./course/main-course-view/NGP-Chap14/complience-requirement-less14/complience-requirement-less14.component";
import { NecessityOfRequirementLess14Component } from "./course/main-course-view/NGP-Chap14/necessity-of-requirement-less14/necessity-of-requirement-less14.component";
import { NonComplianceResultLess14Component } from "./course/main-course-view/NGP-Chap14/non-compliance-result-less14/non-compliance-result-less14.component";
import { IndexChap15Component } from "./course/main-course-view/NGP-Chap15/index-chap15/index-chap15.component";
import { ComplianceRequirementLess15Component } from "./course/main-course-view/NGP-Chap15/compliance-requirement-less15/compliance-requirement-less15.component";
import { NecessityOfRequirementLess15Component } from "./course/main-course-view/NGP-Chap15/necessity-of-requirement-less15/necessity-of-requirement-less15.component";
import { NonComplianceResultLess15Component } from "./course/main-course-view/NGP-Chap15/non-compliance-result-less15/non-compliance-result-less15.component";
import { Quizchap3Component } from "./course/main-course-view/NGP-Chap3/quizchap3/quizchap3.component";
import { Quizchap15Component } from "./course/main-course-view/NGP-Chap15/quizchap15/quizchap15.component";
import { Quizchap4Component } from "./course/main-course-view/NGP-Chap4/quizchap4/quizchap4.component";
import { Quizchap5Component } from "./course/main-course-view/NGP-Chap5/quizchap5/quizchap5.component";
import { Quizchap6Component } from "./course/main-course-view/NGP-Chap6/quizchap6/quizchap6.component";
import { Quizchap7Component } from "./course/main-course-view/NGP-Chap7/quizchap7/quizchap7.component";
import { Quizchap8Component } from "./course/main-course-view/NGP-Chap8/quizchap8/quizchap8.component";
import { Quizchap9Component } from "./course/main-course-view/NGP-Chap9/quizchap9/quizchap9.component";
import { Quizchap10Component } from "./course/main-course-view/NGP-Chap10/quizchap10/quizchap10.component";
import { Quizchap11Component } from "./course/main-course-view/NGP-Chap11/quizchap11/quizchap11.component";
import { Quizchap12Component } from "./course/main-course-view/NGP-Chap12/quizchap12/quizchap12.component";
import { Quizchap13Component } from "./course/main-course-view/NGP-Chap13/quizchap13/quizchap13.component";
import { Quizchap14Component } from "./course/main-course-view/NGP-Chap14/quizchap14/quizchap14.component";
import { CaseStudyMainIndexComponent } from "./course/case-study/case-study-main-index/case-study-main-index.component";
import { CaseMainCourseViewComponent } from "./course/case-study/caseStudy-main-course-view/case-main-course-view/case-main-course-view.component";
import { CaseStudyChap1Component } from "./course/case-study/caseStudy-main-course-view/case-study-chap1/case-study-chap1.component";
import { CaseStudyChap2Component } from "./course/case-study/caseStudy-main-course-view/case-study-chap2/case-study-chap2.component";
import { CaseStudyChap3Component } from "./course/case-study/caseStudy-main-course-view/case-study-chap3/case-study-chap3.component";
import { CaseStudyChap4Component } from "./course/case-study/caseStudy-main-course-view/case-study-chap4/case-study-chap4.component";
import { CaseStudyChap5Component } from "./course/case-study/caseStudy-main-course-view/case-study-chap5/case-study-chap5.component";
import { CaseStudyChap6Component } from "./course/case-study/caseStudy-main-course-view/case-study-chap6/case-study-chap6.component";
import { MainDoDontsIndexComponent } from "./course/do-donts/main-do-donts-index/main-do-donts-index.component";
import { MainDoDontsCourseViewComponent } from "./course/do-donts/main-do-donts-course-view/main-do-donts-course-view/main-do-donts-course-view.component";
import { DoDontsIndexChap1Component } from "./course/do-donts/main-do-donts-course-view/Do-Donts-Chap1/do-donts-index-chap1/do-donts-index-chap1.component";
import { NecessityOfPointLess1Component } from "./course/do-donts/main-do-donts-course-view/Do-Donts-Chap1/necessity-of-point-less1/necessity-of-point-less1.component";
import { DosDontsNonComplianceLess1Component } from "./course/do-donts/main-do-donts-course-view/Do-Donts-Chap1/dos-donts-non-compliance-less1/dos-donts-non-compliance-less1.component";
import { DoDontsIndexChap2Component } from "./course/do-donts/main-do-donts-course-view/Do-Donts-Chap2/do-donts-index-chap2/do-donts-index-chap2.component";
import { NecessityOfPointLess2Component } from "./course/do-donts/main-do-donts-course-view/Do-Donts-Chap2/necessity-of-point-less2/necessity-of-point-less2.component";
import { DosDontsNonComplianceLess2Component } from "./course/do-donts/main-do-donts-course-view/Do-Donts-Chap2/dos-donts-non-compliance-less2/dos-donts-non-compliance-less2.component";
import { DoDontsIndexChap3Component } from "./course/do-donts/main-do-donts-course-view/Do-Donts-Chap3/do-donts-index-chap3/do-donts-index-chap3.component";
import { NecessityOfPointLess3Component } from "./course/do-donts/main-do-donts-course-view/Do-Donts-Chap3/necessity-of-point-less3/necessity-of-point-less3.component";
import { DosDontsNonComplianceLess3Component } from "./course/do-donts/main-do-donts-course-view/Do-Donts-Chap3/dos-donts-non-compliance-less3/dos-donts-non-compliance-less3.component";
import { DoDontsIndexChap4Component } from "./course/do-donts/main-do-donts-course-view/Do-Donts-Chap4/do-donts-index-chap4/do-donts-index-chap4.component";
import { NecessityOfPointLess4Component } from "./course/do-donts/main-do-donts-course-view/Do-Donts-Chap4/necessity-of-point-less4/necessity-of-point-less4.component";
import { DosDontsNonComplianceLess4Component } from "./course/do-donts/main-do-donts-course-view/Do-Donts-Chap4/dos-donts-non-compliance-less4/dos-donts-non-compliance-less4.component";
import { DoDontsIndexChap5Component } from "./course/do-donts/main-do-donts-course-view/Do-Donts-Chap5/do-donts-index-chap5/do-donts-index-chap5.component";
import { NecessityOfPointLess5Component } from "./course/do-donts/main-do-donts-course-view/Do-Donts-Chap5/necessity-of-point-less5/necessity-of-point-less5.component";
import { DosDontsNonComplianceLess5Component } from "./course/do-donts/main-do-donts-course-view/Do-Donts-Chap5/dos-donts-non-compliance-less5/dos-donts-non-compliance-less5.component";
import { DoDontsIndexChap6Component } from "./course/do-donts/main-do-donts-course-view/Do-Donts-Chap6/do-donts-index-chap6/do-donts-index-chap6.component";
import { NecessityOfPointLess6Component } from "./course/do-donts/main-do-donts-course-view/Do-Donts-Chap6/necessity-of-point-less6/necessity-of-point-less6.component";
import { DosDontsNonComplianceLess6Component } from "./course/do-donts/main-do-donts-course-view/Do-Donts-Chap6/dos-donts-non-compliance-less6/dos-donts-non-compliance-less6.component";
import { DoDontsIndexChap7Component } from "./course/do-donts/main-do-donts-course-view/Do-Donts-Chap7/do-donts-index-chap7/do-donts-index-chap7.component";
import { NecessityOfPointLess7Component } from "./course/do-donts/main-do-donts-course-view/Do-Donts-Chap7/necessity-of-point-less7/necessity-of-point-less7.component";
import { DosDontsNonComplianceLess7Component } from "./course/do-donts/main-do-donts-course-view/Do-Donts-Chap7/dos-donts-non-compliance-less7/dos-donts-non-compliance-less7.component";
import { DoDontsIndexChap8Component } from "./course/do-donts/main-do-donts-course-view/Do-Donts-Chap8/do-donts-index-chap8/do-donts-index-chap8.component";
import { NecessityOfPointLess8Component } from "./course/do-donts/main-do-donts-course-view/Do-Donts-Chap8/necessity-of-point-less8/necessity-of-point-less8.component";
import { DosDontsNonComplianceLess8Component } from "./course/do-donts/main-do-donts-course-view/Do-Donts-Chap8/dos-donts-non-compliance-less8/dos-donts-non-compliance-less8.component";
import { DoDontsIndexChap9Component } from "./course/do-donts/main-do-donts-course-view/Do-Donts-Chap9/do-donts-index-chap9/do-donts-index-chap9.component";
import { NecessityOfPointLess9Component } from "./course/do-donts/main-do-donts-course-view/Do-Donts-Chap9/necessity-of-point-less9/necessity-of-point-less9.component";
import { DosDontsNonComplianceLess9Component } from "./course/do-donts/main-do-donts-course-view/Do-Donts-Chap9/dos-donts-non-compliance-less9/dos-donts-non-compliance-less9.component";
import { DoDontsIndexChap10Component } from "./course/do-donts/main-do-donts-course-view/Do-Donts-Chap10/do-donts-index-chap10/do-donts-index-chap10.component";
import { NecessityOfPointLess10Component } from "./course/do-donts/main-do-donts-course-view/Do-Donts-Chap10/necessity-of-point-less10/necessity-of-point-less10.component";
import { DosDontsNonComplianceLess10Component } from "./course/do-donts/main-do-donts-course-view/Do-Donts-Chap10/dos-donts-non-compliance-less10/dos-donts-non-compliance-less10.component";
import { DoDontsIndexChap11Component } from "./course/do-donts/main-do-donts-course-view/Do-Donts-Chap11/do-donts-index-chap11/do-donts-index-chap11.component";
import { NecessityOfPointLess11Component } from "./course/do-donts/main-do-donts-course-view/Do-Donts-Chap11/necessity-of-point-less11/necessity-of-point-less11.component";
import { DosDontsNonComplianceLess11Component } from "./course/do-donts/main-do-donts-course-view/Do-Donts-Chap11/dos-donts-non-compliance-less11/dos-donts-non-compliance-less11.component";


import { AnnexureChap1Component } from './course/main-course-view/NGP-Chap1,2/annexure-chap1/annexure-chap1.component';
import { AnnexureChap3Component } from './course/main-course-view/NGP-Chap3/annexure-chap3/annexure-chap3.component';
import { AnnexureChap4Component } from './course/main-course-view/NGP-Chap4/annexure-chap4/annexure-chap4.component';
import { AnnexureChap5Component } from './course/main-course-view/NGP-Chap5/annexure-chap5/annexure-chap5.component';
import { AnnexureChap6Component } from './course/main-course-view/NGP-Chap6/annexure-chap6/annexure-chap6.component';
import { AnnexureChap7Component } from './course/main-course-view/NGP-Chap7/annexure-chap7/annexure-chap7.component';
import { AnnexureChap8Component } from './course/main-course-view/NGP-Chap8/annexure-chap8/annexure-chap8.component';
import { AnnexureChap9Component } from './course/main-course-view/NGP-Chap9/annexure-chap9/annexure-chap9.component';
import { AnnexureChap10Component } from './course/main-course-view/NGP-Chap10/annexure-chap10/annexure-chap10.component';
import { AnnexureChap13bComponent } from './course/main-course-view/NGP-Chap13/annexure-chap13b/annexure-chap13b.component';
import { AnnexureChap13aComponent } from './course/main-course-view/NGP-Chap13/annexure-chap13a/annexure-chap13a.component';
import { AnnexureChap14Component } from './course/main-course-view/NGP-Chap14/annexure-chap14/annexure-chap14.component';
// import { StatusAlertComponent } from "./status-alert/status-alert.component";
import { InstructionPostTestComponent } from "./posttest/instruction-post-test/instruction-post-test.component";
import { MainPostTestComponent } from "./posttest/main-post-test/main-post-test.component";
import { ResultPostTestComponent } from "./posttest/result-post-test/result-post-test.component";
import { TestContentPostTestComponent } from "./posttest/test-content-post-test/test-content-post-test.component";
import { HomeComponent } from './admin/home/home.component';

import { HawkEyesPMainIndexComponent } from "./course/hawk-EyesP/hawk-eyes-p-main-index/hawk-eyes-p-main-index.component";
import { MainHawkEyesPCourseViewComponent } from "./course/hawk-EyesP/main-hawkEyesP-course-view/main-hawk-eyes-p-course-view/main-hawk-eyes-p-course-view.component";
import { HawkEyesPChap1Component } from "./course/hawk-EyesP/main-hawkEyesP-course-view/hawk-eyes-p-chapter1/hawk-eyes-p-chap1/hawk-eyes-p-chap1.component";
import { HawkEyesPChap2Component } from "./course/hawk-EyesP/main-hawkEyesP-course-view/hawk-eyes-p-chapter2/hawk-eyes-p-chap2/hawk-eyes-p-chap2.component";
import { HawkEyesPChap3Component } from "./course/hawk-EyesP/main-hawkEyesP-course-view/hawk-eyes-p-chapter3/hawk-eyes-p-chap3/hawk-eyes-p-chap3.component";
import { HawkEyesPChap4Component } from "./course/hawk-EyesP/main-hawkEyesP-course-view/hawk-eyes-p-chapter4/hawk-eyes-p-chap4/hawk-eyes-p-chap4.component";
import { HawkEyesPChap5Component } from "./course/hawk-EyesP/main-hawkEyesP-course-view/hawk-eyes-p-chapter5/hawk-eyes-p-chap5/hawk-eyes-p-chap5.component";
import { HawkEyesPChap6Component } from "./course/hawk-EyesP/main-hawkEyesP-course-view/hawk-eyes-p-chapter6/hawk-eyes-p-chap6/hawk-eyes-p-chap6.component";
import { Chap7aOverflowComponent } from "./course/hawk-EyesP/main-hawkEyesP-course-view/hawk-eyes-p-chapter7/chap7a-overflow/chap7a-overflow.component";
import { HawkEyesPChap7IndexComponent } from "./course/hawk-EyesP/main-hawkEyesP-course-view/hawk-eyes-p-chapter7/hawk-eyes-p-chap7-index/hawk-eyes-p-chap7-index.component";
import { HawkeyesIosMainCourseViewComponent } from "./course/hawkeyes-ios/hawkeyes-ios-course-view/hawkeyes-ios-main-course-view/hawkeyes-ios-main-course-view.component";
import { HawkeyesIosMainIndexComponent } from "./course/hawkeyes-ios/hawkeyes-ios-main-index/hawkeyes-ios-main-index.component";


const routes: Routes = [
  { path: "", component: HomecardsComponent , canActivate: [AuthGuard]},
  { path: "login", component: LoginComponent },
  { path: "reset-password", component: ForgotpasswordComponent },
  
  {
    path: "test",
    children: [
      { path: "", component: PreindexComponent, canActivate: [AuthGuard] },
      {path: "test-content", component: TestcontentComponent, canActivate: [AuthGuard] },
      {
        path: "pre-test",
        children: [
          {
            path: "",
            component: StartpretestComponent,
            canActivate: [AuthGuard]
          },
          {
            path: "result",
            component: TestResultComponent,
            canActivate: [AuthGuard]
          }
        ]
      }
    ]
  },
  { 
    path: "testcontentposttest",
    component: TestContentPostTestComponent,
    canActivate: [AuthGuard]
  },




  {
    path: "posttest",
    children: [
      { path: "", component: InstructionPostTestComponent, canActivate: [AuthGuard] },
      { path: "test-content", component: TestContentPostTestComponent, canActivate: [AuthGuard] },
      {
        path: "post-test",
        children: [
          {
            path: "",
            component: MainPostTestComponent,
            canActivate: [AuthGuard]
          },
          {
            path: "resultposttest",
            component: ResultPostTestComponent,
            canActivate: [AuthGuard]
          }
        ]
      }
    ]
  },

  // course routing
  {
    path: 'course',
    children: [
      { path: '', component: MainIndexComponent , canActivate: [AuthGuard]},
      {path:'ngp', 
      children:[
      { path: "annexure-1", component:AnnexureChap1Component, canActivate: [AuthGuard]},       
      { path: "annexure-3", component:AnnexureChap3Component, canActivate: [AuthGuard]},
      { path: "annexure-4", component:AnnexureChap4Component, canActivate: [AuthGuard]},
      { path: "annexure-5", component:AnnexureChap5Component, canActivate: [AuthGuard]},
      { path: "annexure-6", component:AnnexureChap6Component, canActivate: [AuthGuard]},
      { path: "annexure-7", component:AnnexureChap7Component, canActivate: [AuthGuard]},
      { path: "annexure-8", component:AnnexureChap8Component, canActivate: [AuthGuard]},
      { path: "annexure-9", component:AnnexureChap9Component, canActivate: [AuthGuard]},
      { path: "annexure-10", component:AnnexureChap10Component, canActivate: [AuthGuard]},
      { path: "annexure-13a", component:AnnexureChap13aComponent, canActivate: [AuthGuard]},
      { path: "annexure-13b", component:AnnexureChap13bComponent, canActivate: [AuthGuard]},
      { path: "annexure-14", component:AnnexureChap14Component, canActivate: [AuthGuard]}, 
      ]
      },
      { path: 'ngp/:id', component: MainCourseViewComponent, canActivate: [AuthGuard],
        children: [
          
          { path: 'introduction',
            children: [
              { path: '', component: IndexIntroComponent, canActivate: [AuthGuard]},
              { path: 'leaf-electric-vehicle', component: Topic1LEAFElectricVehicleComponent, canActivate: [AuthGuard]},
              { path: 'ev-charging-methods', component: Topic2EVChargingMethodsComponent, canActivate: [AuthGuard]},
              { path: 'charging-port', component: Topic3ChargingPortComponent, canActivate: [AuthGuard]},
              { path: 'mode-2-and-mode-3', component: Topic4Mode2AndMode3Component, canActivate: [AuthGuard]},
              { path: 'case-b-and-case-c', component: Topic5CaseBAndCaseCComponent, canActivate: [AuthGuard]},
              { path: 'modes-and-case-for-ev', component: Topic6ModesAndCaseForEVComponent, canActivate: [AuthGuard]},
              { path: 'vehicle-inlet-type', component: Topic7VehicleInletTypeComponent, canActivate: [AuthGuard]},
              { path: 'charging-time', component: Topic8ChargingTimeComponent, canActivate: [AuthGuard]},
              { path: 'charging-power', component: Topic9ChargingPowerComponent, canActivate: [AuthGuard]},
              { path: 'main-wiring-diagram', component: Topic10MainWiringDiagComponent, canActivate: [AuthGuard]}

            ]
          },
          { path: 'ngp-requirement',
            children: [
              { path: '', component: IndexRequirementComponent, canActivate: [AuthGuard]},
              { path: 'chap1-to-chap8', component: Topic1Chap1ToChap8Component, canActivate: [AuthGuard] },
              {path:'chap9-to-chap15', component: Topic2Chap9ToChap15Component, canActivate: [AuthGuard]}
            ]
          },

          { path: 'ngp-chap-1-2',
            children:[
              { path:'', component: IndexChap1Component , canActivate: [AuthGuard]},
              {path:'compliance-requirement-less1',component: ComplianceRequirementComponent, canActivate: [AuthGuard]},
              {path:'necessity-of-requirement-less1',component: NecessityOfRequirementComponent, canActivate: [AuthGuard]},
              {path:'non-compliance-result-less1',component: NonComplianceResultComponent, canActivate: [AuthGuard]},
              {path:'quizchap1',component: Quizchap1Component, canActivate: [AuthGuard]},
          ]
          },

          { path: 'ngp-chap3',
          children:[
            { path:'', component: IndexChap3Component, canActivate: [AuthGuard]},
            {path:'compliance-requirement-less3',component: ComplianceRequirementLess3Component, canActivate: [AuthGuard]},
            {path:'necessity-of-requirement-less3',component: NecessityOfRequirementLess3Component, canActivate: [AuthGuard]},
            {path:'non-compliance-result-less3',component: NonComplianceResultLess3Component, canActivate: [AuthGuard]},
          ]
          },

          { path: 'ngp-chap4',
          children:[
            { path:'', component: IndexChap4Component , canActivate: [AuthGuard]},
            {path:'compliance-requirement-less4',component: ComplianceRequirementLess4Component, canActivate: [AuthGuard]},
            {path:'necessity-of-requirement-less4',component: NecessityOfRequirementLess4Component, canActivate: [AuthGuard]},
            {path:'non-compliance-result-less4',component: NonComplianceResultLess4Component, canActivate: [AuthGuard]},
          ]
          },

          { path: 'ngp-chap5',
          children:[
            { path:'', component: IndexChap5Component, canActivate: [AuthGuard]},
            {path:'compliance-requirement-less5',component: ComplianceRequirementLess5Component, canActivate: [AuthGuard]},
            {path:'necessity-of-requirement-less5',component: NecessityOfRequirementLess5Component, canActivate: [AuthGuard]},
            {path:'non-compliance-result-less5',component: NonComplianceResultLess5Component, canActivate: [AuthGuard]},
          ]
          },

          { path: 'ngp-chap6',
          children:[
            { path:'', component: IndexChap6Component, canActivate: [AuthGuard]},
            {path:'compliance-requirement-less6',component: ComplianceRequirementLess6Component, canActivate: [AuthGuard]},
            {path:'necessity-of-requirement-less6',component: NecessityOfRequirementLess6Component, canActivate: [AuthGuard]},
            {path:'non-compliance-result-less6',component: NonComplianceResultLess6Component, canActivate: [AuthGuard]},
          ]
          },

          { path: 'ngp-chap7',
          children:[
            { path:'', component: IndexChap7Component, canActivate: [AuthGuard]},
            {path:'compliance-requirement-less7',component: ComplianceRequirementLess7Component, canActivate: [AuthGuard]},
            {path:'necessity-of-requirement-less7',component: NessecityOfRequirementLess7Component, canActivate: [AuthGuard]},
            {path:'non-compliance-result-less7',component: NonComplianceResultLess7Component, canActivate: [AuthGuard]},
          ]
          },

          { path: 'ngp-chap8',
          children:[
            { path:'', component: IndexChap8Component, canActivate: [AuthGuard]},
            {path:'compliance-requirement-less8',component: ComplianceRequirementLess8Component, canActivate: [AuthGuard]},
            {path:'necessity-of-requirement-less8',component:NecessityOfRequirementLess8Component , canActivate: [AuthGuard]},
            {path:'non-compliance-result-less8',component: NonComplianceResultLess8Component, canActivate: [AuthGuard]},
          ]
          },

          { path: 'ngp-chap9',
          children:[
            { path:'', component: IndexChap9Component, canActivate: [AuthGuard]},
            {path:'compliance-requirement-less9',component: ComplianceRequirementLess9Component, canActivate: [AuthGuard]},
            {path:'necessity-of-requirement-less9',component: NecessityOfRequirementLess9Component, canActivate: [AuthGuard]},
            {path:'non-compliance-result-less9',component:NonComplianceResultLess9Component, canActivate: [AuthGuard]},
          ]
          },

          { path: 'ngp-chap10',
          children:[
            { path:'', component: IndexChap10Component, canActivate: [AuthGuard]},
            {path:'compliance-requirement-less10',component: ComplianceRequirementLess10Component, canActivate: [AuthGuard]},
            {path:'necessity-of-requirement-less10',component:NecessityOfRequirementLess10Component , canActivate: [AuthGuard]},
            {path:'non-compliance-result-less10',component: NonComplianceResultLess10Component, canActivate: [AuthGuard]},
          ]
          },

          { path: 'ngp-chap11',
          children:[
            { path:'', component: IndexChap11Component, canActivate: [AuthGuard]},
            {path:'compliance-requirement-less11',component: ComplianceRequirementLess11Component , canActivate: [AuthGuard]},
            {path:'necessity-of-requirement-less11',component:NecessityOfRequirementLess11Component , canActivate: [AuthGuard]},
            {path:'non-compliance-result-less11',component: NonComplianceResultLess11Component, canActivate: [AuthGuard]},
          ]
          },

          { path: 'ngp-chap12',
          children:[
            { path:'', component: IndexChap12Component , canActivate: [AuthGuard]},
            {path:'compliance-requirement-less12',component: ComplianceRequirementLess12Component , canActivate: [AuthGuard]},
            {path:'necessity-of-requirement-less12',component: NecessityOfRequirementLess12Component , canActivate: [AuthGuard]},
            {path:'non-compliance-result-less12',component: NonComplianceResultLess12Component , canActivate: [AuthGuard]},
          ]
          },

          { path: 'ngp-chap13',
          children:[
            { path:'', component: IndexChap13Component, canActivate: [AuthGuard]},
            {path:'compliance-requirement-less13',component: ComplianceRequirementLess13Component , canActivate: [AuthGuard]},
            {path:'necessity-of-requirement-less13',component:NecessityOfRequirementLess13Component , canActivate: [AuthGuard]},
            {path:'non-compliance-result-less13',component:NonComplianceResultLess13Component , canActivate: [AuthGuard]},
          ]
          },

          { path: 'ngp-chap14',
          children:[
            { path:'', component: IndexChap14Component , canActivate: [AuthGuard]},
            {path:'compliance-requirement-less14',component: ComplienceRequirementLess14Component , canActivate: [AuthGuard]},
            {path:'necessity-of-requirement-less14',component:NecessityOfRequirementLess14Component , canActivate: [AuthGuard] },
            {path:'non-compliance-result-less14',component:NonComplianceResultLess14Component , canActivate: [AuthGuard]},
          ]
          },

          { path: 'ngp-chap15',
          children:[
            { path:'', component: IndexChap15Component , canActivate: [AuthGuard]},
            {path:'compliance-requirement-less15',component:  ComplianceRequirementLess15Component , canActivate: [AuthGuard]},
            {path:'necessity-of-requirement-less15',component: NecessityOfRequirementLess15Component , canActivate: [AuthGuard]},
            {path:'non-compliance-result-less15',component: NonComplianceResultLess15Component , canActivate: [AuthGuard]},
          ]
          }


        ]
      }
    ]
  },
{
  path:'course-case-study',
  children:[
    { path: '', component: CaseStudyMainIndexComponent , canActivate: [AuthGuard]},
    { path: 'case-study/:id', component: CaseMainCourseViewComponent, canActivate: [AuthGuard],
    children:[
      { path: 'case-study1',component:CaseStudyChap1Component , canActivate: [AuthGuard]},
      { path: 'case-study2',component:CaseStudyChap2Component , canActivate: [AuthGuard]},
      {path:'case-study3',component:CaseStudyChap3Component , canActivate: [AuthGuard]},
      {path:'case-study4',component:CaseStudyChap4Component , canActivate: [AuthGuard]},
      {path:'case-study5',component:CaseStudyChap5Component , canActivate: [AuthGuard]},
      {path:'case-study6',component:CaseStudyChap6Component, canActivate: [AuthGuard]},
    ]

  }
  ]},

  {
    path: 'course-do-donts',
    children: [
      { path: '', component: MainDoDontsIndexComponent , canActivate: [AuthGuard]},
      { path: 'do-donts/:id', component: MainDoDontsCourseViewComponent, canActivate: [AuthGuard],
      children:[
          {
            path: 'do-donts-indexChap1', 
            children: [
              { path: '', component:DoDontsIndexChap1Component, canActivate: [AuthGuard] },
              { path: 'necessityofpointLesson1', component:NecessityOfPointLess1Component, canActivate: [AuthGuard]},
              { path: 'non-compliance-resultLesson1', component:DosDontsNonComplianceLess1Component , canActivate: [AuthGuard]},
            ]
          },
          {
            path: 'do-donts-indexChap2',
            children:[
              { path: "", component:DoDontsIndexChap2Component, canActivate: [AuthGuard]},
              { path: 'necessityofpointLesson2', component:NecessityOfPointLess2Component, canActivate: [AuthGuard]},
              { path: 'non-complinace-resultLesson2', component:DosDontsNonComplianceLess2Component, canActivate: [AuthGuard]},

            ]
          },
          {
            path: 'do-donts-indexChap3',
            children:[
              { path: '', component:DoDontsIndexChap3Component, canActivate: [AuthGuard]},
              { path: 'necessityofpointLesson3', component:NecessityOfPointLess3Component, canActivate: [AuthGuard]},
              { path: 'non-compliance-resultLesson3', component:DosDontsNonComplianceLess3Component, canActivate: [AuthGuard]},
            ]
          },
          {
            path: 'do-donts-indexChap4',
            children:[
              { path: '', component:DoDontsIndexChap4Component, canActivate: [AuthGuard]},
              { path: 'necessityofpointLesson4', component:NecessityOfPointLess4Component, canActivate: [AuthGuard]},
              { path: 'non-compliance-resultLesson4', component:DosDontsNonComplianceLess4Component, canActivate: [AuthGuard]},
            ]
          },
          {
            path: 'do-donts-indexChap5',
            children:[
              { path: '', component:DoDontsIndexChap5Component, canActivate: [AuthGuard]},
              { path: 'necessityofpointLesson5', component:NecessityOfPointLess5Component, canActivate: [AuthGuard]},
              { path: 'non-compliance-resultLesson5', component:DosDontsNonComplianceLess5Component, canActivate: [AuthGuard]},
            ]
          },
          {
            path: 'do-donts-indexChap6',
            children:[
              { path: '', component:DoDontsIndexChap6Component, canActivate: [AuthGuard]},
              { path: 'necessityofpointLesson6', component:NecessityOfPointLess6Component, canActivate: [AuthGuard]},
              { path: 'non-compliance-resultLesson6', component:DosDontsNonComplianceLess6Component, canActivate: [AuthGuard]},
            ]
          },  
          
          {
            path:'do-donts-indexLesson7',
            children:[
              { path: '', component:DoDontsIndexChap7Component, canActivate: [AuthGuard]},
              { path: 'necessityofpointLesson7', component:NecessityOfPointLess7Component, canActivate: [AuthGuard]},
              { path: 'non-compliane-resultLesson7', component:DosDontsNonComplianceLess7Component, canActivate: [AuthGuard]},
            ]
          },
          
          {
            path:'do-donts-indexLesson8',
            children:[
              { path: '', component:DoDontsIndexChap8Component, canActivate: [AuthGuard]},
              { path: 'necessityofpointLesson8', component:NecessityOfPointLess8Component, canActivate: [AuthGuard]},
              { path: 'non-compliance-resultLesson8', component:DosDontsNonComplianceLess8Component, canActivate: [AuthGuard]},
            ]
          },

          {
            path:'do-donts-indexLesson9',
            children:[
              { path: '', component:DoDontsIndexChap9Component, canActivate: [AuthGuard]},
              { path: 'necessityofpointLesson9', component:NecessityOfPointLess9Component, canActivate: [AuthGuard]},
              { path: 'non-compliance-resultLesson9', component:DosDontsNonComplianceLess9Component, canActivate: [AuthGuard]},
            ]
          },

          {
            path:'do-donts-indexLesson10',
            children:[
              { path: '', component:DoDontsIndexChap10Component, canActivate: [AuthGuard]},
              { path: 'necessityofpointLesson10', component:NecessityOfPointLess10Component, canActivate: [AuthGuard]},
              { path: 'non-compliance-resultLesson10', component:DosDontsNonComplianceLess10Component, canActivate: [AuthGuard]},
            ]

          },

          {
            path:'do-donts-indexLesson11',
            children:[
              { path: '', component:DoDontsIndexChap11Component, canActivate: [AuthGuard]},
              { path: 'necessityofpointLesson11', component:NecessityOfPointLess11Component, canActivate: [AuthGuard]},
              { path: 'non-compliance-resultLesson11', component:DosDontsNonComplianceLess11Component, canActivate: [AuthGuard]},
            
            ]
          },
      ]
    }
    ]
  
  },

// {
//   path: 'course-hawkEyesP',
//   children: [
//     { path: '', component: HawkEyesPMainIndexComponent , canActivate: [AuthGuard]},
//     { path: 'hawkEyesP/:id', component: MainHawkEyesPCourseViewComponent, canActivate: [AuthGuard],}
//   ]

// },  

{
  path: 'hawkeyesp',
  children: [
    { path: 'android-material', component: HawkEyesPMainIndexComponent , canActivate: [AuthGuard]},
    { path: 'android-material/:id', component: MainHawkEyesPCourseViewComponent, canActivate: [AuthGuard],},
    { path: 'ios', component: HawkeyesIosMainIndexComponent,canActivate: [AuthGuard] },
    { path: 'ios/:id', component:HawkeyesIosMainCourseViewComponent,  canActivate: [AuthGuard]},  
  ]

},


];


@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
