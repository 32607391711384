import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chap8f-mains-breaker',
  templateUrl: './chap8f-mains-breaker.component.html',
  styleUrls: ['./chap8f-mains-breaker.component.css']
})
export class Chap8fMainsBreakerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
