import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalConstants } from '../../common/global-constants';

@Injectable({
  providedIn: 'root'
})
export class CreateuserService {
  private url = GlobalConstants.rootURL;

  constructor(private http: HttpClient){ }

  createUser(country, email, firstname, lastname, password,market){
    var data = {
      country: country,
      email: email,
      firstname: firstname,
      lastname: lastname,
      password: password,
      market: market
    }
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return this.http.post(this.url+'/api/users/add', data, {
      headers: headers
    })
  }
}
