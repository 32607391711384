import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-test-history',
  templateUrl: './test-history.component.html',
  styleUrls: ['./test-history.component.css']
})
export class TestHistoryComponent implements OnInit {
  displayedColumns: string[] = [ 'email', 'country','first_name', 'last_name' , 'pre-attempt'];
  displayedColumns1: string[] = [ 'email', 'country','first_name', 'last_name' , 'post-attempt'];

  constructor() { }

  ngOnInit() {
  }

}
