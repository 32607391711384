import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GetService } from 'src/app/services/get.service';

@Component({
  selector: 'app-subchap7a-index',
  templateUrl: './subchap7a-index.component.html',
  styleUrls: ['./subchap7a-index.component.css']
})
export class Subchap7aIndexComponent implements OnInit {

  lastCompleted: number;

  constructor(private statusService: GetService,private toastr:ToastrService,private router: Router) { }

  navigateNgp(componentId: number){

    this.router.navigate(['hawkeyesp/ios', componentId]);
  }
  isCompleted(componentId: number){
    if(componentId <= this.lastCompleted){
      return true;
    }
    return false;
  }




  ngOnInit() {
    window.scrollTo(0, 0);
  }

}
