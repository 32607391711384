import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GetService } from '../services/get.service';
import { GetquestionsService } from '../services/getquestions.service';

@Component({
  selector: 'app-homecards',
  templateUrl: './homecards.component.html',
  styleUrls: ['./homecards.component.css']
})
export class HomecardsComponent implements OnInit {
  val = 80;
  ngpCompletion = 0;
  ngpCompletioncasestudy = 0;
  ngpCompletionDosDonts = 0;
  ngpVisible: boolean = false
  doDontsVisible: boolean = true
  caseStudyVisible: boolean = true
  postTestVisible: boolean = true
  constructor(private router: Router, private statusService: GetService, private queService: GetquestionsService) { 
    this.queService.ifTestAttempted().subscribe(data=>{
      if(data){
        this.ngpVisible = true
      }else{
        this.ngpVisible = false
      }
    },err=>{
      // console.log(err)
    })

    this.statusService.getCompletionStatus()
      .subscribe(data=>{
        let lastCompleted = parseInt(data.toString());
        var completion = (lastCompleted/84)*100;
        // console.log('Status Received-',data);
        this.ngpCompletion = parseInt(Math.round(completion).toString());
        if (this.ngpCompletion == 100) {
          this.doDontsVisible = false
        }
    },err=>{
      // window.localStorage.clear();
      // this.router.navigate(['/']);
    })

    this.statusService.getCompletionStatusCaseStudy()
    .subscribe(data=>{
      let lastCompleted = parseInt(data.toString());
      var completion = (lastCompleted/6)*100;
      // console.log('Status Received-',data);
      this.ngpCompletioncasestudy = parseInt(Math.round(completion).toString());
      if(this.ngpCompletioncasestudy == 100){
        this.postTestVisible = false     
      }
    },err=>{
      // window.localStorage.clear();
      // this.router.navigate(['/']);
    })

    this.statusService.getCompletionStatusDos()
      .subscribe(data=>{
      let lastCompleted = parseInt(data.toString());
      var completion = (lastCompleted/33)*100;
      // console.log('Status Received-',data);
      this.ngpCompletionDosDonts = parseInt(Math.round(completion).toString());
      if(this.ngpCompletionDosDonts == 100){
        this.caseStudyVisible = false
      }
    },err=>{
      // window.localStorage.clear()
      // this.router.navigate(['/']);
    })
  }

  ngOnInit() {

  }

  onClickPreTest(){
    window.scroll(0,0);
    this.router.navigate(['preindex'])
  }

  onclick(){
    if(!this.ngpVisible){
      window.scroll(0,0);
      this.router.navigate(['course']);
    }
  }

  onclickcase(){
    if(!this.caseStudyVisible){
      window.scroll(0,0);
      this.router.navigate(['course-case-study']);
    }
  }

  onclickDo(){
    if(!this.doDontsVisible){
      window.scroll(0,0);
      this.router.navigate(['course-do-donts']);
    }
  }

  onclickhawk(){
      window.scroll(0,0);
      this.router.navigate(['hawkeyesp/android-material']);
  }

  onclickhawkios(){
    window.scroll(0,0);
    this.router.navigate(['hawkeyesp/ios']);
  }

  onclickpost(){
    if(!this.postTestVisible){
      window.scroll(0,0);
      this.router.navigate(['posttest']);
    }

  }

}
