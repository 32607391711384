import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subchap8q-spd',
  templateUrl: './subchap8q-spd.component.html',
  styleUrls: ['./subchap8q-spd.component.css']
})
export class Subchap8qSpdComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
