import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chap7m-post-setting2',
  templateUrl: './chap7m-post-setting2.component.html',
  styleUrls: ['./chap7m-post-setting2.component.css']
})
export class Chap7mPostSetting2Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
