import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chap8a-main-screen',
  templateUrl: './chap8a-main-screen.component.html',
  styleUrls: ['./chap8a-main-screen.component.css']
})
export class Chap8aMainScreenComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
