import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-annexure-chap3',
  templateUrl: './annexure-chap3.component.html',
  styleUrls: ['./annexure-chap3.component.css']
})
export class AnnexureChap3Component implements OnInit {

  constructor(private router:Router) { }

  ngOnInit() {
  window.scrollTo(0, 0);
    
  }
  onClickBack(){
    this.router.navigate(['course/ngp/22']);
  }
}
