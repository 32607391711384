import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Component, ViewChild } from '@angular/core';

import { NgForm } from '@angular/forms';
import { CreateuserService } from 'src/app/admin/services/createuser.service';



export class FormObject {
  public name: string;
  public email: string;
  public first: string;
  public last: string;
  public password: string;
  public market: string;
}


interface Market {
  value: string;
  viewValue: string;
}


@Component({
  selector: 'app-usercreation',
  templateUrl: './usercreation.component.html',
  styleUrls: ['./usercreation.component.css']
})

export class UsercreationComponent implements OnInit {
  userCreateStatus: boolean = false;
  model: FormObject;
  message = "";
  @ViewChild('MyForm', {static: false}) MyForm: NgForm;

  constructor(private service: CreateuserService, private router: Router) {
    this.model = ({} as FormObject);
  }

  submitForm() {
    this.MyForm.form.markAllAsTouched();
    // console.log(this.model.market);
    if (this.MyForm.form.valid) {
      this.service.createUser(this.model.name, this.model.email, this.model.first, this.model.last, this.model.password,this.model.market)
        .subscribe(data => {
          if(data){
            // console.log("success-",data)
            // this.message = "User Created Successfully, click to<a routerLink='//login'>login</a>";
            this.userCreateStatus = true;
            // console.log(JSON.stringify(data))
          }else{
            this.userCreateStatus = false;
          }
        },err=>{
          // console.log("errr-",err)
          this.message = JSON.stringify(err);
          // console.log(this.message)
          alert(this.message)
        })
    }
  }

  ngOnInit(): void {
  }

  // onRegister(){
  //   this.router.navigate(["login"]);
  // }
  selectedValue: string;
  selectedCar: string;

  markets: Market[] = [
    {value: 'matured', viewValue: 'Matured'},
    {value: 'Non-matured', viewValue: 'Non-matured'},
  ];


}
