import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-annexure-chap8',
  templateUrl: './annexure-chap8.component.html',
  styleUrls: ['./annexure-chap8.component.css']
})
export class AnnexureChap8Component implements OnInit {

  constructor(private router:Router) { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }
  onClickBack(){
    this.router.navigate(['course/ngp/47'])
  }
}
