import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chap8k-earthing',
  templateUrl: './chap8k-earthing.component.html',
  styleUrls: ['./chap8k-earthing.component.css']
})
export class Chap8kEarthingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
