import { Injectable } from '@angular/core';
import { Quiz } from '../shared/quiz';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { GlobalConstants } from '../common/global-constants';


@Injectable({
  providedIn: 'root'
})
export class QuizcourseService {
  url = GlobalConstants.rootURL;
  // questions= new Quiz(1, "Q. What will be the minimum size for dedicated Wire (PVC type) used for 32A EV Charging installation from DB to EVSE ? Select the correct option.", 
  //                     "(1)  For 32A: Min. Wire Size 10 mm2", 
  //                     "(2)  For 32A: Min. Wire Size 6 mm2", 
  //                     "(3)  For 32A: Min. Wire Size 4 mm2",
  //                     "(4)  Any wiring size can be used ",
  //                     "(1)  For 32A: Min. Wire Size 10 mm2",
  //                     ""
  //                     );
question = [
  {
  "id": "1",
  "description": "Q. What will be the minimum size for dedicated Wire (PVC type) used for 32A EV Charging installation from DB to EVSE ? Select the correct option.",
  "correctOption": "(1)  For 32A: Min. Wire Size 10 mm2",
  "questionType": "single",
  "optionType":"text",
  "questionImage":"Chapter1-2.png",
  "userResponse": "NA"
},
{
  "id": "2",
  "description": "Q. What will happen if Mode 2 socket is not mated properly with Mode 2 EVSE Plug ? Select all the correct option(s).",
  "correctOption": "(1) The plug cord may be pulled out accidently|(2) Over heating of the plug and socket may occur due to arcing sparking across the connecting parts|(3) Charging will stop by Plug Disconnecting",
  "questionType": "multiple",
  "optionType":"text",
  "questionImage":"Chapter15.png",
  "userResponse": "NA"
},

{
  "id": "3",
  "description": "Q. What is the Maximum voltage drop allowed from Origin of Installation (DB Box) to load (EVSE) as per IEC standards ?",
  "correctOption": "[2] 5%",
  "questionType": "single",
  "optionType":"text",
  "questionImage":"Chapter3.png",
  "userResponse": "NA"
},

{
  "id": "4",
  "description": "Q. What should be the minimum capacity of dedicated MCB (=OverCurrent protection) for the 32A EV Charging installation? Select the correct option.",
  "correctOption": "(B) 40 A",
  "questionType": "single",
  "optionType":"text",
  "questionImage":"Chapter4.png",
  "userResponse": "NA"
},

{
  "id": "5",
  "description": "Q. Which of the installation is correct for dedicated MCB in EV Charging installation ? Select the correct option.",
  "correctOption": "Chapter5b.png",
  "questionType": "single",
  "optionType":"image",
  "questionImage":"NA",
  "userResponse": "NA"
},
{
  "id": "6",
  "description": "Q. Which of the installation is correct for dedicated RCD in EV Charging installation? Select the correct option.",
  "correctOption": "Chapter6b.png",
  "questionType": "single",
  "optionType":"image",
  "questionImage":"NA",
  "userResponse": "NA"
},
{
  "id": "7",
  "description": " Q. Is the RCD installed in the following EVSE installation correctly? ",
  "correctOption": "(B) No",
  "questionType": "single",
  "optionType":"text",
  "questionImage":"Chapter7.png",
  "userResponse": "NA"

},
{
  "id": "8",
  "description": " Q. Select the correct option for TT earthing scheme for wiring diagram shown below: ",
  "correctOption": "tt1.png",
  "questionType": "single",
  "optionType":"image",
  "questionImage":"NA",
  "userResponse": "NA"

},
{
  "id": "9",
  "description": " Q. Which of the following is the correct diagram for certified EVSE/Socket dedicated for EV? ",
  "correctOption": "Chapter9a.png",
  "questionType": "single",
  "optionType":"image",
  "questionImage":"NA",
  "userResponse": "NA"

},

{
  "id": "10",
  "description": " Q. What should be the height of the Charging Installation (EVSE) from ground level ? Select the correct option.",
  "correctOption": "Chapter10a.png",
  "questionType": "single",
  "optionType":"image",
  "questionImage":"NA",
  "userResponse": "NA"
},

{
  "id": "11",
  "description": " Q. Which of the following Wall-box with the given specification will operate if the specified supply voltage tolerance as per local regulation ( IEC: 207 – 253 V)? Select all the correct option(s).  ",
  "correctOption": "(A) Wallbox Input Voltage Range: 207 – 253 V",
  "questionType": "single",
  "optionType":"text",
  "questionImage":"Chapter11.png",
  "userResponse": "NA"
},

{
  "id": "12",
  "description": " Q. What should be the minimum Socket Durability in strokes with Load? Select the correct option.",
  "correctOption": "(B) 5000 Cycles (10,000 strokes)",
  "questionType": "single",
  "optionType":"text",
  "questionImage":"Chapter12.png",
  "userResponse": "NA"
},

{
  "id": "13",
  "description": "Q. Select the correct option for the minimum IP (Ingress Protection) rating for the EV Charging Installation of EVSE (Electric vehicle supply equipment) of the diagram given below.",
  "correctOption": "[3] (A) Minimum IP 44, (B) Minimum IP 54, (C) Minimum IP 44 ",
  "questionType": "single",
  "optionType":"text",
  "questionImage":"Chapter13.png",
  "userResponse": "NA"
},

{
  "id": "14",
  "description": "Q. What should be the Socket current rating for Mode 2 10A EVSE? Select the correct option.",
  "correctOption": "(3) 10A ",
  "questionType": "single",
  "optionType":"text",
  "questionImage":"Chapter14.png",
  "userResponse": "NA"
}

];

options=[
        ["(1)  For 32A: Min. Wire Size 10 mm2",
         "(2)  For 32A: Min. Wire Size 6 mm2",
         "(3)  For 32A: Min. Wire Size 4 mm2",
         "(4)  Any wiring size can be used "
        ],
       
        [" (1) The plug cord may be pulled out accidently",
        " (2) Over heating of the plug and socket may occur due to arcing sparking across the connecting parts",
        "(3) Charging will stop by Plug Disconnecting"
        ],

        ["[1] 3%",
        "[2] 5%",
        "[3] 6%",
        "[4] 10%"
        ],

        ["(A) 50 A",
        "(B) 40 A",
        "(C) 32 A",
        "(D) 25 A"
        ],

        ["Chapter5a.png",
         "Chapter5b.png",
        ],

        ["Chapter6a.png",
         "Chapter6b.png",
        ],

        ["(A) Yes",
         "(B) No",
        ],

        ["tt1.png",
         "tt2.png",
         "tt3.png",
        ],
        ["Chapter9a.png",
         "Chapter9b.png",
        ],
        ["Chapter10a.png",
        "Chapter10b.png",
        "Chapter10c.png",
       ],
       ["(A) Wallbox Input Voltage Range: 207 – 253 V",
        "(B) Wallbox Input Voltage Range: 220 – 264 V",
        "(C) Wallbox Input Voltage Range: 185 – 240 V",
       ],
      ["(A) 2500 Cycles (5,000 strokes)",
      "(B) 5000 Cycles (10,000 strokes)",
      "(C) 10,000 Cycles (20,000 strokes)"
      ],
      ["[1] (A) Minimum IP 54, (B) Minimum IP 44, (C) Minimum IP 54",
      " [2] (A) Minimum IP 44, (B) Minimum IP 44, (C) Minimum IP 44  ",
      "[3] (A) Minimum IP 44, (B) Minimum IP 54, (C) Minimum IP 44 "
      ],

      ["(1) 6A",
      " (2) 8A ",
      "(3) 10A "
      ]

      ]


      constructor(private http: HttpClient) { }
      getquizzes(questionNumber:number){
        // console.log(this.question[questionNumber]);
        // console.log(this.options);
        return [this.question[questionNumber], this.options[questionNumber]] ;
      }
    
      saveChapterQuizResult(question, chapterName){
        var data = {
          result: question,
          chapterName: chapterName
        }
        const headers = new HttpHeaders()
          .set('Content-Type', 'application/json');
        return this.http.post(this.url+'/api/chapter-wise-quiz/save-result', data)
      }
    
      // get chapter quiz history
      getChapterQuizResult(){
        return this.http.get(this.url+'/api/chapter-wise-quiz/get-result');
      }
    
      // get details chapterwise quiz
      getDetailCourseStatus(username: string){
        return this.http.get(this.url+'/api/chapter-wise-quiz/detail-status?username='+username)
      }
}