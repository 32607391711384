import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chap8o-wallbox-socket',
  templateUrl: './chap8o-wallbox-socket.component.html',
  styleUrls: ['./chap8o-wallbox-socket.component.css']
})
export class Chap8oWallboxSocketComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
