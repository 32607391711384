import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hawkeyesios-datainputs',
  templateUrl: './hawkeyesios-datainputs.component.html',
  styleUrls: ['./hawkeyesios-datainputs.component.css']
})
export class HawkeyesiosDatainputsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
