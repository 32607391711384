import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RandomQuestionsService } from 'src/app/admin/services/random-questions.service';
import { GetquestionsService } from 'src/app/services/getquestions.service';
import { GetquestionspostService } from 'src/app/services/getquestionspost.service';
import { GlobalConstants } from 'src/app/common/global-constants';

@Component({
  selector: 'app-instruction-post-test',
  templateUrl: './instruction-post-test.component.html',
  styleUrls: ['./instruction-post-test.component.css']
})
export class InstructionPostTestComponent implements OnInit {

  warningMsg: boolean = false;
  timeInstruction;
  constructor(private router: Router,
    private queService: GetquestionspostService,
    private randomQuestion: RandomQuestionsService) {
    this.randomQuestion.getMarketDetails()
    .subscribe(data=>{
      this.timeInstruction = parseInt(data["time"]); 
    })
  }

  ngOnInit() {
  }

  onPostTestStart(){
    if(confirm('Test will be launched in new Window, Are you sure you want to start the test?')){
      this.queService.ifTestAttempted().subscribe(data=>{
        if(data){
          if(GlobalConstants.pretestOpen == null || GlobalConstants.pretestOpen.closed){
            GlobalConstants.pretestOpen = window.open('posttest/post-test', '', 'toolbar=0');
          }else{
            this.router.navigate(['posttest/test-content'])
          }
          // this.router.navigate(['posttest/post-test'])
        }else{
          this.warningMsg = true;
        }
      },err=>{
        // console.log(err)
      })
    }else{
      // console.log("not confirmed")
    }
  }

}
