import { Component, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { Router, ActivatedRoute } from '@angular/router';
import { GetService } from 'src/app/services/get.service';

@Component({
  selector: 'app-case-study-main-index',
  templateUrl: './case-study-main-index.component.html',
  styleUrls: ['./case-study-main-index.component.css']
})
export class CaseStudyMainIndexComponent implements OnInit {
  lastCompleted:number = 0;
  panelOpenState = false;
  panelOpenState1 = false;
  // allExpandState= true;
  expandCollapseState : boolean =false;
  colorName = 'green';
  
  
  @ViewChild(MatAccordion,{static:true}) accordion: MatAccordion;
    constructor(private router: Router, private route: ActivatedRoute,private statusService: GetService) { }
  
    ngOnInit() {
      window.scrollTo(0, 0);
      this.statusService.getCompletionStatusCaseStudy()
      .subscribe(data=>{
        this.lastCompleted = parseInt(data.toLocaleString());
        // console.log("Status Received-",this.lastCompleted)
      },err=>{
        alert("could not get status "+err);
      })

    }

    isCompleted(componentId: number){
      if(componentId <= this.lastCompleted){
        return true;
      }
      return false;
    }
  
  goToChapterTopic(){
    this.router.navigate([0], {relativeTo: this.route})
  }
}
