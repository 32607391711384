import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-annexure-chap10',
  templateUrl: './annexure-chap10.component.html',
  styleUrls: ['./annexure-chap10.component.css']
})
export class AnnexureChap10Component implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }
  onClickBack(){
    this.router.navigate(['course/ngp/57'])
  }

}
