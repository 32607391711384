import { Component, OnInit } from '@angular/core';
import { AdminserviceService } from '../services/adminservice.service';
import { GlobalConstants } from 'src/app/common/global-constants';
import {AfterViewInit, ViewChild} from '@angular/core';
import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { AdminserviceposttestService } from '../services/adminserviceposttest.service';

@Component({
  selector: 'app-posttest-analysis',
  templateUrl: './posttest-analysis.component.html',
  styleUrls: ['./posttest-analysis.component.css']
})
export class PosttestAnalysisComponent implements OnInit,AfterViewInit {
  pretestRecords;

  displayedColumns: string[] = ['username', 'date_attempted', 'total_points', 'obtained_points' , 'result','export'];
  dataSource:any;
  @ViewChild(MatPaginator,{static: true}) paginator: MatPaginator;

  constructor(private adminService: AdminserviceposttestService) {
    this.adminService.getPretestHistory().subscribe(data=>{
      this.pretestRecords = data;
      this.dataSource = new MatTableDataSource<TableElement>(this.pretestRecords);
      this.dataSource.paginator = this.paginator;
      // console.log(data)
      // console.log(this.pretestRecords)
      // console.log(this.dataSource)
    }, error=>{
      // console.log("cant load data")
    })
   }
   exportData(id) {
    this.adminService.createReport(id)
      .subscribe(data=>{
        this.downloadFile(data['csvString'], data['userName']);
      },error=>{
        // console.log("cant load data")
    })
  }

  downloadFile(data, filename:string) {
    const blob = new Blob([data], { type: 'text/csv' });
    const url= window.URL.createObjectURL(blob);

    var anchor = document.createElement("a");
    anchor.download = 'Post-Test_Result_'+filename+'.csv';
    anchor.href = url;
    anchor.click();
  }
   
  ngOnInit() {
  }
  ngAfterViewInit(){

  }
}
export interface TableElement {
  username: string;
  date_attempted: number;
  total_points: number;
  obtained_points: number;
  result: number;
  export: string;
}