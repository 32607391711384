import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subchap7d-view-in-testflight',
  templateUrl: './subchap7d-view-in-testflight.component.html',
  styleUrls: ['./subchap7d-view-in-testflight.component.css']
})
export class Subchap7dViewInTestflightComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
