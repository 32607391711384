import { Component, OnInit, TestabilityRegistry } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Router } from '@angular/router';
import { GetService } from 'src/app/services/get.service';
import { QuizcourseService } from 'src/app/services/quizcourse.service';

@Component({
  selector: 'app-quizchap3',
  templateUrl: './quizchap3.component.html',
  styleUrls: ['./quizchap3.component.css']
})
export class Quizchap3Component implements OnInit {
  question : any;
  lastCompleted: number; 
  options: any;
  data: any;
  answerStatus:boolean = false;
  selectedOption:string;
  cssChangeAction:string;
  constructor( private quizcourseService: QuizcourseService,private router: Router,private statusService: GetService) {
    
    this.statusService.getCompletionStatus()
    .subscribe(data=>{
      this.lastCompleted = parseInt(data.toString());
    },err=>{
      alert("Couldnt get status"+err);
    })

   }

  ngOnInit():void {
    window.scrollTo(0, 0);
    // window.location.reload();
    this.data = this.quizcourseService.getquizzes(2);
    // console.log(this.question);
    this.question = this.data[0];
    this.options = this.data[1];
    // console.log(this.question);
    // console.log(this.options);

  }

  radioChange(event){
    // console.log(event.value)
    this.selectedOption = event.value;
  }

  onAnswerCheck(){
  this.answerStatus=true;
  this.cssChangeAction=this.selectedOption;
  }

  onSubmitQuiz(){

    // this.quizcourseService.saveChapterQuizResult(this.question, `chapter_3`)
    //   .subscribe(data=>{
    //     if (data){
    //       this.router.navigate(['/course/ngp/25']);
    //     } else{
    //       alert("Cant update the score, Please try again!!!")
    //     }
    // })


    if (this.lastCompleted == 23) {
      this.statusService.updateCompletionStatus(23, 24)
        .subscribe(data => {
          if (data) {
            this.quizcourseService.saveChapterQuizResult(this.question, `chapter_3`)
              .subscribe(data => {
                if (data) {
                  this.router.navigate(['/course/ngp/25']);
                } else {
                  alert("Can't update the score, Please try again!!!")
                }
              })
          }
        }, err => {
          alert("something went wrong, please try again!!")
        })
    } else {

    this.quizcourseService.saveChapterQuizResult(this.question, `chapter_3`)
      .subscribe(data => {
        if (data) {
          this.router.navigate(['/course/ngp/25']);
        } else {
          alert("Can't update the score, Please try again!!!")
        }
      })

  }   


  }

}
