import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hawk-eyes-p-chap3',
  templateUrl: './hawk-eyes-p-chap3.component.html',
  styleUrls: ['./hawk-eyes-p-chap3.component.css']
})
export class HawkEyesPChap3Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
