import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { GlobalConstants } from '../common/global-constants';

@Injectable({
  providedIn: 'root'
})
export class GetquestionsService {
  private url = GlobalConstants.rootURL;

  constructor(private http: HttpClient) { }

  getQuestions(easy: number, medium: number, hard: number, custom: number){
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return this.http.get(this.url+'/api/test/pretest/20?easy='+easy+'&medium='+medium+'&hard='+hard+'&custom='+custom, {
      headers: headers
    })
  }

  saveResult(result) {
    var data = {
      result: result
    }
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return this.http.post<boolean>(this.url+'/api/pretest/save-result', data, {
      headers: headers
    })
  }

  ifTestAttempted() {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return this.http.get<boolean>(this.url+'/api/test/if-attempted', {
      headers: headers
    })
  }
}
