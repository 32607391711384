import { Component, OnInit,ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { GetService } from 'src/app/services/get.service';


@Component({
  selector: 'app-hawkeyes-ios-main-index',
  templateUrl: './hawkeyes-ios-main-index.component.html',
  styleUrls: ['./hawkeyes-ios-main-index.component.css']
})
export class HawkeyesIosMainIndexComponent implements OnInit {

  lastCompleted:number = 0;
  panelOpenState = false;
  panelOpenState1 = false;
  // colorName = 'green';
  expandCollapseState : boolean =false;



  @ViewChild(MatAccordion,{static:true}) accordion: MatAccordion;

  constructor(private statusService: GetService,private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
  }

}
