import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subchap8f-location',
  templateUrl: './subchap8f-location.component.html',
  styleUrls: ['./subchap8f-location.component.css']
})
export class Subchap8fLocationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
