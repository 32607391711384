// import { Component, OnInit } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { GetService } from 'src/app/services/get.service';


@Component({
  selector: 'app-non-compliance-result',
  templateUrl: './non-compliance-result.component.html',
  styleUrls: ['./non-compliance-result.component.css']
})
export class NonComplianceResultComponent implements OnInit {
  componentId: number;
  lastCompleted:number;

  constructor(  private statusService: GetService,
    private router: Router, 
    private route: ActivatedRoute, 
    public mediaObserver: MediaObserver, 
    private breakpointObserver: BreakpointObserver) { 
      this.route.params.subscribe( params => this.componentId = params['id'] );
      this.statusService.getCompletionStatus()
      .subscribe(data=>{
        this.lastCompleted = parseInt(data.toString());
        // console.log("lastcomp",this.lastCompleted)
      },err=>{
        alert("Couldnt get status"+err);
      })
    }


  ngOnInit() {
    window.scrollTo(0, 0);
  }
}
