import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hawkeyesios-objective',
  templateUrl: './hawkeyesios-objective.component.html',
  styleUrls: ['./hawkeyesios-objective.component.css']
})
export class HawkeyesiosObjectiveComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
