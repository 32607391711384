import { Component, OnDestroy, OnInit } from '@angular/core';
import { GetquestionsService } from 'src/app/services/getquestions.service';
import { GlobalConstants } from 'src/app/common/global-constants';
import { MatRadioChange } from '@angular/material/radio';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Router } from '@angular/router';
import { RandomQuestionsService } from 'src/app/admin/services/random-questions.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-startpretest',
  templateUrl: './startpretest.component.html',
  styleUrls: ['./startpretest.component.css']
})
export class StartpretestComponent implements OnInit,OnDestroy {
  questionNo = '';
  progressbarValue:number= 100;
  timeLeft: number = 1200; //1200
  timeLeftDiv: number = this.timeLeft
  quizSummaryEnable: boolean = false
  noOfAttemptedQue: number = 0
  description;
  option1;
  option2;
  option3;
  option4;
  selectedQueIndex;
  currenQue;
  value;
  type;
  option;
  index;
  users;
  selectoption;
  userselectoption;
  val: boolean = false
  public clock;
  url = GlobalConstants.rootURL;
  loadStatus: boolean = false;
  display;
  reviewQuesIndex: number;
  interval;
  testLength;
  oplist = null
  optionvalue
  questionLegendFlag:boolean = false;
  activeButtonNumber: number = 0
  username:string;
  test: any;
  options: any;
  responseArray:Array<String> = []
  constructor(private preQuestion: GetquestionsService, private randomQuestion: RandomQuestionsService, private router: Router,private toastr: ToastrService) {
    this.username = localStorage.getItem('firstName');
    this.randomQuestion.getQuestionOccurence('pretest')
      .subscribe(data=>{
        // this.timeLeftDiv = data['maxTime'] * 60;
        // this.timeLeft = data['maxTime'] * 60;
        this.preQuestion.getQuestions(data['easy'], data['medium'], data['hard'], data['custom']).subscribe(data => {
          this.test = data['questionArray'];
          this.options = data['optionArray'];
          this.testLength = this.test.length - 1  
          this.startTimer();
          this.loadStatus = true
          this.questionCLick(1, false);
        }, error => {
          // console.log('no data received')
        })
      }, err=>{

      })

  this.randomQuestion.getMarketDetails()
  .subscribe(data=>{
    this.timeLeftDiv = data['time'] * 60;
    this.timeLeft = data["time"] * 60;
  })  
}

showWarning() {
  this.toastr.warning('Last 5 minutes remaining. Please complete the test');
}
 
 
  ngOnInit() {
  }

  onSubmit() {
    this.preQuestion.saveResult(this.test)
      .subscribe(data => {
        // console.log('generated report')
        this.router.navigate(['test/pre-test/result'])
      }, error => {
        // console.log('error getting report')
      })
  }

  onQuizSummary(){
    this.noOfAttemptedQue = 0;
    for(let i= 0; i<this.test.length; i++){
      if(this.test[i].user_response != null && this.test[i].user_response.length != 0){
        this.noOfAttemptedQue = this.noOfAttemptedQue + 1;
        // console.log("got response val---",this.test[i].user_response,typeof(this.test[i].user_response))
      }
    }
    this.quizSummaryEnable = true
  }

  logout(){
    localStorage.clear()
    this.router.navigate(['/'])
  }
  
  radioChange(event: MatRadioChange) {
    this.test[this.selectedQueIndex].user_response = event.value;

    // attempted or not
    let body1 = document.getElementsByTagName('button')[this.selectedQueIndex];
    if (this.test[this.selectedQueIndex].user_response != null) {
      body1.classList.remove("reviewButton");
      body1.classList.add("afterAnswer");
    } else {
      body1.classList.remove("afterAnswer");
    }
  }

  checkBoxChange(event: MatCheckboxChange) {
    this.optionvalue = event.source.value[0]

    if (this.optionvalue['isAnswer'] == true) {
      this.responseArray.push(this.optionvalue['option'])
    } else {
      this.responseArray = this.responseArray.filter(item => item !== this.optionvalue['option']);
    }
    // Attempted or not
    let body1 = document.getElementsByTagName('button')[this.selectedQueIndex];
    if (this.responseArray.length > 0) {
      body1.classList.remove("reviewButton");
      body1.classList.add("afterAnswer");
    } else {
      body1.classList.remove("afterAnswer");
    }
    this.test[this.selectedQueIndex].user_response = this.responseArray
    // console.log("Response Array-",this.responseArray,this.responseArray.length)

// after clear response
    if( this.responseArray.length == 0  && body1.classList.contains('reviewWithAnswer'))
    {
      body1.classList.remove("reviewWithAnswer");
      body1.classList.add("afterVisit");
      // console.log('hello')
    }
  }

  startTimer() {
     this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
        this.progressbarValue = this.timeLeft * 100 / this.timeLeftDiv;
      }
      this.display = this.transform(this.timeLeft)
      if(this.display === '00:00:00'){
        clearInterval(this.interval)
        this.onSubmit()
      }
      if(this.display === '00:05:00'){
        this.showWarning();
      }
    }, 1000)

    
 
  }
 
  transform(value: number): string {
    var sec_num = value;
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);
    
    return this.clock = '' + ('00' + hours).substr(-2) + ':' + ('00' + minutes).substr(-2) + ':' + ('00' + seconds).substr(-2) ; 
    
  }

  questionCLick(questionNo: number, legendFlag: boolean) {
    this.selectedQueIndex = questionNo - 1;
    this.description = this.test[this.selectedQueIndex].question_desc;
    this.quizSummaryEnable = false

    if (this.test[this.selectedQueIndex].user_response == null) {
      this.responseArray = []
    } else {
      this.responseArray = this.test[this.selectedQueIndex].user_response;
    }

    let body3 = document.getElementsByTagName('button')[this.selectedQueIndex];
    body3.classList.add("afterVisit");

    let body1 = document.getElementsByTagName('button')[this.activeButtonNumber];
    body1.classList.remove("activeButton");

    let body2 = document.getElementsByTagName('button')[this.selectedQueIndex];
    body2.classList.add("activeButton");

    if(legendFlag == false){
      body1.classList.remove("reviewButton");
      body1.classList.remove("reviewWithAnswer")
      if(this.test[this.activeButtonNumber].user_response != null && this.responseArray.length != 0 || this.test[this.activeButtonNumber].user_response != null){
        body1.classList.remove("afterVisit");
        body1.classList.add("afterAnswer");
      } else {
        body3.classList.add("afterVisit");
      }
    }  
    this.activeButtonNumber = this.selectedQueIndex;
  }

  // review question
  markQuesForReview() {
    let body1 = document.getElementsByTagName('button')[this.selectedQueIndex];
    body1.classList.remove("afterAnswer")
    
    if(this.test[this.selectedQueIndex].question_type == 'single'){
      if(this.test[this.selectedQueIndex].user_response != null){
        body1.classList.remove("reviewButton")
        body1.classList.add("reviewWithAnswer")
      } else if(this.test[this.selectedQueIndex].user_response == null){
        body1.classList.add("reviewButton")  
      }
    } else {
      if(this.test[this.selectedQueIndex].user_response == null){
        body1.classList.add("reviewButton")   
      }else if(this.test[this.selectedQueIndex].user_response != null  && this.responseArray.length == 0){
        body1.classList.add("reviewButton")
      } else if(this.test[this.selectedQueIndex].user_response != null && this.responseArray.length != 0){
        body1.classList.remove("reviewButton")
        body1.classList.add("reviewWithAnswer")
      }
    }
    this.questionLegendFlag = true;
    if(this.selectedQueIndex != this.testLength)
    this.questionCLick(this.selectedQueIndex + 2, true)
  }

 resetResponse(){
  this.test[this.selectedQueIndex].user_response = null;
  let body1 = document.getElementsByTagName('button')[this.selectedQueIndex];
  body1.classList.remove("afterAnswer");
  body1.classList.remove("reviewButton");
  body1.classList.remove("reviewWithAnswer");
  body1.classList.add("afterVisit");
  if(this.test[this.selectedQueIndex].question_type == 'multiple'){
    var optionList = this.options[this.selectedQueIndex];
    for(let i=0; i<optionList.length; i++){
      optionList[i].isAnswer = false
    }
  }
 }

ngOnDestroy(){
clearInterval(this.interval)
}
  
}