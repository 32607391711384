import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subchap8l-earthing',
  templateUrl: './subchap8l-earthing.component.html',
  styleUrls: ['./subchap8l-earthing.component.css']
})
export class Subchap8lEarthingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
