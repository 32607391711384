import { Component, OnInit } from '@angular/core';
import { GetService } from 'src/app/services/get.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MediaObserver } from '@angular/flex-layout';
import { BreakpointObserver } from '@angular/cdk/layout';


@Component({
  selector: 'app-topic2-chap9-to-chap15',
  templateUrl: './topic2-chap9-to-chap15.component.html',
  styleUrls: ['./topic2-chap9-to-chap15.component.css']
})
export class Topic2Chap9ToChap15Component implements OnInit {

  componentId: number;
  lastCompleted:number;

  constructor(  private statusService: GetService,
    private router: Router, 
    private route: ActivatedRoute, 
    public mediaObserver: MediaObserver, 
    private breakpointObserver: BreakpointObserver) { 
      this.route.params.subscribe( params => this.componentId = params['id'] );
      this.statusService.getCompletionStatus()
      .subscribe(data=>{
        this.lastCompleted = parseInt(data.toString());
        // console.log("lastcomp",this.lastCompleted)
      },err=>{
        alert("Couldnt get status"+err);
      })
    }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

}
