import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-test-content-post-test',
  templateUrl: './test-content-post-test.component.html',
  styleUrls: ['./test-content-post-test.component.css']
})
export class TestContentPostTestComponent implements OnInit {
  url = '';       
  constructor(private router: Router){}
  ngOnInit() { 
    // window.open(this.url, '_blank', 'right=200','height=700, width=700'); 
  }
}
