import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subchap8a-main-screen',
  templateUrl: './subchap8a-main-screen.component.html',
  styleUrls: ['./subchap8a-main-screen.component.css']
})
export class Subchap8aMainScreenComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
