import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subchap8p-wallboxsocket',
  templateUrl: './subchap8p-wallboxsocket.component.html',
  styleUrls: ['./subchap8p-wallboxsocket.component.css']
})
export class Subchap8pWallboxsocketComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
