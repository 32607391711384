import { Injectable } from '@angular/core';
import { GlobalConstants } from 'src/app/common/global-constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AdminserviceService {
  private url = GlobalConstants.rootURL;
  constructor(private http: HttpClient) { }

  getPretestHistory(){
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return this.http.get(this.url+'/api/pretest/attempt-history',{
      headers: headers
    })
  }

  createReport(id){
    const headers = new HttpHeaders()
      .set('Content-Type', 'text/csv');
    return this.http.get(this.url+'/api/pretest/create-report?id='+id,{
      headers: headers
    })
  }
}
