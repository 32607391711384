import { Component, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { Router, ActivatedRoute } from '@angular/router';
import { GetService } from "src/app/services/get.service";

@Component({
  selector: 'app-main-do-donts-index',
  templateUrl: './main-do-donts-index.component.html',
  styleUrls: ['./main-do-donts-index.component.css']
})
export class MainDoDontsIndexComponent implements OnInit {
  lastCompleted:number = 0;
  panelOpenState = false;
  panelOpenState1 = false;
  colorName = 'green';
  expandCollapseState : boolean =false;

 
  
@ViewChild(MatAccordion,{static:true}) accordion: MatAccordion;
  constructor(private statusService: GetService,private router: Router, private route: ActivatedRoute) { 
    
  }
  ngOnInit() {
    window.scrollTo(0, 0);
    this.statusService.getCompletionStatusDos()
    .subscribe(data=>{
      this.lastCompleted = parseInt(data.toLocaleString());
      // console.log("Status Received-",this.lastCompleted)
    },err=>{
      alert("could not get status "+err);
    })
  }

  isCompleted(componentId: number){
    if(componentId <= this.lastCompleted){
      return true;
    }
    return false;
  }
  
  goToChapterTopic(){
    this.router.navigate([0], {relativeTo: this.route})
  }
}
