import { Component, OnInit } from '@angular/core';
import { GetService } from 'src/app/services/get.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MediaObserver } from '@angular/flex-layout';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-topic1-leaf-electric-vehicle',
  templateUrl: './topic1-leaf-electric-vehicle.component.html',
  styleUrls: ['./topic1-leaf-electric-vehicle.component.css']
})
export class Topic1LEAFElectricVehicleComponent implements OnInit {
  componentId: number;
  lastCompleted:number;

  constructor(
    private statusService: GetService,
    private router: Router, 
    private route: ActivatedRoute, 
    public mediaObserver: MediaObserver, 
    private breakpointObserver: BreakpointObserver) {
    this.route.params.subscribe( params => this.componentId = params['id'] );
      this.statusService.getCompletionStatus()
      .subscribe(data=>{
        this.lastCompleted = parseInt(data.toString());
        // console.log("lastcomp",this.lastCompleted)
      },err=>{
        alert("Couldnt get status"+err);
      })
   }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

}
