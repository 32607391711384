import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chap7c-app-receive',
  templateUrl: './chap7c-app-receive.component.html',
  styleUrls: ['./chap7c-app-receive.component.css']
})
export class Chap7cAppReceiveComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
