import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chap7i-pre-setting1-ver8',
  templateUrl: './chap7i-pre-setting1-ver8.component.html',
  styleUrls: ['./chap7i-pre-setting1-ver8.component.css']
})
export class Chap7iPreSetting1Ver8Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
