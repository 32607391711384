import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subchap8t-post-check-images',
  templateUrl: './subchap8t-post-check-images.component.html',
  styleUrls: ['./subchap8t-post-check-images.component.css']
})
export class Subchap8tPostCheckImagesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
