import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { GetService } from 'src/app/services/get.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-do-donts-index-chap10',
  templateUrl: './do-donts-index-chap10.component.html',
  styleUrls: ['./do-donts-index-chap10.component.css']
})
export class DoDontsIndexChap10Component implements OnInit {

  opened= false;
  deviceXs: boolean;
  mediaSub: Subscription;
  sideFlag=1;
  lastCompleted: number;
  constructor(private statusService: GetService,private toastr: ToastrService,private router:Router, public mediaObserver: MediaObserver) {
    statusService.getCompletionStatusDos()
    .subscribe(data=>{
      this.lastCompleted = parseInt(data.toString());
    },err=>{
      alert("Error getting status!!")
    })
   }
   
  isCompleted(componentId: number){
    if(componentId <= this.lastCompleted){
      return true;
    }
    return false;
  }

  navigateDosDonts(componentId: number){
    if(componentId <= this.lastCompleted){
    this.router.navigate(['course-do-donts/do-donts', componentId]);
    } else if(componentId == this.lastCompleted+1){
    this.router.navigate(['course-do-donts/do-donts', componentId]);
    } else{
    this.toastr.warning('Please complete this topic first to proceed for next');
    this.router.navigate(['course-do-donts/do-donts', this.lastCompleted+1]);
    }
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.mediaSub = this.mediaObserver.media$.subscribe((res: MediaChange) => {
      // console.log(res.mqAlias);
      this.deviceXs = res.mqAlias === "xs" ? true : false;
    })
  }
  ngOnDestroy() {
    this.mediaSub.unsubscribe();
  }
  sidebar(){
    if(this.sideFlag==1){
      this.sideFlag=0;
    }
    else{
      this.sideFlag=1;
    }
    // console.log(this.sideFlag)
}


}
