import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chap8m-recomm-wiring-detail',
  templateUrl: './chap8m-recomm-wiring-detail.component.html',
  styleUrls: ['./chap8m-recomm-wiring-detail.component.css']
})
export class Chap8mRecommWiringDetailComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
