import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chap7h-pre-setting-ver8',
  templateUrl: './chap7h-pre-setting-ver8.component.html',
  styleUrls: ['./chap7h-pre-setting-ver8.component.css']
})
export class Chap7hPreSettingVer8Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
