import { Component, OnInit, ViewChild } from '@angular/core';
import { Ilogin } from '../shared/login';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GetService } from '../services/get.service'
import { stringify } from '@angular/compiler/src/util';
import { isEmptyExpression } from '@angular/compiler';
import { NgForm } from '@angular/forms';
// import { setFlagsFromString } from 'v8';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  // @ViewChild('myForm', {static: true}) myForm: NgForm;

  login: Ilogin;
  users;
  loginError = ""
  loading = false;
  operator1:string = "one";
  operand = "+";
  operator2:string = "2";
  symbol= "=";
  myOperator;
  randomnumber;
  randomnumber2;
  randomnumber3;
  randomnumber4;
  captchaResult;
  numeric;
  randomnumber1;
  randomOperator;
  captchaResult2
  captchaResult1;
  
  

  operand1 = [

   "zero","one","two","three","four","five","six","seven","eight","nine","ten","eleven","twelve",
   "thirteen","fourteen","fifteen","sixteen","seventeen","eighteen","nineteen","twenty"

  ];

  operator = [
    "+","-","*"
   ];

  // ConvertStringToNumber() {
  // this.numeric = Number(this.operand1);
  //   return this.numeric;
  // }

  getExpression(){

    // this.randomnumber1 = Math.floor(Math.random() * (20 - 0 + 1)) + 0;
    var operator = Math.floor(Math.random() * (2 - 0 + 1)) + 0;
    // this.randomnumber2 = Math.floor(Math.random() * (20 - 0 + 1)) + 0;

    // this.randomnumber3 =this.operand1[randomnumber1];
    this.randomOperator = this.operator[operator];

    if(this.randomOperator == "+"){
      this.randomnumber1 = Math.floor(Math.random() * (20 - 0 + 1)) + 0;
      this.randomnumber2 = Math.floor(Math.random() * (20 - 0 + 1)) + 0;
      this.captchaResult = this.randomnumber1 + this.randomnumber2;
    }

    else if(this.randomOperator == "-"){
      this.randomnumber1 = Math.floor(Math.random() * (20 - 0 + 1)) + 0;
      this.randomnumber2 = Math.floor(Math.random() * (20 - 0 + 1)) + 0;

      if(this.randomnumber1 < this.randomnumber2){
        this.randomnumber3 = this.randomnumber2;
        this.randomnumber2 = this.randomnumber1;
        this.randomnumber1 = this.randomnumber3;
      }
      this.captchaResult = this.randomnumber1 - this.randomnumber2;
    }

    else{
      this.randomnumber1 = Math.floor(Math.random() * (9 - 0 + 1)) + 0;
      this.randomnumber2 = Math.floor(Math.random() * (9 - 0 + 1)) + 0;
      this.captchaResult = this.randomnumber1 * this.randomnumber2;
    }
    
    
    // console.log(this.captchaResult);
    // console.log(this.operator[operator]);
    // console.log(this.randomnumber2);
    // console.log(this.operand1[randomnumber1]);

  }
  
  constructor(private service: GetService, private router: Router, private http: HttpClient) {
    this.login = new Ilogin();
  }

  ngOnInit() {
  this.getExpression();
  // console.log(this.randomnumber);
  }

  onLogin(heroForm: NgForm) {
    if(this.captchaResult == this.login.answer){
      this.service.getUsers(this.login.email, this.login.password)
        .subscribe(data => {
          // console.log(data);
          if(data!= null){
            this.loading = false;
            // console.log(data);
            localStorage.setItem('loginStatus', 'true')
            localStorage.setItem('accessToken', data['token'])
            localStorage.setItem('payload', data['payload'])
            localStorage.setItem('firstName', data['firstName'])
            this.router.navigate(["/"]);
          }
          else{
          this.loginError = 'Incorrect username or password, Please try again!';
          localStorage.setItem('loginStatus', 'false')
          }
          // console.log(data);
        }, err => {
          this.loginError = 'Incorrect username or password, Please try again! ';
          localStorage.setItem('loginStatus', 'false')
        });
    }
    else{
      this.loginError = 'Invalid Captcha, Please try again!';
     
      heroForm.reset();
      this.getExpression();
      // window.location.reload();
      // this.ngOnInit();
      // this.login.email = '';
      // this.login.password= '';
      // this.login.answer = 0;
    }
  }
  onSubmit() { }

  
}
